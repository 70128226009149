import {
  ASYNC_STATUSES,
  DEFAULT_ASYNC_FUNCTION_STATE
} from '_qoc-redux/constants';
import { TYPES } from './constants';

export const initialState = {
  clinicId: '',
  pNo: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  patientHash: '',
  registerProvider: DEFAULT_ASYNC_FUNCTION_STATE
};

export default (state = initialState, action) => {
  if (action.type === TYPES.REGISTER_PROVIDER_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      providerRegistered: true,
      registerProvider: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.REGISTER_PROVIDER_PENDING) {
    return Object.assign({}, state, {
      registerProvider: {
        ...state.registerProvider,
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.REGISTER_PROVIDER_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      providerRegistered: false,
      registerProvider: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.SET_CLINIC_ID) {
    const { clinicId } = action.payload;

    return Object.assign({}, state, {
      clinicId
    });
  }

  if (action.type === TYPES.SET_PHYSICIAN_NUMBER) {
    const { pNo } = action.payload;

    return Object.assign({}, state, {
      pNo
    });
  }

  if (action.type === TYPES.SET_PATIENT_HASH) {
    const { patientHash } = action.payload;

    return Object.assign({}, state, {
      patientHash
    });
  }

  if (action.type === TYPES.SET_PHYSICIAN_NAME) {
    const { firstName, lastName } = action.payload;
    console.log('TYPES.SET_PHYSICIAN_NAME: ', firstName, lastName);

    return Object.assign({}, state, {
      firstName,
      lastName
    });
  }

  if (action.type === TYPES.SET_FORM_VALUES) {
    const { values } = action.payload;
    const { emailAddress, firstName, lastName } = values;

    return Object.assign({}, state, {
      emailAddress,
      firstName,
      lastName
    });
  }

  return state;
};
