const fulfillAction = (props = {}) => dispatch => {
  const { onFulfilled, type, ...otherProps } = props;

  dispatch({
    payload: otherProps,
    type
  });

  if (typeof onFulfilled === 'function') {
    onFulfilled(props);
  }

  return props;
};

export default fulfillAction;
