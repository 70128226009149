import { chain, map, omit } from 'lodash';
import { TYPES } from './constants';
import { ASYNC_STATUSES } from '../constants';

const initialState = {
  documentProfilePropertys: {},
  deleteDocumentsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchDocumentsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  postDocumentsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  updateDocumentsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  }
};

const reducer = (state = initialState, action) => {
  if (action.type === TYPES.DELETE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED) {
    const { profileDocumentPropertyUUIDs, message, status } = action.payload;

    return Object.assign({}, state, {
      instanceLinks: omit(
        state.documentProfilePropertys,
        profileDocumentPropertyUUIDs
      ),
      deleteDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.DELETE_DOCUMENTS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      deleteDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.DELETE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      deleteDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED) {
    const { documentProfilePropertys, message, status } = action.payload;

    return Object.assign({}, state, {
      documentProfilePropertys: {
        ...state.documentProfilePropertys,
        ...documentProfilePropertys
      },
      fetchDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_DOCUMENTS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      fetchDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_DOCUMENTS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED) {
    const { documentProfilePropertys, message, status } = action.payload;
    const newUUIDS = map(
      documentProfilePropertys,
      'profileDocumentPropertyUUID'
    );

    return Object.assign({}, state, {
      documentProfilePropertys: chain(state.documentProfilePropertys)
        .filter(
          ({ profileDocumentPropertyUUID }) =>
            !newUUIDS.includes(profileDocumentPropertyUUID)
        )
        .concat(documentProfilePropertys)
        .keyBy('profileDocumentPropertyUUID')
        .value(),
      postDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_DOCUMENTS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      postDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.POST_DOCUMENTS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      postDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED) {
    const { documentProfilePropertys, message, status } = action.payload;
    const newUUIDS = map(
      documentProfilePropertys,
      'profileDocumentPropertyUUID'
    );

    return Object.assign({}, state, {
      documentProfilePropertys: chain(state.documentProfilePropertys)
        .filter(
          ({ profileDocumentPropertyUUID }) =>
            !newUUIDS.includes(profileDocumentPropertyUUID)
        )
        .concat(documentProfilePropertys)
        .keyBy('profileDocumentPropertyUUID')
        .value(),
      updateDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_DOCUMENTS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      updateDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.UPDATE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      updateDocumentsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  return state;
};

export default reducer;
