/* eslint-disable */

import _ from 'lodash';
// import moment from 'moment';
// import generateUUID from 'uuid/v1';
import { types } from './actions';
// import masterMedications from '../../library/medications';

const initialState = {
  chartNoteResults: [],
  numSuccessfulChartNoteResults: 0,
  numFailedChartNoteResults: 0,
  numChartNoteResults: 0,
  singleChartNoteTestId: null,
  patientInstanceLinks: null
};

const reducer = (state = initialState, action) => {
  const type = action.type;
  const data = action.data;

  let ret = state;

  // - - - - - - - - - - - - -

  if (types.hasOwnProperty(type)) {
    ret = Object.assign({}, ret);

    if (
      -1 !==
      [types.POST_CHART_NOTES_REQUEST, types.POST_CHART_NOTES_REQUEST2].indexOf(
        type
      )
    ) {
      ret.chartNoteResults = [...ret.chartNoteResults, data];
      ret.chartNoteResults = _.sortBy(ret.chartNoteResults, 'id');
    } else if (types.CLEAR_CHART_NOTE_RESULTS === type) {
      ret.chartNoteResults = [];
    } else if (types.CHANGE_FORM_SETTINGS === type) {
      _.each(data, (propVal, propName) => {
        if (ret.hasOwnProperty(propName)) {
          ret[propName] = propVal; // set
        }
      });
    } else if (types.GET_ASSIGNED_PATIENTS === type) {
      ret.patientInstanceLinks = data;
    }

    // - - - - - - - - - - - - -

    // update counts

    if (
      -1 !==
      [
        types.POST_CHART_NOTES_REQUEST,
        types.POST_CHART_NOTES_REQUEST2,
        types.CLEAR_CHART_NOTE_RESULTS
      ].indexOf(type)
    ) {
      ret.numChartNoteResults = ret.chartNoteResults.length;

      ret.numSuccessfulChartNoteResults = _.reduce(
        ret.chartNoteResults,
        (acc, val) => {
          if ('success' === val.statusClass) acc++;
          return acc;
        },
        0
      );

      ret.numFailedChartNoteResults =
        ret.numChartNoteResults - ret.numSuccessfulChartNoteResults;
    }
  }

  return ret;
};

export default reducer;
