import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';
import sdk from '@eams-dev/js-qoc-sdk';
import { TYPES } from './constants';
import extractHttpResponse from '../utils/extractHttpResponse';
import fulfillAction from '../utils/fulfillAction';
import rejectAction from '../utils/rejectAction';

/**
 * Fetch Profiles
 */
export const fetchProfiles = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_PROFILES_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getProfiles(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const profiles = keyBy(data.responseEntity || data, 'profileUUID');

      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          profiles,
          status,
          type: TYPES.FETCH_PROFILES_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_PROFILES_REJECTED
      })
    );
  }
};

/**
 * Fetch Doctor Profile
 */
export const fetchDoctorProfile = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: TYPES.FETCH_DOCTOR_PROFILE_PENDING
  });

  const {
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const cacheResponses = getState().cache.responses || {};
    const profiles = getState().profile.profiles || {};

    const getAAPProvider = reduce(
      cacheResponses,
      (pResponse, value, key, _responses) => {
        if (_responses[key].questionId === 'aap_provider') {
          return _responses[key];
        }
        return pResponse;
      },
      {}
    );

    const provider = reduce(
      profiles,
      (aapProvider, value, key, _profiles) => {
        if (key === getAAPProvider.createdBy.profileUUID) {
          return _profiles[key];
        }
        return aapProvider;
      },
      []
    );
    console.log('fetchDoctorProfile provider: ', provider);

    return dispatch(
      fulfillAction({
        message: onFulfilledMessage,
        onFulfilled,
        provider,
        type: TYPES.FETCH_DOCTOR_PROFILE_FULFILLED
      })
    );
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_DOCTOR_PROFILE_REJECTED
      })
    );
  }
};

/**
 * Fetch Profile VO
 */
export const fetchProfileVO = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_PROFILE_VO_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getProfileVO({
      useCache: false,
      ...requestProps
    });
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const profileVO = data.responseEntity || data;

      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          profileVO,
          status,
          type: TYPES.FETCH_PROFILE_VO_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_PROFILE_VO_REJECTED
      })
    );
  }
};

/**
 * Request Reset Password
 */
export const requestResetPassword = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.REQUEST_RESET_PASSWORD_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.postPasswordReset(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 201) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.REQUEST_RESET_PASSWORD_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.REQUEST_RESET_PASSWORD_REJECTED
      })
    );
  }
};

/**
 * Reset Password
 */
export const resetPassword = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.RESET_PASSWORD_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.postPasswordWithToken(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (data.loginId && status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.RESET_PASSWORD_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or no loginId in response');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.RESET_PASSWORD_REJECTED
      })
    );
  }
};

/**
 * Update Profile
 */
export const updateProfile = (props = {}) => async (dispatch, getState) => {
  dispatch({
    type: TYPES.UPDATE_PROFILE_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const { profileUUID, ...otherRequestProps } = requestProps;
    const currentProfile = get(getState(), 'profile.profileVO.profile') || {};
    const isTargetCurrentProfile = profileUUID === currentProfile.profileUUID;
    const targetProfile = isTargetCurrentProfile
      ? Object.assign({}, currentProfile, otherRequestProps)
      : Object.assign(
          {},
          {
            profileUUID,
            ...otherRequestProps
          }
        );

    const response = await sdk.putProfile({
      profile: targetProfile
    });
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          targetProfile,
          type: TYPES.UPDATE_PROFILE_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.UPDATE_PROFILE_REJECTED
      })
    );
  }
};

/**
 * Update User Password
 */
export const updateUserPassword = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.UPDATE_USER_PASSWORD_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.putUserPassword(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.UPDATE_USER_PASSWORD_FULFILLED,
          unAuthenticatedRedirect: false
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.UPDATE_USER_PASSWORD_REJECTED
      })
    );
  }
};
