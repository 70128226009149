import { keyBy } from 'lodash';
import sdk from '@eams-dev/js-qoc-sdk';
import { TYPES } from './constants';
import extractHttpResponse from '../utils/extractHttpResponse';
import fulfillAction from '../utils/fulfillAction';
import rejectAction from '../utils/rejectAction';

/**
 * Fetch Login Status
 */
export const fetchLoginStatus = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_LOGIN_STATUS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getLoginStatus(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.FETCH_LOGIN_STATUS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_LOGIN_STATUS_REJECTED
      })
    );
  }
};

/**
 * Fetch Login Statuses
 */
export const fetchLoginStatuses = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_LOGIN_STATUSES_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getLoginStatuses(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      const loginStatuses = keyBy(
        data.responseEntity || data.loginStatuses,
        'loginStatus'
      );

      return dispatch(
        fulfillAction({
          loginStatuses,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.FETCH_LOGIN_STATUSES_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_LOGIN_STATUSES_REJECTED
      })
    );
  }
};

/**
 * Login
 */
export const login = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.LOGIN_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    console.log('login props', props);
    const response = await sdk.login(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.LOGIN_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.LOGIN_REJECTED
      })
    );
  }
};

/**
 * Logout
 */
export const logout = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.LOGOUT_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.logout(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.LOGOUT_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.LOGOUT_REJECTED
      })
    );
  }
};
