import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = ({ profile }) => ({
  profile,
  profiles: profile.profiles
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProfiles: actions.fetchProfiles,
      fetchProfileVO: actions.fetchProfileVO,
      requestResetPassword: actions.requestResetPassword,
      updateProfile: actions.updateProfile,
      updateUserPassword: actions.updateUserPassword
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
