import {
  ASYNC_STATUSES,
  DEFAULT_ASYNC_FUNCTION_STATE
} from '_qoc-redux/constants';
import { TYPES } from './constants';

export const initialState = {
  clinicId: '',
  dateOfBirth: '',
  emailAddress: '',
  eConsentAgree: false,
  firstName: '',
  hin: '',
  lastName: '',
  loginId: '',
  password: '',
  confirmPassword: '',
  termsOfUseAgree: false,
  userRegistered: false,
  patientHash: '',
  activatedUser: false,

  sendEmailVerification: DEFAULT_ASYNC_FUNCTION_STATE,
  registerUser: DEFAULT_ASYNC_FUNCTION_STATE
};

export default (state = initialState, action) => {
  if (action.type === TYPES.REGISTER_USER_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      userRegistered: true,
      registerUser: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.REGISTER_USER_PENDING) {
    return Object.assign({}, state, {
      registerUser: {
        ...state.registerUser,
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.REGISTER_USER_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      userRegistered: false,
      registerUser: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.SEND_EMAIL_VERIFICATION_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      sendEmailVerification: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.SEND_EMAIL_VERIFICATION_PENDING) {
    return Object.assign({}, state, {
      sendEmailVerification: {
        ...state.sendEmailVerification,
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.SEND_EMAIL_VERIFICATION_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      sendEmailVerification: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.SET_MAYBE_LOGIN_FORM_VALUES) {
    const { values } = action.payload;
    const { dateOfBirth, hin } = values;

    return Object.assign({}, state, {
      dateOfBirth,
      hin
    });
  }

  if (action.type === TYPES.SET_ACTIVATED_USER_INFO) {
    const { values } = action.payload;
    const { patientHash, activatedUser } = values;
    localStorage.setItem('pHash', patientHash);
    localStorage.setItem('activatedUser', activatedUser);

    return Object.assign({}, state, {
      activatedUser,
      patientHash
    });
  }

  if (action.type === TYPES.SET_EMAIL_TO_ACTIVATE) {
    console.log('calling action SET_EMAIL_TO_ACTIVATE');
    const { emailToActivate } = action.payload;

    return Object.assign({}, state, {
      emailAddress: emailToActivate
    });
  }

  if (action.type === TYPES.SET_TERMS_OF_USE_AGREE) {
    const { termsOfUseAgree } = action.payload;

    return Object.assign({}, state, {
      termsOfUseAgree
    });
  }

  if (action.type === TYPES.SET_E_CONSENT_AGREE) {
    const { eConsentAgree } = action.payload;

    return Object.assign({}, state, {
      eConsentAgree
    });
  }

  if (action.type === TYPES.SET_REGISTER_FORM_VALUES) {
    const { values } = action.payload;
    const { emailAddress, firstName, lastName, clinicId, patientHash } = values;

    const res = Object.assign({}, state, {
      emailAddress,
      firstName,
      lastName,
      clinicId
    });

    if (patientHash) {
      res.patientHash = patientHash;
    }

    return res;
  }

  if (action.type === TYPES.SET_TOKEN_UUID) {
    const { tokenUUID } = action.payload;

    return Object.assign({}, state, {
      tokenUUID
    });
  }

  return state;
};
