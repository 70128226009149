import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = ({ authentication }) => ({
  authentication
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirectRegisterUrl: actions.redirectRegisterUrl,
      registerProvider: actions.registerProvider,
      setClinicId: actions.setClinicId,
      setPhysicianNumber: actions.setPhysicianNumber,
      setPhysicianName: actions.setPhysicianName,
      setPatientHash: actions.setPatientHash,
      setFormValues: actions.setFormValues
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
