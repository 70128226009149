import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = ({ authentication }) => ({
  authentication
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirectRegisterUrl: actions.redirectRegisterUrl,
      validateLogin: actions.validateLogin,
      registerUser: actions.registerUser,
      sendEmailVerification: actions.sendEmailVerification,
      setMaybeLoginFormValues: actions.setMaybeLoginFormValues,
      setTermsOfUseAgree: actions.setTermsOfUseAgree,
      setEConsentAgree: actions.setEConsentAgree,
      setRegisterFormValues: actions.setRegisterFormValues
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
