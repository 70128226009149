/*eslint-disable*/
import _ from 'lodash';

const data = [
  [
    'id',
    'atc',
    'din',
    'href',
    'colour',
    'device',
    'functionType',
    'name',
    'type',
    'chemicalType',
    'chemicalLABA',
    'chemicalICS',
    'chemicalOther',
    'doseLABA',
    'doseICS',
    'doseOther',
    'maxGreenLABA',
    'maxGreenICS',
    'maxYellowLABA',
    'maxYellowICS',
    'lowCeilICS',
    'highFloorICS',
    'timesPerDay',
    'maxPuffsPerTime',
    'dose',
    'maxGreen',
    'maxYellow',
    'trade',
    'chemical',
    'daysPerSession',
    'appears in questionnaire',
    ''
  ],
  [
    '21',
    'R03AC13',
    '2230898',
    'm24e4c9bed24c8c20ce0b560445df2cc0',
    'blue',
    'aerolizer',
    'controller',
    'foradil',
    'laba',
    'laba',
    'formoterol',
    '.',
    '.',
    '12',
    '.',
    '.',
    '24',
    '.',
    '48',
    '.',
    '.',
    '.',
    '2',
    '2',
    '12',
    '24',
    '.',
    'Foradil',
    'formoterol',
    '.',
    'Y',
    ''
  ],
  [
    '2',
    'R03AK06',
    '2240835',
    'm6f6c7924b86047e8195d31fc0c481569',
    'purple',
    'diskus',
    'controller',
    'advair',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '50',
    '100',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '50, 100',
    '100, 1000',
    '100, 2000',
    'Advair',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '3',
    'R03AK06',
    '2240836',
    'm6f6926c583efcb408860cece832a0b60',
    'purple',
    'diskus',
    'controller',
    'advair',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '50',
    '250',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '50, 250',
    '100, 1000',
    '100, 2000',
    'Advair',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '4',
    'R03AK06',
    '2240837',
    'm5bf832d962e9dcc240d58248f73d072c',
    'purple',
    'diskus',
    'controller',
    'advair',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '50',
    '500',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '50, 500',
    '100, 1000',
    '100, 2000',
    'Advair',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '15',
    'R03BA05',
    '2237245',
    'm1e0d909522ad2ef65b4a1c2e075be4e2',
    'orange',
    'diskus',
    'controller',
    'flovent',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '100',
    '.',
    '.',
    '1000',
    '.',
    '2000',
    '250',
    '501',
    '2',
    '4',
    '100',
    '1000',
    '2000',
    'Flovent',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '16',
    'R03BA05',
    '2237246',
    'mf0ac6ded81063593df47ed1edd7944dc',
    'orange',
    'diskus',
    'controller',
    'flovent',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '250',
    '.',
    '.',
    '1000',
    '.',
    '2000',
    '250',
    '501',
    '2',
    '4',
    '250',
    '1000',
    '2000',
    'Flovent',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '17',
    'R03BA05',
    '2237247',
    'm1ca346ec02b843937794cb6540a8a2cf',
    'orange',
    'diskus',
    'controller',
    'flovent',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '500',
    '.',
    '.',
    '1000',
    '.',
    '2000',
    '250',
    '501',
    '2',
    '4',
    '500',
    '1000',
    '2000',
    'Flovent',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '29',
    'R03AC12',
    '2231129',
    'm38ac235671a9ea9e6c73301884b9442e',
    'green',
    'diskus',
    'controller',
    'serevent',
    'laba',
    'laba',
    'salmeterol',
    '.',
    '.',
    '50',
    '.',
    '.',
    '100',
    '.',
    '100',
    '.',
    '.',
    '.',
    '2',
    '1',
    '50',
    '100',
    '100',
    'Serevent',
    'salmeterol',
    '.',
    'Y',
    ''
  ],
  [
    '35',
    'R03AC02',
    '2243115',
    'm4f45c44811e6aaae86d34e08fe3009e0',
    'blue',
    'diskus',
    'reliever',
    'ventolin',
    'other',
    'saba',
    '.',
    '.',
    'salbutamol',
    '.',
    '.',
    '200',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '200',
    '',
    '',
    'Ventolin',
    'salbutamol',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '31',
    'R03BB04',
    '2246793',
    'mec04316a7d6e3d29df8d00898e930c61',
    'grey',
    'handihaler',
    'controller',
    'spiriva',
    'other',
    'laac',
    '.',
    '.',
    'tiotropium',
    '.',
    '.',
    '18',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '1',
    '18',
    '',
    '',
    'Spiriva',
    'tiotropium',
    '.',
    'Y',
    ''
  ],
  [
    '62',
    'R03AC02',
    '2232570',
    'md4c1859ab641f957fd3aa575d1c77d52',
    'blue',
    'inhaler1',
    'reliever',
    'airomir',
    'other',
    'saba',
    '.',
    '.',
    'salbutamol',
    '.',
    '.',
    '100',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '100',
    '',
    '',
    'Airomir',
    'salbutamol',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '7',
    'R03BA08',
    '2285606',
    'm2181c8cef3a08ba9fa0439527cd95300',
    'orange',
    'inhaler1',
    'controller',
    'alvesco',
    'ICS',
    'ICS',
    '.',
    'ciclesonide',
    '.',
    '.',
    '100',
    '.',
    '.',
    '400',
    '.',
    '1600',
    '200',
    '401',
    '1 OR 2',
    '4',
    '100',
    '400',
    '800',
    'Alvesco',
    'ciclesonide',
    '.',
    'Y',
    ''
  ],
  [
    '8',
    'R03BA08',
    '2285614',
    'm4c1d5585eaa4f8dcca2928eb666a94da',
    'orange',
    'inhaler1',
    'controller',
    'alvesco',
    'ICS',
    'ICS',
    '.',
    'ciclesonide',
    '.',
    '.',
    '200',
    '.',
    '.',
    '400',
    '.',
    '1600',
    '200',
    '401',
    '1 OR 2',
    '4',
    '200',
    '400',
    '800',
    'Alvesco',
    'ciclesonide',
    '.',
    'Y',
    ''
  ],
  [
    '27',
    'R03BA01',
    '2242029',
    'm93ef0025632ef5c9121f6282628a01f0',
    'beige',
    'inhaler1',
    'controller',
    'qvar',
    'ICS',
    'ICS',
    '.',
    'beclomethasone',
    '.',
    '.',
    '50',
    '.',
    '.',
    '800',
    '.',
    '800',
    '250',
    '501',
    '2',
    '4',
    '50',
    '800',
    '800',
    'Qvar',
    'beclomethasone',
    '.',
    'Y',
    ''
  ],
  [
    '28',
    'R03BA01',
    '2242030',
    'mf28a39c849147078c9f04865275b57fc',
    'red',
    'inhaler1',
    'controller',
    'qvar',
    'ICS',
    'ICS',
    '.',
    'beclomethasone',
    '.',
    '.',
    '100',
    '.',
    '.',
    '800',
    '.',
    '800',
    '250',
    '501',
    '2',
    '4',
    '100',
    '800',
    '800',
    'Qvar',
    'beclomethasone',
    '.',
    'Y',
    ''
  ],
  [
    '5',
    'R03AK06',
    '2245126',
    'mb6a527acc03190a3ad21fe50d6447087',
    'purple',
    'inhaler2',
    'controller',
    'advair',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '25',
    '125',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '25, 125',
    '100, 1000',
    '100, 2000',
    'Advair',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '6',
    'R03AK06',
    '2245127',
    'md5f0607e0e733d17bea21884a64c2507',
    'purple',
    'inhaler2',
    'controller',
    'advair',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '25',
    '250',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '25, 250',
    '100, 1000',
    '100, 2000',
    'Advair',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '9',
    'R03AC02',
    '2245669',
    'm4dfafc2a5f47c1bddb2296e6802d9599',
    'blue',
    'inhaler2',
    'reliever',
    'Apo-Salbutamol',
    'other',
    'saba',
    '.',
    '.',
    'Apo-Salbutamol',
    '.',
    '.',
    '100',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '100',
    '',
    '',
    'Apo-Salvent',
    'salbutamol',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '12',
    'R03BB01',
    '2247686',
    'mdf6ca77e3e772786db90f389cc54bb68',
    'green',
    'inhaler2',
    'reliever',
    'atrovent',
    'other',
    'saac',
    '.',
    '.',
    'ipratropium',
    '.',
    '.',
    '20',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '20',
    '',
    '',
    'Atrovent',
    'ipratropium',
    '.',
    'N',
    ''
  ],
  [
    '18',
    'R03BA05',
    '2244291',
    'm72c420e1f98a5e87c561346026e5a96f',
    'orange',
    'inhaler2',
    'controller',
    'flovent',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '50',
    '.',
    '.',
    '1000',
    '.',
    '2000',
    '250',
    '501',
    '2',
    '4',
    '50',
    '1000',
    '2000',
    'Flovent',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '19',
    'R03BA05',
    '2244292',
    'm619f0e70c5906d6f094efd34c768eb77',
    'orange',
    'inhaler2',
    'controller',
    'flovent',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '125',
    '.',
    '.',
    '1000',
    '.',
    '2000',
    '250',
    '501',
    '2',
    '4',
    '125',
    '1000',
    '2000',
    'Flovent',
    'fluticasone',
    '.',
    'y',
    ''
  ],
  [
    '20',
    'R03BA05',
    '2244293',
    'm72533f4b33fb7a9fd0603ba7043e768e',
    'orange',
    'inhaler2',
    'controller',
    'flovent',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '250',
    '.',
    '.',
    '1000',
    '.',
    '2000',
    '250',
    '501',
    '2',
    '4',
    '250',
    '1000',
    '2000',
    'Flovent',
    'fluticasone',
    '.',
    'y',
    ''
  ],
  [
    '34',
    'R03AC02',
    '2241497',
    'md0c1859ab641f957fd3aa575d1c77d52',
    'blue',
    'inhaler2',
    'reliever',
    'ventolin',
    'other',
    'saba',
    '.',
    '.',
    'salbutamol',
    '.',
    '.',
    '100',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '100',
    '',
    '',
    'Ventolin',
    'salbutamol',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '64',
    'R03AC02',
    '2419858',
    'md2c1859ab641f957fd3aa575d1c77d52',
    'blue',
    'inhaler2',
    'reliever',
    `Salbutamol (Sanis)`,
    'other',
    'saba',
    '.',
    '.',
    'salbutamol',
    '.',
    '.',
    '100',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '100',
    '',
    '',
    'Salbutamol (Sanis)',
    'salbutamol',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '63',
    'R03AC02',
    '2326450',
    'md3c1859ab641f957fd3aa575d1c77d52',
    'blue',
    'inhaler2',
    'reliever',
    'Teva-Salbutamol',
    'other',
    'saba',
    '.',
    '.',
    'salbutamol',
    '.',
    '.',
    '100',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '100',
    '',
    '',
    'Teva-Salbutamol',
    'salbutamol',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '37',
    'R03AK07',
    '2361752',
    'm376399671ee65bf7fa64b3ecc1c892e4',
    'blue',
    'inhaler2',
    'controller',
    'zenhale',
    'combo',
    'laba,ICS',
    'formoterol',
    'mometasone',
    '.',
    '5',
    '100',
    '.',
    '20',
    '800',
    '40',
    '800',
    '399',
    '801',
    '2',
    '4',
    '5, 100',
    '20, 800',
    '20, 800',
    'Zenhale',
    'formoterol, mometasone',
    '.',
    'Y',
    ''
  ],
  [
    '38',
    'R03AK07',
    '2361760',
    'mf7b4edb50c2b715d9999bda2d89af597',
    'blue',
    'inhaler2',
    'controller',
    'zenhale',
    'combo',
    'laba,ICS',
    'formoterol',
    'mometasone',
    '.',
    '5',
    '200',
    '.',
    '20',
    '800',
    '40',
    '800',
    '399',
    '801',
    '2',
    '4',
    '5, 200',
    '20, 800',
    '20, 800',
    'Zenhale',
    'formoterol, mometasone',
    '.',
    'Y',
    ''
  ],
  [
    '1',
    'R03DC01',
    '2236606',
    'm86d31129dd70512f35357b077b5440a1',
    'white',
    'pills',
    'controller',
    'accolate',
    'other',
    'ltra',
    '.',
    '.',
    'zafirlukast',
    '.',
    '.',
    '20',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '2',
    '1',
    '20000',
    '',
    '',
    'Accolate',
    'zafirlukast',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '30',
    'R03DC03',
    '2238217',
    'm90bb8c4bea59fb7dd9521d8cd5845c41',
    'beige',
    'pills',
    'controller',
    'singulair',
    'other',
    'ltra',
    '.',
    '.',
    'montelukast',
    '.',
    '.',
    '10',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '1',
    '10000',
    '',
    '',
    'Singulair',
    'montelukast',
    '.',
    'Y',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '13',
    'R03AC03',
    '786616',
    'medab40bb2707595cbd68a2585c66d0b2',
    'blue',
    'turbuhaler',
    'reliever',
    'bricanyl',
    'other',
    'saba',
    '.',
    '.',
    'terbutaline',
    '',
    '.',
    '500',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '500',
    '',
    '',
    'Bricanyl',
    'terbutaline',
    '.',
    'Y',
    ''
  ],
  [
    '22',
    'R03AC13',
    '2237224',
    'm2e016253478755504ad75995027ae2d4',
    'blue',
    'turbuhaler',
    'controller',
    'oxeze',
    'laba',
    'laba',
    'formoterol',
    '.',
    '.',
    '12',
    '.',
    '.',
    '24',
    '.',
    '48',
    '.',
    '.',
    '.',
    '2',
    '2',
    '12',
    '24',
    '',
    'Oxeze',
    'formoterol',
    '.',
    'Y',
    ''
  ],
  [
    '23',
    'R03AC13',
    '2237225',
    'm9001de01608df7290b9c4f13621bafcf',
    'blue',
    'turbuhaler',
    'controller',
    'oxeze',
    'laba',
    'laba',
    'formoterol',
    '.',
    '.',
    '6',
    '.',
    '.',
    '24',
    '.',
    '48',
    '.',
    '.',
    '.',
    '2',
    '4',
    '6',
    '24',
    '',
    'Oxeze',
    'formoterol',
    '.',
    'Y',
    ''
  ],
  [
    '24',
    'R03BA02',
    '851752',
    'mc90dafcdf3c7b8c995f03bbdf822f9a3',
    'brown',
    'turbuhaler',
    'controller',
    'pulmicort',
    'ICS',
    'ICS',
    '.',
    'budesonide',
    '.',
    '.',
    '200',
    '.',
    '.',
    '800',
    '.',
    '2400',
    '400',
    '801',
    '2',
    '4',
    '200',
    '800',
    '2400',
    'Pulmicort',
    'budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '25',
    'R03BA02',
    '851760',
    'm7f9cc69e93525ac59041cb0049abfc12',
    'brown',
    'turbuhaler',
    'controller',
    'pulmicort',
    'ICS',
    'ICS',
    '.',
    'budesonide',
    '.',
    '.',
    '400',
    '.',
    '.',
    '800',
    '.',
    '2400',
    '400',
    '801',
    '2',
    '4',
    '400',
    '800',
    '2400',
    'Pulmicort',
    'budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '26',
    'R03BA02',
    '852074',
    'm951906d3d15200f4d057ebb6a8a56e04',
    'brown',
    'turbuhaler',
    'controller',
    'pulmicort',
    'ICS',
    'ICS',
    '.',
    'budesonide',
    '.',
    '.',
    '100',
    '.',
    '.',
    '800',
    '.',
    '2400',
    '400',
    '801',
    '2',
    '4',
    '100',
    '800',
    '2400',
    'Pulmicort',
    'budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '32',
    'R03AK07',
    '2245385',
    'me1b27b1efa3342f2e29c49ddd1e34d31',
    'red',
    'turbuhaler',
    'controller',
    'symbicort',
    'combo',
    'laba,ICS',
    'formoterol',
    'budesonide',
    '.',
    '6',
    '100',
    '.',
    '24',
    '800',
    '48',
    '2400',
    '400',
    '801',
    '2',
    '4',
    '6, 100',
    '24, 800',
    '48, 2400',
    'Symbicort',
    'formoterol, budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '33',
    'R03AK07',
    '2245386',
    'm2326e00d3b6b382cf8b556e5fdf6ae29',
    'red',
    'turbuhaler',
    'controller',
    'symbicort',
    'combo',
    'laba,ICS',
    'formoterol',
    'budesonide',
    '.',
    '6',
    '200',
    '.',
    '24',
    '800',
    '48',
    '2400',
    '400',
    '801',
    '2',
    '4',
    '6, 200',
    '24, 800',
    '48, 2400',
    'Symbicort',
    'formoterol, budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '39',
    'R03BA07',
    '2438690',
    '.',
    'pink',
    'twisthaler',
    'controller',
    'asmanex',
    'ICS',
    'ICS',
    '.',
    'mometasone',
    '.',
    '.',
    '100',
    '.',
    '.',
    '800',
    '.',
    '800',
    '399',
    '801',
    '1 OR 2',
    '4',
    '100',
    '800',
    '800',
    'Asmanex',
    'mometasone',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '10',
    'R03BA07',
    '2243595',
    'mcb897f3461a9850d8a1d6e4f059ea4c2',
    'pink',
    'twisthaler',
    'controller',
    'asmanex',
    'ICS',
    'ICS',
    '.',
    'mometasone',
    '.',
    '.',
    '200',
    '.',
    '.',
    '800',
    '.',
    '800',
    '399',
    '801',
    '1 OR 2',
    '4',
    '200',
    '800',
    '800',
    'Asmanex',
    'mometasone',
    '.',
    'Y',
    ''
  ],
  [
    '11',
    'R03BA07',
    '2243596',
    'me8cd8c870d0ea88706be758acbdefaaf',
    'pink',
    'twisthaler',
    'controller',
    'asmanex',
    'ICS',
    'ICS',
    '.',
    'mometasone',
    '.',
    '.',
    '400',
    '.',
    '.',
    '800',
    '.',
    '800',
    '399',
    '801',
    '1 OR 2',
    '4',
    '400',
    '800',
    '800',
    'Asmanex',
    'mometasone',
    '.',
    'Y',
    ''
  ],
  [
    '40',
    'R03BB04',
    '2435381',
    '.',
    'grey',
    'respimat',
    'controller',
    'spiriva',
    'other',
    'laac',
    '.',
    '.',
    'tiotropium',
    '.',
    '.',
    '2.5',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '2',
    '2.5',
    '.',
    '.',
    'Spiriva',
    'tiotropium',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '41',
    'R03BB07 ',
    '2423596',
    '.',
    'white/green',
    'ellipta',
    'controller',
    'incruse',
    'other',
    'laac',
    '.',
    '.',
    'umeclidinium',
    '.',
    '.',
    '62.5',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '1',
    '62.5',
    '.',
    '.',
    'Incruse',
    'umeclidinium',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '42',
    'R03BB06 ',
    '2394936',
    '.',
    'white/orange',
    'breezhaler',
    'controller',
    'seebri',
    'other',
    'laac',
    '.',
    '.',
    'glycopyrronium',
    '.',
    '.',
    '50',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '1',
    '50',
    '.',
    '.',
    'Seebri',
    'Glycopyrronium',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '43',
    'R03BB05 ',
    '2409720',
    '.',
    'white/green',
    'genuair',
    'controller',
    'tudorza',
    'other',
    'laac',
    '.',
    '.',
    'aclidinium',
    '.',
    '.',
    '400',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '2',
    '1',
    '400',
    '.',
    '.',
    'Tudorza',
    'Aclidinium',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '44',
    'R03AC18',
    '2376938',
    '.',
    'white',
    'breezhaler',
    'controller',
    'onbrez',
    'laba',
    'laba',
    'Indacaterol',
    '.',
    '.',
    '75',
    '.',
    '.',
    '75',
    '.',
    '75',
    '.',
    '.',
    '.',
    '1',
    '1',
    '75',
    '.',
    '75',
    'onbrez',
    'Indacaterol',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '45',
    'R03BA09',
    '2446561',
    '.',
    'white/orange',
    'ellipta',
    'controller',
    'arnuity',
    'ICS',
    'ICS',
    '.',
    'fluticasone furoate',
    '.',
    '.',
    '100',
    '.',
    '.',
    '200',
    '.',
    '200',
    '199',
    '201',
    '1',
    '1',
    '100',
    '200',
    '200',
    'Arnuity',
    'fluticasone furoate',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '46',
    'R03BA09',
    '2446588',
    '.',
    'white/orange',
    'ellipta',
    'controller',
    'arnuity',
    'ICS',
    'ICS',
    '.',
    'fluticasone furoate',
    '.',
    '.',
    '200',
    '.',
    '.',
    '200',
    '.',
    '200',
    '199',
    '201',
    '1',
    '1',
    '200',
    '200',
    '200',
    'Arnuity',
    'fluticasone furoate',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '47',
    'R03AK10',
    '2408872',
    '.',
    'white/blue',
    'ellipta',
    'controller',
    'breo',
    'combo',
    'laba,ICS',
    'vilanterol',
    'fluticasone furoate',
    '.',
    '25',
    '100',
    '.',
    '25',
    '200',
    '25',
    '200',
    '199',
    '201',
    '1',
    '1',
    '25, 100',
    '25, 200',
    '25, 200',
    'breo',
    'vilanterol, fluticasone furoate',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '48',
    'R03AK10',
    '2444186',
    '.',
    'white/blue',
    'ellipta',
    'controller',
    'breo',
    'combo',
    'laba,ICS',
    'vilanterol',
    'fluticasone furoate',
    '.',
    '25',
    '200',
    '.',
    '25',
    '200',
    '25',
    '200',
    '199',
    '201',
    '1',
    '1',
    '25, 200',
    '25, 200',
    '25, 200',
    'breo',
    'vilanterol, fluticasone furoate',
    '.',
    'N',
    'NEED TO ADD TO Q LATER'
  ],
  [
    '92',
    'R03DC00',
    '2238257',
    '.',
    'beige',
    'pills',
    'controller',
    'prednisone',
    'other',
    'prednisone',
    'prednisone',
    'prednisone',
    'prednisone',
    '.',
    '.',
    '30',
    '30',
    '30',
    '30',
    '30',
    '30',
    '30',
    '1',
    '1',
    '30',
    '30',
    '30',
    'prednisone',
    'prednisone',
    '.',
    'N',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '94',
    'R03DC04',
    '2238717',
    '.',
    'beige',
    'pills',
    'controller',
    'prednisone',
    'other',
    'prednisone',
    'prednisone',
    'prednisone',
    'prednisone',
    '.',
    '.',
    '40',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '1',
    '40',
    '40',
    '40',
    'prednisone',
    'prednisone',
    '.',
    'N',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '95',
    'R03DC07',
    '2288217',
    '.',
    'beige',
    'pills',
    'controller',
    'prednisone',
    'other',
    'prednisone',
    'prednisone',
    'prednisone',
    'prednisone',
    '.',
    '.',
    '50',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '1',
    '1',
    '50',
    '50',
    '50',
    'prednisone',
    'prednisone',
    '.',
    'N',
    "doesn't ask 'how often do you use it' questions"
  ],
  [
    '96',
    'R03AK07',
    '2245386',
    'm2326e00d3b6b382cf8b556e5fdf6ae29',
    'red',
    'turbuhaler',
    'reliever',
    'symbicort',
    'combo',
    'laba,ICS',
    'formoterol',
    'budesonide',
    '.',
    '6',
    '100',
    '.',
    '24',
    '800',
    '48',
    '2400',
    '400',
    '801',
    '.',
    'maximum [x] puffs',
    '6, 200',
    '24, 800',
    '48, 2400',
    'Symbicort',
    'formoterol, budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '97',
    'R03AK07',
    '2245386',
    'm2326e00d3b6b382cf8b556e5fdf6ae29',
    'red',
    'turbuhaler',
    'reliever',
    'symbicort',
    'combo',
    'laba,ICS',
    'formoterol',
    'budesonide',
    '.',
    '6',
    '200',
    '.',
    '24',
    '800',
    '48',
    '2400',
    '400',
    '801',
    '.',
    'maximum [x] puffs',
    '6, 200',
    '24, 800',
    '48, 2400',
    'Symbicort',
    'formoterol, budesonide',
    '.',
    'Y',
    ''
  ],
  [
    '98',
    'R03AC13',
    '2237224',
    'm2e016253478755504ad75995027ae2d4',
    'blue',
    'turbuhaler',
    'reliever',
    'oxeze',
    'laba',
    '.',
    'formoterol',
    '.',
    '.',
    '12',
    '.',
    '.',
    '24',
    '.',
    '48',
    '.',
    '.',
    '.',
    '.',
    '.',
    '12',
    '24',
    '',
    'Oxeze',
    'formoterol',
    '.',
    'Y',
    ''
  ],
  [
    '99',
    'R03AC13',
    '2237225',
    'm9001de01608df7290b9c4f13621bafcf',
    'blue',
    'turbuhaler',
    'reliever',
    'oxeze',
    'laba',
    '.',
    'formoterol',
    '.',
    '.',
    '6',
    '.',
    '.',
    '24',
    '.',
    '48',
    '.',
    '.',
    '.',
    '.',
    '.',
    '6',
    '24',
    '',
    'Oxeze',
    'formoterol',
    '.',
    'Y',
    ''
  ],
  [
    '100',
    'R03AC13',
    '2230898',
    'm24e4c9bed24c8c20ce0b560445df2cc0',
    'blue',
    'aerolizer',
    'reliever',
    'foradil',
    'laba',
    '.',
    'formoterol',
    '.',
    '.',
    '12',
    '.',
    '.',
    '24',
    '.',
    '48',
    '.',
    '.',
    '.',
    '.',
    '.',
    '12',
    '24',
    '.',
    'Foradil',
    'formoterol',
    '.',
    'Y',
    ''
  ],
  [
    '101',
    'R03AL02',
    '2231675',
    '.',
    'grey/orange',
    'respimat',
    'reliever',
    'combivent',
    'other',
    'saac,saba',
    '.',
    '.',
    'ipratropium,salbutamol',
    '.',
    '.',
    '20',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '20',
    '.',
    '.',
    'Combivent',
    'ipratropium,salbutamol',
    '.',
    'Y',
    ''
  ],
  [
    '102',
    'R03BA05',
    '2467909',
    '.',
    'white/green',
    'respiclick',
    'controller',
    'aermony',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '55',
    '.',
    '.',
    '464',
    '.',
    '856',
    '110',
    '227',
    '2',
    '1',
    '55',
    '464',
    '1856',
    'Aermony',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '103',
    'R03BA05',
    '2467909',
    '.',
    'white/green',
    'respiclick',
    'controller',
    'aermony',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '113',
    '.',
    '.',
    '464',
    '.',
    '856',
    '110',
    '227',
    '2',
    '1',
    '113',
    '464',
    '1856',
    'Aermony',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '104',
    'R03BA05',
    '2467917',
    '.',
    'white/green',
    'respiclick',
    'controller',
    'aermony',
    'ICS',
    'ICS',
    '.',
    'fluticasone',
    '.',
    '.',
    '232',
    '.',
    '.',
    '464',
    '.',
    '856',
    '110',
    '227',
    '2',
    '1',
    '232',
    '464',
    '1856',
    'Aermony',
    'fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '105',
    'R03AK06',
    '2495597',
    '.',
    'grey',
    'inhub',
    'controller',
    'wixela',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '50',
    '100',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '50, 100',
    '100, 1000',
    '100, 2000',
    'Wixela',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '106',
    'R03AK06',
    '2495597',
    '.',
    'grey',
    'inhub',
    'controller',
    'wixela',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '50',
    '250',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '50, 250',
    '100, 1000',
    '100, 2000',
    'Wixela',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '107',
    'R03AK06',
    '2495597',
    '.',
    'grey',
    'inhub',
    'controller',
    'wixela',
    'combo',
    'laba,ICS',
    'salmeterol',
    'fluticasone',
    '.',
    '50',
    '500',
    '.',
    '100',
    '1000',
    '100',
    '2000',
    '250',
    '501',
    '2',
    '2',
    '50, 500',
    '100, 1000',
    '100, 2000',
    'Wixela',
    'salmeterol,fluticasone',
    '.',
    'Y',
    ''
  ],
  [
    '108',
    'R03AK14',
    '2498685',
    '.',
    'white',
    'breezhaler',
    'controller',
    'atectura',
    'combo',
    'laba,ICS',
    'indacaterol',
    'mometasone',
    '.',
    '150',
    '80',
    '.',
    '150',
    '320',
    '150',
    '320',
    '80',
    '161',
    '1',
    '1',
    '150,80',
    '150,320',
    '150,320',
    'Atectura',
    'indacaterol, mometasone',
    '.',
    'Y',
    ''
  ],
  [
    '109',
    'R03AK14',
    '2498685',
    '.',
    'white',
    'breezhaler',
    'controller',
    'atectura',
    'combo',
    'laba,ICS',
    'indacaterol',
    'mometasone',
    '.',
    '150',
    '160',
    '.',
    '150',
    '320',
    '150',
    '320',
    '80',
    '161',
    '1',
    '1',
    '150,160',
    '150,320',
    '150,320',
    'Atectura',
    'indacaterol, mometasone',
    '.',
    'Y',
    ''
  ],
  [
    '110',
    'R03AK14',
    '2498685',
    '.',
    'white',
    'breezhaler',
    'controller',
    'atectura',
    'combo',
    'laba,ICS',
    'indacaterol',
    'mometasone',
    '.',
    '150',
    '320',
    '.',
    '150',
    '320',
    '150',
    '320',
    '80',
    '161',
    '1',
    '1',
    '150,320',
    '150,320',
    '150,320',
    'Atectura',
    'indacaterol, mometasone',
    '.',
    'Y',
    ''
  ]
];

const medications = _.chain(data)
  .drop(1)
  .map(row => {
    const currentMedication = data[0];

    return _.chain(currentMedication)
      .reduce(
        (accRow, colName, index) => {
          if (
            !(
              colName === 'chemicalICS' ||
              colName === 'chemicalLABA' ||
              colName === 'chemicalType' ||
              colName === 'colour' ||
              colName === 'device' ||
              colName === 'doseICS' ||
              colName === 'doseLABA' ||
              colName === 'doseOther' ||
              colName === 'functionType' ||
              colName === 'highFloorICS' ||
              colName === 'id' ||
              colName === 'lowCeilICS' ||
              colName === 'maxGreenICS' ||
              colName === 'maxGreenLABA' ||
              colName === 'maxPuffsPerTime' ||
              colName === 'name' ||
              colName === 'timesPerDay'
            )
          ) {
            return accRow;
          }

          accRow[colName] = row[index] === '.' ? '' : row[index].toLowerCase(); // eslint-disable-line no-param-reassign

          if (accRow.device === 'pills' && accRow.name === 'prednisone') {
            accRow.daysPerSession = [5, 6, 7]; // eslint-disable-line no-param-reassign
          } else if (accRow.device === 'pills') {
            accRow.puffsPerTime = 1; // eslint-disable-line no-param-reassign
          }

          if (colName === 'timesPerDay') {
            accRow[colName] =
              accRow[colName] === '1 or 2'
                ? [1, 2]
                : [
                    isNaN(parseFloat(accRow[colName]))
                      ? 0
                      : parseFloat(accRow[colName])
                  ]; // eslint-disable-line no-param-reassign
          } else if (
            colName === 'maxPuffsPerTime' ||
            colName === 'doseICS' ||
            colName === 'doseLABA' ||
            colName === 'doseOther'
          ) {
            accRow[colName] = isNaN(parseFloat(accRow[colName]))
              ? 0
              : parseFloat(accRow[colName]); // eslint-disable-line no-param-reassign
          } else {
            accRow[colName] = isNaN(parseFloat(accRow[colName]))
              ? accRow[colName]
              : parseFloat(accRow[colName]); // eslint-disable-line no-param-reassign
          }

          return accRow;
        },
        {
          asReliever: false,
          daysPerSession: [],
          discontinue: null,
          checked: false,
          messageOrOptions: false
        }
      )
      .thru(med =>
        Object.assign(med, { deviceAndName: `${med.name} (${med.device})` })
      )
      .value();
  })
  .value();

export default medications;
