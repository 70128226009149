import {
  ASYNC_STATUSES,
  DEFAULT_ASYNC_FUNCTION_STATE
} from '_qoc-redux/constants';
import { types } from './constants';

const initialState = {
  initializedPatientProfile: false,
  initializedMedications: false,
  fetchingMedications: false,
  patientProfileLocked: false,
  screen5Display: {
    body: [
      {
        id: 1,
        text:
          'All actions are clearly listed in an automated EMR chart note dated today ("Refresh" your EMR chart to view).'
      }
    ],
    bodyCta: [
      {
        id: 1,
        text: 'You may now close this window.'
      }
    ],
    ctaButtonText: '',
    subTitle: '',
    title: 'Finished'
  },
  showNotSuitableButton: false,
  initializePatientProfile: DEFAULT_ASYNC_FUNCTION_STATE,
  onReachScreen2: false,
  onReachScreen3: false,
  decisionSupportResumed: false
};

const reducer = (state = initialState, action) => {
  if (action.type === types.INITIALIZE_MEDICATIONS_PENDING) {
    return Object.assign({}, state, {
      initializedMedications: false,
      fetchingMedications: true
    });
  }

  if (action.type === types.INITIALIZE_MEDICATIONS_FULFILLED) {
    return Object.assign({}, state, {
      initializedMedications: true,
      fetchingMedications: false
    });
  }

  if (action.type === types.INITIALIZE_PATIENT_PROFILE_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      initializedPatientProfile: true,
      initializePatientProfile: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === types.INITIALIZE_PATIENT_PROFILE_PENDING) {
    return Object.assign({}, state, {
      initializePatientProfile: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === types.INITIALIZE_PATIENT_PROFILE_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      initializedPatientProfile: false,
      initializePatientProfile: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === types.ON_CHANGE_ROUTE) {
    return Object.assign({}, state, {
      showNotSuitableButton: action.data.isRouteChanged
    });
  }

  if (action.type === types.ON_UPDATE_SCREEN_5_DISPLAY) {
    const { screen5Display } = action.data;

    return Object.assign({}, state, {
      screen5Display: Object.assign({}, state.screen5Display, screen5Display)
    });
  }

  if (action.type === types.ON_REACH_SCREEN_2) {
    return Object.assign({}, state, {
      onReachScreen2: true
    });
  }

  if (action.type === types.ON_REACH_SCREEN_3) {
    return Object.assign({}, state, {
      onReachScreen3: true
    });
  }

  if (action.type === types.DECISION_SUPPORT_RESUMED) {
    return Object.assign({}, state, {
      decisionSupportResumed: true
    });
  }

  return state;
};

export default reducer;
