/* global window */
import { push } from 'connected-react-router';
import { TYPES } from './constants';
import fulfillAction from '../utils/fulfillAction';
import rejectAction from '../utils/rejectAction';

const changeSystemModal = ({ modalContent }) => dispatch =>
  dispatch({
    type: TYPES.CHANGE_SYSTEM_MODAL,
    payload: modalContent
  });

const displaySystemModal = ({ show }) => dispatch =>
  dispatch({
    type: TYPES.DISPLAY_SYSTEM_MODAL,
    payload: show
  });

export const hideSystemModal = () => dispatch =>
  dispatch(displaySystemModal({ show: false }));

export const showSystemModal = ({ modalContent }) => dispatch => {
  dispatch(displaySystemModal({ show: true }));
  dispatch(changeSystemModal({ modalContent }));
};

const changeSystemToast = ({ toastMessage }) => dispatch =>
  dispatch({
    type: TYPES.CHANGE_SYSTEM_TOAST,
    payload: toastMessage
  });

const displaySystemToast = ({ show }) => dispatch =>
  dispatch({
    type: TYPES.DISPLAY_SYSTEM_TOAST,
    payload: show
  });

export const hideSystemToast = () => dispatch =>
  dispatch(displaySystemToast({ show: false }));

export const showSystemToast = ({ toastMessage }) => dispatch => {
  dispatch(displaySystemToast({ show: true }));
  dispatch(changeSystemToast({ toastMessage }));
};

export const onHistoryListen = (props = {}) => dispatch => {
  const { location, action, onHistoryListenCallback } = props;

  dispatch({
    type: TYPES.HISTORY_LISTEN
  });

  dispatch(onHistoryListenCallback(location, action));

  window.scrollTo(0, 0);
};

/**
 * Redirect browser url
 */
export const onRedirectUrl = url => dispatch => {
  dispatch({ type: TYPES.ON_REDIRECT_URL });
  dispatch(push(url));
};

/**
 * Redirect using the old-school method (window.location)
 */
export const onForceRedirectUrl = url => dispatch => {
  dispatch({ type: TYPES.ON_FORCE_REDIRECT_URL });
  window.location = url;
};

/**
 * Throw an error
 */
export const onResolveError = () => ({
  type: TYPES.ON_PROCESS_TASK_FULFILLED
});

export const onThrowError = error => ({
  type: TYPES.ON_PROCESS_TASK_REJECTED,
  error
});

/**
 * Process Task
 */
export const onProcessTaskFulfilled = () => dispatch =>
  dispatch({
    type: TYPES.ON_PROCESS_TASK_FULFILLED
  });

export const onProcessTaskPending = () => dispatch =>
  dispatch({
    type: TYPES.ON_PROCESS_TASK_PENDING
  });

export const onProcessTask = (taskCB, onErrorCB, onFulfillCB) => dispatch =>
  new Promise(resolve => {
    let taskPromise;

    try {
      dispatch(onProcessTaskPending());
      taskPromise = dispatch(taskCB());
    } catch (error) {
      if (typeof onErrorCB === 'function') {
        dispatch(onErrorCB(error));
      }
      dispatch(onThrowError(error));
    }

    if (taskPromise instanceof Promise) {
      return taskPromise.then(() => {
        if (typeof onFulfillCB === 'function') {
          dispatch(onFulfillCB());
        }
        dispatch(onProcessTaskFulfilled());

        return resolve();
      });
    }

    if (typeof onFulfillCB === 'function') {
      dispatch(onFulfillCB());
    }
    dispatch(onProcessTaskFulfilled());

    return resolve();
  });

/**
 * First Login
 */
export const firstLogin = (props = {}) => {
  const { notFirstLogin } = props;

  return {
    payload: notFirstLogin,
    type: TYPES.FIRST_LOGIN
  };
};

export const initializeSystem = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.INITIALIZE_SYSTEM_PENDING
  });

  const {
    initializeSystemCallback,
    onFulfilled,
    onFulfilledMessage = 'System initialize successfull',
    onRejected,
    onRejectedMessage = 'System initialize failed'
  } = props;

  const errorCB = error =>
    rejectAction({
      error,
      onRejected,
      onRejectedMessage,
      type: TYPES.INITIALIZE_SYSTEM_REJECTED
    });

  const fulfillCB = () =>
    fulfillAction({
      message: onFulfilledMessage,
      onFulfilled,
      status: 200,
      type: TYPES.INITIALIZE_SYSTEM_FULFILLED
    });

  if (typeof initializeSystemCallback === 'function') {
    await dispatch(onProcessTask(initializeSystemCallback, errorCB, fulfillCB));
  }
};
