import get from 'lodash/get';

const rejectAction = (props = {}) => dispatch => {
  const {
    error = {},
    onRejected,
    onRejectedMessage,
    type,
    ...otherProps
  } = props;
  const message =
    onRejectedMessage ||
    get(error.response, 'statusText') ||
    error.reason ||
    error.message;
  const status =
    get(error.response, 'status') || error.errorCode || error.status;

  dispatch({
    payload: {
      message,
      status,
      ...otherProps
    },
    type
  });

  if (typeof onRejected === 'function') {
    onRejected(props);
  }

  return Object.assign({}, props, { message, status });
};

export default rejectAction;
