import { TYPES } from './constants';
import { ASYNC_STATUSES, DEFAULT_ASYNC_FUNCTION_STATE } from '../constants';

export const initialState = {
  properties: {},
  deleteSessionKey: DEFAULT_ASYNC_FUNCTION_STATE,
  getSessionKey: DEFAULT_ASYNC_FUNCTION_STATE,
  postPutSessionKeyValue: DEFAULT_ASYNC_FUNCTION_STATE
};

const reducer = (state = initialState, action) => {
  // --- deleteSessionKey ---
  if (action.type === TYPES.DELETE_SESSION_KEY_FULFILLED) {
    const { key, message, status } = action.payload;

    const properties = Object.assign({}, state.properties);
    delete properties[key];

    return Object.assign({}, state, {
      properties,
      deleteSessionKey: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.DELETE_SESSION_KEY_PENDING) {
    return Object.assign({}, state, {
      deleteSessionKey: Object.assign({}, state.deleteSessionKey, {
        asyncStatus: ASYNC_STATUSES.PENDING
      })
    });
  }

  if (action.type === TYPES.DELETE_SESSION_KEY_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      deleteSessionKey: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  // --- getSessionKey ---
  if (action.type === TYPES.GET_SESSION_KEY_FULFILLED) {
    const { key, message, status, value } = action.payload;

    return Object.assign({}, state, {
      properties: Object.assign({}, state.properties, {
        [key]: value
      }),
      getSessionKey: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.GET_SESSION_KEY_PENDING) {
    return Object.assign({}, state, {
      getSessionKey: Object.assign({}, state.getSessionKey, {
        asyncStatus: ASYNC_STATUSES.PENDING
      })
    });
  }

  if (action.type === TYPES.GET_SESSION_KEY_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      getSessionKey: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  // --- postPutSessionKeyValue ---
  if (action.type === TYPES.POST_PUT_SESSION_KEY_VALUE_FULFILLED) {
    const { key, message, status, value } = action.payload;

    return Object.assign({}, state, {
      properties: Object.assign({}, state.properties, {
        [key]: value
      }),
      postPutSessionKeyValue: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_PUT_SESSION_KEY_VALUE_PENDING) {
    return Object.assign({}, state, {
      postPutSessionKeyValue: Object.assign({}, state.postPutSessionKeyValue, {
        asyncStatus: ASYNC_STATUSES.PENDING
      })
    });
  }

  if (action.type === TYPES.POST_PUT_SESSION_KEY_VALUE_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      postPutSessionKeyValue: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  return state;
};

export default reducer;
