import axios from 'axios';
import * as systemActions from '_qoc-redux/System/actions';
import extractHttpResponse from '_qoc-redux/utils/extractHttpResponse';
import fulfillAction from '_qoc-redux/utils/fulfillAction';
import getFirstChar from '_qoc-redux/utils/getFirstChar';
import rejectAction from '_qoc-redux/utils/rejectAction';

import { TYPES } from './constants';

/**
 * This function will take a url string as parameter and
 * replace any url/query parameter specifically for register urls then
 * invoke system action to redirect to that url
 *
 * @param {*} url string - redirect url
 * @returns Object
 */
export function redirectRegisterUrl(url) {
  return (dispatch, getState) => {
    const { clinicId, pNo, patientHash } = getState().providerRegister || {};
    const replacedUrl = url
      .replace(/:clinicId/g, clinicId)
      .replace(/:pNo/g, pNo)
      .replace(/:patientHash/g, patientHash);

    dispatch(systemActions.onForceRedirectUrl(replacedUrl));

    return {
      type: TYPES.REDIRECT_REGISTER_URL
    };
  };
}

/* registerProvider */
function registerProviderFulfilled(props = {}) {
  return fulfillAction({
    ...props,
    type: TYPES.REGISTER_PROVIDER_FULFILLED
  });
}
function registerProviderPending(props = {}) {
  return { payload: props, type: TYPES.REGISTER_PROVIDER_PENDING };
}
function registerProviderRejected(props = {}) {
  return rejectAction({
    ...props,
    type: TYPES.REGISTER_PROVIDER_REJECTED
  });
}
export function registerProvider(props = {}) {
  const errorsResponse = [];

  return async dispatch => {
    dispatch(registerProviderPending());

    const { values = {} } = props;
    values.physicianNo = values.pNo;

    try {
      const url = '/api/register/provider';
      const response = await axios({
        data: values,
        method: 'post',
        url
      });
      const { errors = [], status, statusText: message } = extractHttpResponse({
        response
      });

      if (errors.length > 0 && errors[0]) {
        errorsResponse.push(...errors);

        throw new Error(errors[0].reason);
      }

      if (getFirstChar({ value: status }) === '2') {
        return dispatch(
          registerProviderFulfilled({
            message,
            status
          })
        );
      }

      throw new Error('Status not in 2xx range');
    } catch (error) {
      return dispatch(
        registerProviderRejected({
          error: errorsResponse[0] || error
        })
      );
    }
  };
}
/* / */

export function setClinicId(clinicId) {
  return {
    payload: {
      clinicId
    },
    type: TYPES.SET_CLINIC_ID
  };
}

export function setPhysicianName(provider) {
  return {
    payload: {
      firstName: provider.firstName,
      lastName: provider.lastName
    },
    type: TYPES.SET_PHYSICIAN_NAME
  };
}

export function setPhysicianNumber(pNo) {
  return {
    payload: {
      pNo
    },
    type: TYPES.SET_PHYSICIAN_NUMBER
  };
}

export function setPatientHash(patientHash) {
  return {
    payload: {
      patientHash
    },
    type: TYPES.SET_PATIENT_HASH
  };
}

export function setFormValues(values) {
  return {
    payload: {
      values
    },
    type: TYPES.SET_FORM_VALUES
  };
}
