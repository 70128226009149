import { connect as reduxConnect } from 'react-redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = state => ({
  session: state.session
});

const mapDispatchToProps = dispatch => ({
  deleteSessionKey: () => dispatch(actions.deleteSessionKey()),
  getSessionKey: () => dispatch(actions.getSessionKey()),
  postPutSessionKeyValue: () => dispatch(actions.postPutSessionKeyValue())
});

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
