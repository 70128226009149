import { chain, keyBy } from 'lodash';
import sdk from '@eams-dev/js-qoc-sdk';
import { TYPES } from './constants';
import extractHttpResponse from '../utils/extractHttpResponse';
import fulfillAction from '../utils/fulfillAction';
import rejectAction from '../utils/rejectAction';

/**
 * Delete Documents Profiles Propertys
 */
export const deleteDocumentsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.DELETE_DOCUMENTS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { profileDocumentPropertyUUIDs = [] } = requestProps;

  try {
    const response = await sdk.deleteDocumentsProfilesPropertys(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          profileDocumentPropertyUUIDs,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.DELETE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.DELETE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};

/**
 * Fetch Documents Profiles Propertys
 */
export const fetchDocumentsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_DOCUMENTS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getDocumentsProfilesPropertys(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const documentProfilePropertys = keyBy(
        data.responseEntity || data,
        'profileDocumentPropertyUUID'
      );

      return dispatch(
        fulfillAction({
          documentProfilePropertys,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.FETCH_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_DOCUMENTS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};

/**
 * Post Documents Profiles Propertys
 */
export const postDocumentsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.POST_DOCUMENTS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { documentProfilePropertys } = requestProps;

  try {
    const response = await sdk.postDocumentsProfilesPropertys(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 201) {
      const documentProfilePropertysWithUUIDs = chain(documentProfilePropertys)
        .map((documentProfileProperty, index) =>
          Object.assign({}, documentProfileProperty, {
            profileDocumentPropertyUUID: data[index].id
          })
        )
        .keyBy('profileDocumentPropertyUUID')
        .value();

      return dispatch(
        fulfillAction({
          documentProfilePropertys: documentProfilePropertysWithUUIDs,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.POST_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.POST_DOCUMENTS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};

/**
 * Update Documents Profiles Propertys
 */
export const updateDocumentsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.UPDATE_DOCUMENTS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { documentProfilePropertys } = requestProps;

  try {
    const response = await sdk.postDocumentsProfilesPropertys(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 201) {
      return dispatch(
        fulfillAction({
          documentProfilePropertys,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.UPDATE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.UPDATE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};
