import {
  actions as qocActions,
  connect as qocConnect,
  reducers as qocReducers
} from '_qoc-redux';
import {
  actions as cdssActions,
  connect as cdssConnect,
  reducers as cdssReducers
} from '_cdss/redux';
import userRegister from './UserRegister';
import providerRegister from './ProviderRegister';

export const actions = Object.assign({}, qocActions, cdssActions, {
  userRegister: userRegister.actions,
  providerRegister: providerRegister.actions
});

export const connect = Object.assign({}, qocConnect, cdssConnect, {
  userRegister: userRegister.connect,
  providerRegister: providerRegister.connect
});

export const reducers = Object.assign({}, qocReducers, cdssReducers, {
  userRegister: userRegister.reducer,
  providerRegister: providerRegister.reducer
});

const redux = {
  actions,
  connect,
  reducers
};

export default redux;
