export const ASYNC_STATUSES = {
  FULFILLED: 'FULFILLED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED'
};

export const DEFAULT_ASYNC_FUNCTION_STATE = {
  asyncStatus: '',
  message: '',
  status: 0
};

export const ERROR_NOT_IN_2XX = 'Status is not in 2xx range';

export default {
  ASYNC_STATUSES,
  DEFAULT_ASYNC_FUNCTION_STATE,
  ERROR_NOT_IN_2XX
};
