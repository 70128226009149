/* eslint-disable */
// helper functions to reduce some of the boilerplate code

import _ from 'lodash';

//
/*
constructor( props ) {
        
    super( props );

    // instead of this
    this.foo = this.foo.bind( this );
    this.bar = this.bar.bind( this );

    // do this
    rh.bindAll( this, [ 'foo', 'bar' ] );

}
*/

const bindAll = (subject, methods) => {
  _.each(methods, methodName => {
    if (subject.hasOwnProperty(methodName)) {
      subject[methodName] = subject[methodName].bind(subject);
    }
  });
};

// - - - - - - - - - - - - - - - - - - - -

/*

// instead of this
const mapDispatchToProps = dispatch => ( {
    foo: params => dispatch( actions.sub.foo( params ) ),
    bar: params => dispatch( actions.sub.bar( params ) )
} );

// do this
const mapDispatchToProps = dispatch => ( rh.dispatchToProps(
    dispatch, actions.sub, [ 'foo', 'bar' ]
) );

*/

const mapDispatchToProps = (dispatch, subject, propNames) => {
  const ret = {};

  _.each(propNames, propName => {
    if (subject.hasOwnProperty(propName)) {
      ret[propName] = params => dispatch(subject[propName](params));
    }
  });

  return ret;
};

// - - - - - - - - - - - - - - - - - - - -

/*

// instead of
export const types = {
    FOO: 'FOO',
    BAR: 'BAR'
};

// do this
export const types = rh.types( [ 'FOO', 'BAR' ] );

*/

const types = typeNames => {
  return _.reduce(
    typeNames,
    (acc, val) => {
      acc[val] = val;
      return acc;
    },
    {}
  );
};

// - - - - - - - - - - - - - - - - - - - -

const reactHelpers = {
  bindAll,
  mapDispatchToProps,
  types
};

export default reactHelpers;
