/* eslint-disable no-inline-comments */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-router-dom';

import './styles.scss';

import Application from './containers/Application';
import {
  DoctorLoginRoute,
  FourOhFourRoute,
  HomeRoute,
  LoginActivationRoute,
  LoginRoute,
  ServerMessagesRoute,
  SessionExpiredRoute,
  UserRegisterConfirmNewUserRoute,
  UserRegisterTermsOfUseRoute,
  UserRegisterEConsentRoute,
  UserRegisterFormRoute,
  UserNotEligibleRoute,
  UserRegisterEmailVerificationRoute,
  ProviderRegisterRoute,
  MainAboutUsRoute
} from './RoutesPublic';
import * as serviceWorker from './serviceWorker';

function Root() {
  return (
    <Application>
      <Suspense fallback={<div />}>
        <Switch>
          {DoctorLoginRoute}
          {LoginActivationRoute}
          {LoginRoute}
          {ServerMessagesRoute}
          {SessionExpiredRoute}
          {UserRegisterConfirmNewUserRoute}
          {UserRegisterTermsOfUseRoute}
          {UserRegisterEConsentRoute}
          {UserRegisterFormRoute}
          {UserNotEligibleRoute}
          {UserRegisterEmailVerificationRoute}
          {ProviderRegisterRoute}
          {MainAboutUsRoute}

          {HomeRoute}
          {FourOhFourRoute}
        </Switch>
      </Suspense>
    </Application>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
