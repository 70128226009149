const extractHttpResponse = (props = {}) => {
  const { response = {} } = props;
  const { status, statusText } = response;
  const { errors, httpStatus, responseEntity } = response.data || {};

  return {
    data: responseEntity,
    errors,
    status: parseInt(httpStatus || status, 10),
    statusText
  };
};

export default extractHttpResponse;
