import axios from 'axios';
import { each } from 'lodash';
import * as systemActions from '_qoc-redux/System/actions';
import extractHttpResponse from '_qoc-redux/utils/extractHttpResponse';
import fulfillAction from '_qoc-redux/utils/fulfillAction';
import getFirstChar from '_qoc-redux/utils/getFirstChar';
import rejectAction from '_qoc-redux/utils/rejectAction';

import { TYPES } from './constants';

/**
 * This function will take a url string as parameter and
 * replace any url/query parameter specifically for register urls then
 * invoke system action to redirect to that url
 *
 * @param {*} url string - redirect url
 * @returns Object
 */
export function redirectRegisterUrl(url) {
  return (dispatch, getState) => {
    const { clinicId } = getState().userRegister || {};
    const replacedUrl = url.replace(/:clinicId/g, clinicId);

    dispatch(systemActions.onRedirectUrl(replacedUrl));

    return {
      type: TYPES.REDIRECT_REGISTER_URL
    };
  };
}

// factory for creating api functions
const createApiFunction = params => {
  const { url, types, method } = params;
  const apiFulfilled = (props = {}) => {
    return fulfillAction({
      ...props,
      type: types.fulfilled
    });
  };
  const apiPending = (props = {}) => {
    return { payload: props, type: types.pending };
  };
  const apiRejected = (props = {}) => {
    return rejectAction({
      ...props,
      type: types.rejected
    });
  };
  return (props = {}) => {
    const errorsResponse = [];

    return async dispatch => {
      dispatch(apiPending());

      const { queryParams = {}, values = {} } = props;

      try {
        let injectedUrl = url;
        if (url.includes(':')) {
          const matches = [...url.matchAll(/:([a-zA-Z]+)/g)];

          each(matches, match => {
            const key = match[1];
            if (values[key]) {
              injectedUrl = injectedUrl.replace(match[0], values[key]);
            }
          });
        }

        const response = await axios({
          params: queryParams,
          data: values,
          method: method || 'post',
          url: injectedUrl
        });

        // console.log(url, response);
        const {
          errors = [],
          status,
          statusText: message
        } = extractHttpResponse({
          response
        });

        if (errors.length > 0 && errors[0]) {
          errorsResponse.push(...errors);

          throw new Error(errors[0].reason);
        }

        if (getFirstChar({ value: status }) === '2') {
          return dispatch(
            apiFulfilled({
              message,
              status,
              data: response.data
            })
          );
        }

        throw new Error('Status not in 2xx range');
      } catch (error) {
        return dispatch(
          apiRejected({
            error: errorsResponse[0] || error
          })
        );
      }
    };
  };
};

/* validateLogin */
export const validateLogin = createApiFunction({
  url: '/api/register/validatelogin',
  types: {
    fulfilled: TYPES.VALIDATE_LOGIN_FULFILLED,
    pending: TYPES.VALIDATE_LOGIN_PENDING,
    rejected: TYPES.VALIDATE_LOGIN_REJECTED
  }
});

/* registerUser */
export const registerUser = createApiFunction({
  url: '/api/register/user',
  types: {
    fulfilled: TYPES.REGISTER_USER_FULFILLED,
    pending: TYPES.REGISTER_USER_PENDING,
    rejected: TYPES.REGISTER_USER_REJECTED
  }
});

/* sendEmailVerification */
export const sendEmailVerification = createApiFunction({
  url: '/api/logins/auth/activate',
  types: {
    fulfilled: TYPES.SEND_EMAIL_VERIFICATION_FULFILLED,
    pending: TYPES.SEND_EMAIL_VERIFICATION_PENDING,
    rejected: TYPES.SEND_EMAIL_VERIFICATION_REJECTED
  }
});

/* activateAccount */
export const activateAccount = createApiFunction({
  url: '/api/register/activation/:tokenUUID',
  method: 'get',
  types: {
    fulfilled: TYPES.ACTIVATE_ACCOUNT_FULFILLED,
    pending: TYPES.ACTIVATE_ACCOUNT_PENDING,
    rejected: TYPES.ACTIVATE_ACCOUNT_REJECTED
  }
});

// non-api actions

export function setMaybeLoginFormValues(values) {
  return {
    payload: {
      values
    },
    type: TYPES.SET_MAYBE_LOGIN_FORM_VALUES
  };
}

export function setActivatedUserInfo(values) {
  return {
    payload: {
      values
    },
    type: TYPES.SET_ACTIVATED_USER_INFO
  };
}

export function setEmailToActivate(emailToActivate) {
  return {
    payload: {
      emailToActivate
    },
    type: TYPES.SET_EMAIL_TO_ACTIVATE
  };
}

export function setTermsOfUseAgree(termsOfUseAgree) {
  return {
    payload: {
      termsOfUseAgree
    },
    type: TYPES.SET_TERMS_OF_USE_AGREE
  };
}

export function setEConsentAgree(eConsentAgree) {
  return {
    payload: {
      eConsentAgree
    },
    type: TYPES.SET_E_CONSENT_AGREE
  };
}

export function setRegisterFormValues(values) {
  return {
    payload: {
      values
    },
    type: TYPES.SET_REGISTER_FORM_VALUES
  };
}

export function setTokenUUID(tokenUUID) {
  return {
    payload: {
      tokenUUID
    },
    type: TYPES.SET_TOKEN_UUID
  };
}
