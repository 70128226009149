export const types = {
  INITIALIZE_PATIENT_PROFILE_FULFILLED: 'INITIALIZE_PATIENT_PROFILE_FULFILLED',
  INITIALIZE_PATIENT_PROFILE_PENDING: 'INITIALIZE_PATIENT_PROFILE_PENDING',
  INITIALIZE_PATIENT_PROFILE_REJECTED: 'INITIALIZE_PATIENT_PROFILE_REJECTED',

  ON_RESET_CDSS_PROGRESS_FULFILLED: 'ON_RESET_CDSS_PROGRESS_FULFILLED',
  ON_RESET_CDSS_PROGRESS_PENDING: 'ON_RESET_CDSS_PROGRESS_PENDING',
  ON_RESET_CDSS_PROGRESS_REJECTED: 'ON_RESET_CDSS_PROGRESS_REJECTED',

  ON_CHANGE_ROUTE: 'ON_CHANGE_ROUTE',

  ON_UPDATE_AAP_STATUS_FULFILLED: 'ON_UPDATE_AAP_STATUS_FULFILLED',
  ON_UPDATE_AAP_STATUS_PENDING: 'ON_UPDATE_AAP_STATUS_PENDING',
  ON_UPDATE_AAP_STATUS_REJECTED: 'ON_UPDATE_AAP_STATUS_REJECTED',

  ON_UPDATE_PROFILE_PROPERTIES_FULFILLED:
    'ON_UPDATE_PROFILE_PROPERTIES_FULFILLED',
  ON_UPDATE_PROFILE_PROPERTIES_PENDING: 'ON_UPDATE_PROFILE_PROPERTIES_PENDING',
  ON_UPDATE_PROFILE_PROPERTIES_REJECTED:
    'ON_UPDATE_PROFILE_PROPERTIES_REJECTED',

  ON_SEND_AAP_TO_OSCAR_FULFILLED: 'ON_SEND_AAP_TO_OSCAR_FULFILLED',
  ON_SEND_AAP_TO_OSCAR_PENDING: 'ON_SEND_AAP_TO_OSCAR_PENDING',
  ON_SEND_AAP_TO_OSCAR_REJECTED: 'ON_SEND_AAP_TO_OSCAR_REJECTED',

  // ON_SEND_MRP_MESSAGE_TO_OSCAR_FULFILLED:
  //   'ON_SEND_MRP_MESSAGE_TO_OSCAR_FULFILLED',
  // ON_SEND_MRP_MESSAGE_TO_OSCAR_PENDING: 'ON_SEND_MRP_MESSAGE_TO_OSCAR_PENDING',
  // ON_SEND_MRP_MESSAGE_TO_OSCAR_REJECTED:
  //   'ON_SEND_MRP_MESSAGE_TO_OSCAR_REJECTED',

  ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_FULFILLED:
    'ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_FULFILLED',
  ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_PENDING:
    'ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_PENDING',
  ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_REJECTED:
    'ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_REJECTED',

  ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_FULFILLED:
    'ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_FULFILLED',
  ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_PENDING:
    'ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_PENDING',
  ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_REJECTED:
    'ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_REJECTED',

  ON_POST_SCREEN_FULFILLED: 'ON_POST_SCREEN_FULFILLED',
  ON_POST_SCREEN_PENDING: 'ON_POST_SCREEN_PENDING',
  ON_POST_SCREEN_REJECTED: 'ON_POST_SCREEN_REJECTED',

  ON_RELEASE_PATIENT_RECORD_FULFILLED: 'ON_RELEASE_PATIENT_RECORD_FULFILLED',
  ON_RELEASE_PATIENT_RECORD_PENDING: 'ON_RELEASE_PATIENT_RECORD_PENDING',
  ON_RELEASE_PATIENT_RECORD_REJECTED: 'ON_RELEASE_PATIENT_RECORD_REJECTED',

  ON_UPDATE_SCREEN_5_DISPLAY: 'ON_UPDATE_SCREEN_5_DISPLAY',

  REDIRECT_TO_COMPLETE: 'REDIRECT_TO_COMPLETE',

  ON_REACH_SCREEN_2: 'ON_REACH_SCREEN_2',
  ON_REACH_SCREEN_3: 'ON_REACH_SCREEN_3',
  DECISION_SUPPORT_RESUMED: 'DECISION_SUPPORT_RESUMED'
};

export default {
  types
};
