import { chain, find, get } from 'lodash';

import shouldResetCDSSProgress from '_cdss/library/businessLogic/shouldResetCDSSProgress';
import { actions as qActions } from '_qoc-redux';
import { URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE } from '_constants/urls';
import {
  QUESTION_ID_AAP_COMPLETED,
  QUESTION_ID_AAP_DEFERED,
  QUESTION_ID_AAP_CONTINUED,
  QUESTION_ID_AAP_APPROVED,
  QUESTION_ID_AAP_PRINTED,
  QUESTION_ID_PATIENT_NOT_SUITABLE,
  SURVEY_LINK_UUID_AAP_COMPLETED,
  SURVEY_LINK_UUID_AAP_DEFERED,
  SURVEY_LINK_UUID_AAP_CONTINUED,
  SURVEY_LINK_UUID_AAP_APPROVED,
  SURVEY_LINK_UUID_AAP_PRINTED,
  SURVEY_LINK_UUID_PATIENT_NOT_SUITABLE
} from '_cdss/constants/surveys';
import cdssSDK from '_cdss/sdk';

import { types } from './constants';
import { actions as patientProfilesActions } from '../patientProfiles';
import { AAP_STATUS, SCREENS } from '../constants';
import { patientProfilesSelector } from '../selectors';
import {
  QUESTION_ID_PATIENT_DOES_NOT_HAVE_ASTHMA,
  QUESTION_ID_PATIENT_IS_PREGNANT
} from '../../constants/surveys';

function findResponseResponseByQuestionId(props) {
  const { instanceLinkUUID, responses = [], questionId } = props || {};

  return find(
    responses,
    response =>
      response.instanceLinkUUID === instanceLinkUUID &&
      response.questionId === questionId
  );
}

export const onUpdatePatientProfileProperties = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.ON_UPDATE_PROFILE_PROPERTIES_PENDING
  });

  try {
    const {
      propertyLinks,
      selectedProfile,
      assignLastUpdatedPropertyLinks = true
    } = props;

    if (!selectedProfile) {
      throw new Error('No selected profile');
    }

    const doctorProfileUUID =
      get(getState(), 'profile.profileVO.profile.profileUUID') || '';
    const profiles = get(getState(), 'profile.profiles');
    const targetProfile =
      find(
        profiles,
        ({ profileUUID }) => profileUUID === selectedProfile.profileUUID
      ) || {};
    const lastUpdatedTimestamp = new Date().getTime();
    const updatedTargetProfile = Object.assign({}, targetProfile, {
      propertyLinks: assignLastUpdatedPropertyLinks
        ? [
            {
              propertyKey: 'lastUpdatedBy',
              propertyValue: doctorProfileUUID
            },
            {
              propertyKey: 'lastUpdatedTimestamp',
              propertyValue: lastUpdatedTimestamp
            }
          ]
        : propertyLinks || [
            {
              propertyKey: 'lastUpdatedBy',
              propertyValue: ''
            },
            {
              propertyKey: 'lastUpdatedTimestamp',
              propertyValue: lastUpdatedTimestamp
            }
          ] ||
          null
    });

    if (updatedTargetProfile.profileUUID) {
      await dispatch(
        qActions.profile.updateProfile({
          requestProps: updatedTargetProfile
        })
      );
    }

    return dispatch({
      data: { updatedTargetProfile },
      type: types.ON_UPDATE_PROFILE_PROPERTIES_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.ON_UPDATE_PROFILE_PROPERTIES_REJECTED
    });
  }
};

export const onReleasePatientRecord = () => async (dispatch, getState) => {
  dispatch({
    type: types.ON_RELEASE_PATIENT_RECORD_PENDING
  });

  try {
    const { selectedProfile } = getState().patientProfiles || {};
    const doctorProfileUUID =
      get(getState(), 'profile.profileVO.profile.profileUUID') || '';
    const profiles = get(getState(), 'profile.profiles') || [];
    const targetProfile =
      find(
        profiles,
        ({ profileUUID }) => profileUUID === selectedProfile.profileUUID
      ) || {};
    const lastUpdatedBy =
      find(targetProfile.propertyLinks, { propertyKey: 'lastUpdatedBy' }) || {};

    if (lastUpdatedBy.propertyValue === doctorProfileUUID) {
      await dispatch(
        onUpdatePatientProfileProperties({
          selectedProfile,
          assignLastUpdatedPropertyLinks: false
        })
      );
    }

    return dispatch({
      type: types.ON_RELEASE_PATIENT_RECORD_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.ON_RELEASE_PATIENT_RECORD_REJECTED
    });
  }
};

export const onPostScreen = (props = {}) => async (dispatch, getState) => {
  dispatch({
    type: types.ON_POST_SCREEN_PENDING
  });
  const { responseSetId = 'cdss', screen: screenProps } = props;

  try {
    const selectedProfile =
      get(getState(), 'patientProfiles.selectedProfile') || {};
    const { instanceLinkUUID = '' } = selectedProfile || {};
    const responseList = get(getState(), 'cache.responses');
    const questionId = 'screen';
    const surveyLinkUUID = 'CE3B4256-6BCC-4B35-A641-B2D88CBCA36A';
    const screen = SCREENS[screenProps] || screenProps;

    const responses = chain(responseList)
      .find({
        instanceLinkUUID,
        questionId,
        responseSetId
      })
      .thru(surveyResponse => {
        if (surveyResponse) {
          return [
            Object.assign({}, surveyResponse, {
              responseString: screen,
              responseText: screen
            })
          ];
        }

        return [
          {
            instanceLinkUUID,
            responseDataType: 'string',
            responseString: screen,
            responseText: screen,
            questionId,
            responseSetId,
            surveyLinkUUID
          }
        ];
      })
      .value();

    if (instanceLinkUUID) {
      await Promise.all([
        dispatch(
          qActions.cache.postInstancesResponses({
            requestProps: {
              surveyResponses: responses
            }
          })
        ),
        dispatch(
          onUpdatePatientProfileProperties({
            selectedProfile
          })
        )
      ]);
    }

    return dispatch({
      data: {
        responses
      },
      type: types.ON_POST_SCREEN_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.ON_POST_SCREEN_REJECTED
    });
  }
};

/* onSendAAPToOscar */
export const onSendAAPToOscarFulfilled = props => {
  const { data } = props || {};

  return {
    data,
    type: types.ON_SEND_AAP_TO_OSCAR_FULFILLED
  };
};
export const onSendAAPToOscarRejected = props => {
  const { error } = props || {};

  return {
    data: {
      error
    },
    type: types.ON_SEND_AAP_TO_OSCAR_REJECTED
  };
};
export const onSendAAPToOscar = props => async (dispatch, getState) => {
  dispatch({
    type: types.ON_SEND_AAP_TO_OSCAR_PENDING
  });
  const {
    providerProfileUUID,
    encodedString,
    instanceLinkUUID: instanceLinkUUIDProps,
    patientHash: patientHashProps
  } = props || {};

  try {
    const selectProfile =
      get(getState(), 'patientProfiles.selectedProfile') || {};
    const instanceLinkUUID =
      instanceLinkUUIDProps || selectProfile.instanceLinkUUID;
    const patientHash = patientHashProps || selectProfile.patientHash;

    const postAAPToOscarVO = {
      encodedString,
      instanceLinkUUID,
      patientHash,
      providerProfileUUID
    };
    const request = await cdssSDK.postAAPToOscar({ postAAPToOscarVO });

    if (!request) {
      return dispatch(
        onSendAAPToOscarRejected({
          error: { message: 'request is null' }
        })
      );
    }

    return dispatch(
      onSendAAPToOscarFulfilled({
        data: {
          postAAPToOscarVO
        }
      })
    );
  } catch (error) {
    return dispatch(onSendAAPToOscarRejected({ error }));
  }
};
/* /onSendAAPToOscar */

/* onSendMRPMessageToOscarQueue */
export const onSendMRPMessageToOscarQueueFulfilled = props => {
  const { data } = props || {};

  return {
    data,
    type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_FULFILLED
  };
};
export const onSendMRPMessageToOscarQueueRejected = props => {
  const { error } = props || {};

  return {
    data: {
      error
    },
    type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_REJECTED
  };
};
export const onSendMRPMessageToOscarQueue = props => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_QUEUE_PENDING
  });

  try {
    const { instanceLinkUUID, patientHash } =
      get(getState(), 'patientProfiles.selectedProfile') || {};
    const { mrpMessage } = props || {};
    console.log('onSendMRPMessageToOscarQueue props: ', props);
    const queueMRPMessageToOscarVO = {
      instanceLinkUUID,
      mrpMessage,
      patientHash
    };

    console.log(
      'onSendMRPMessageToOscarQueue queueMRPMessageToOscarVO: ',
      queueMRPMessageToOscarVO
    );

    const request = await cdssSDK.queueMRPMessageToOscar({
      queueMRPMessageToOscarVO
    });

    if (!request) {
      return dispatch(
        onSendMRPMessageToOscarQueueRejected({
          error: { message: 'request is null' }
        })
      );
    }

    return dispatch(
      onSendMRPMessageToOscarQueueFulfilled({
        data: {
          queueMRPMessageToOscarVO
        }
      })
    );
  } catch (error) {
    return dispatch(onSendMRPMessageToOscarQueueRejected({ error }));
  }
};
/* onMRPMessageToOscarQueue */

/* onMRPMessageToOscarUnqueue */
export const onSendMRPMessageToOscarUnqueueFulfilled = props => {
  const { data } = props || {};

  return {
    data,
    type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_FULFILLED
  };
};
export const onSendMRPMessageToOscarUnqueueRejected = props => {
  const { error } = props || {};

  return {
    data: {
      error
    },
    type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_REJECTED
  };
};
export const onSendMRPMessageToOscarUnqueue = () => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_UNQUEUE_PENDING
  });

  try {
    const { instanceLinkUUID } =
      get(getState(), 'patientProfiles.selectedProfile') || {};

    const unqueueMRPMessageToOscarVO = {
      instanceLinkUUID
    };

    const request = await cdssSDK.unqueueMRPMessageToOscar({
      unqueueMRPMessageToOscarVO
    });

    if (!request) {
      return dispatch(
        onSendMRPMessageToOscarUnqueueRejected({
          error: { message: 'request is null' }
        })
      );
    }

    return dispatch(
      onSendMRPMessageToOscarUnqueueFulfilled({
        data: {
          unqueueMRPMessageToOscarVO
        }
      })
    );
  } catch (error) {
    return dispatch(onSendMRPMessageToOscarUnqueueRejected({ error }));
  }
};
/* onMRPMessageToOscarUnqueue */

/* onSendMRPMessageToOscar */
// export const onSendMRPMessageToOscarFulfilled = props => {
//   const { data } = props || {};

//   return {
//     data,
//     type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_FULFILLED
//   };
// };
// export const onSendMRPMessageToOscarRejected = props => {
//   const { error } = props || {};

//   return {
//     data: {
//       error
//     },
//     type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_REJECTED
//   };
// };
// export const onSendMRPMessageToOscar = () => async (dispatch, getState) => {
//   dispatch({
//     type: types.ON_SEND_MRP_MESSAGE_TO_OSCAR_PENDING
//   });

//   try {
//     const { instanceLinkUUID, patientHash, mrp } =
//       get(getState(), 'patientProfiles.selectedProfile') || {};
//     const postMRPMessageToOscarVO = {
//       instanceLinkUUID,
//       mrp,
//       patientHash
//     };
//     const request = await cdssSDK.postMRPMessageToOscar({
//       postMRPMessageToOscarVO
//     });

//     if (!request) {
//       return dispatch(
//         onSendMRPMessageToOscarRejected({
//           error: { message: 'request is null' }
//         })
//       );
//     }

//     return dispatch(
//       onSendMRPMessageToOscarFulfilled({
//         data: {
//           postMRPMessageToOscarVO
//         }
//       })
//     );
//   } catch (error) {
//     return dispatch(onSendMRPMessageToOscarRejected({ error }));
//   }
// };
/* /onSendMRPMessageToOscar */

const AAP_STATUS_MAPPER = {
  [AAP_STATUS.DEFERED]: {
    key: 'responseState',
    questionId: QUESTION_ID_AAP_DEFERED,
    surveyLinkUUID: SURVEY_LINK_UUID_AAP_DEFERED
  },
  [AAP_STATUS.CONTINUED]: {
    key: 'responseState',
    questionId: QUESTION_ID_AAP_CONTINUED,
    surveyLinkUUID: SURVEY_LINK_UUID_AAP_CONTINUED
  },
  [AAP_STATUS.APPROVED]: {
    key: 'responseState',
    questionId: QUESTION_ID_AAP_APPROVED,
    surveyLinkUUID: SURVEY_LINK_UUID_AAP_APPROVED
  },
  [AAP_STATUS.PRINTED]: {
    key: 'responseState',
    questionId: QUESTION_ID_AAP_PRINTED,
    surveyLinkUUID: SURVEY_LINK_UUID_AAP_PRINTED
  },
  [AAP_STATUS.NOT_SUITABLE]: {
    key: 'responseState',
    questionId: QUESTION_ID_PATIENT_NOT_SUITABLE,
    surveyLinkUUID: SURVEY_LINK_UUID_PATIENT_NOT_SUITABLE
  },
  [AAP_STATUS.COMPLETED]: {
    key: 'responseState',
    questionId: QUESTION_ID_AAP_COMPLETED,
    surveyLinkUUID: SURVEY_LINK_UUID_AAP_COMPLETED
  }
};

export const onUpdateAAPStatus = (props = {}) => async (dispatch, getState) => {
  dispatch({
    type: types.ON_UPDATE_AAP_STATUS_PENDING
  });

  const { aapStatus, completeAAP, onFulfilled } = props;

  try {
    const aapStatusData = AAP_STATUS_MAPPER[aapStatus];

    if (aapStatusData) {
      const selectedProfile =
        get(getState(), 'patientProfiles.selectedProfile') || {};
      const responses = get(getState(), 'cache.responses') || {};
      const { instanceLinkUUID } = selectedProfile;

      // Get responses from redux cache state and update values
      const dataToMapSurveyResponses =
        aapStatus === AAP_STATUS.COMPLETED
          ? [
              {
                ...aapStatusData,
                value: true
              }
            ]
          : [
              {
                ...aapStatusData,
                value: true
              },
              {
                ...AAP_STATUS_MAPPER[AAP_STATUS.COMPLETED],
                value: completeAAP
              }
            ];

      const surveyResponses = dataToMapSurveyResponses.map(
        ({ key, questionId, surveyLinkUUID, value }) => {
          const targetSurveyResponse = findResponseResponseByQuestionId({
            instanceLinkUUID,
            responses,
            questionId
          });

          const response = targetSurveyResponse
            ? Object.assign({}, targetSurveyResponse, {
                [key]: value
              })
            : {
                instanceLinkUUID,
                [key]: value,
                questionId,
                surveyLinkUUID
              };

          return response;
        }
      );

      const results = await Promise.all([
        dispatch(
          qActions.cache.postInstancesResponses({
            requestProps: {
              surveyResponses
            }
          })
        ),
        dispatch(onUpdatePatientProfileProperties({ selectedProfile }))
      ]);

      if (typeof onFulfilled === 'function') {
        onFulfilled({ dataToMapSurveyResponses, results, surveyResponses });
      }

      return dispatch({
        data: {
          dataToMapSurveyResponses,
          results,
          surveyResponses
        },
        type: types.ON_UPDATE_AAP_STATUS_FULFILLED
      });
    }

    throw new Error('Invalid aapStatus');
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.ON_UPDATE_AAP_STATUS_REJECTED
    });
  }
};

export const onChangeRoute = isRouteChanged => ({
  data: {
    isRouteChanged
  },
  type: types.ON_CHANGE_ROUTE
});

export const onResetCDSSProgress = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.ON_RESET_CDSS_PROGRESS_PENDING
  });

  try {
    const { instanceLinkUUID } = props;

    if (!instanceLinkUUID) {
      throw new Error('No instanceLinkUUID provided');
    }

    const instanceLink = chain(getState())
      .get('cache.instanceLinks')
      .find({ instanceLinkUUID })
      .value();

    const doctorNotificationResponseSetIds = [
      'doctorRecommendations',
      'rulesRecommendations',
      'rulesRecommendationsMedicationOptions',
      'stepUpRecommendations',
      'cdss'
    ];

    const questionIds = [
      QUESTION_ID_AAP_APPROVED,
      QUESTION_ID_AAP_COMPLETED,
      QUESTION_ID_AAP_CONTINUED,
      QUESTION_ID_AAP_DEFERED,
      QUESTION_ID_AAP_PRINTED,
      QUESTION_ID_PATIENT_NOT_SUITABLE,
      QUESTION_ID_PATIENT_DOES_NOT_HAVE_ASTHMA,
      QUESTION_ID_PATIENT_IS_PREGNANT
    ];

    const surveyResponseUUIDs = chain(getState())
      .get('cache.responses')
      .filter(
        surveyResponse =>
          surveyResponse.instanceLinkUUID === instanceLinkUUID &&
          (doctorNotificationResponseSetIds.includes(
            surveyResponse.responseSetId
          ) ||
            questionIds.includes(surveyResponse.questionId))
      )
      .map('surveyResponseUUID')
      .value();

    const selectedProfile =
      chain(getState())
        .get('profile.profiles')
        .find({ profileUUID: instanceLink.profileUUID })
        .value() || {};

    if (selectedProfile.profileUUID) {
      await Promise.all([
        dispatch(
          qActions.cache.deleteInstancesResponses({
            requestProps: {
              surveyResponseUUIDs
            }
          })
        ),
        dispatch(
          onUpdatePatientProfileProperties({
            assignLastUpdatedPropertyLinks: false,
            selectedProfile
          })
        )
      ]);
    }

    return dispatch({
      type: types.ON_RESET_CDSS_PROGRESS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.ON_RESET_CDSS_PROGRESS_REJECTED
    });
  }
};

export const onUpdateScreen5Display = (props = {}) => {
  const { screen5Display } = props;

  return {
    data: {
      screen5Display
    },
    type: types.ON_UPDATE_SCREEN_5_DISPLAY
  };
};

export const redirectToComplete = () => async (dispatch, getState) => {
  const { instanceLinkUUID, patientHash } =
    get(getState(), 'patientProfiles.selectedProfile') || {};

  await dispatch(
    qActions.system.onRedirectUrl(
      `${URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE.replace(
        /:patientHash/g,
        patientHash
      )}?instanceLinkUUID=${instanceLinkUUID}`
    )
  );

  return {
    type: types.REDIRECT_TO_COMPLETE
  };
};

/* initializePatientProfile */
const initializePatientProfileFulfilled = props => {
  return {
    payload: {
      message: 'Success',
      status: 200,
      ...props
    },
    type: types.INITIALIZE_PATIENT_PROFILE_FULFILLED
  };
};
const initializePatientProfileRejected = (props = {}) => {
  const { error, ...otherProps } = props;

  return {
    payload: {
      error,
      message: error.message,
      status: 500,
      ...otherProps
    },
    type: types.INITIALIZE_PATIENT_PROFILE_REJECTED
  };
};

export const initializePatientProfile = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.INITIALIZE_PATIENT_PROFILE_PENDING
  });

  try {
    const {
      cdss = true,
      instanceLinkUUID: instanceLinkUUIDProps,
      patientHash,
      mrp
    } = props;

    const requestProps = instanceLinkUUIDProps
      ? { instanceLinkUUID: instanceLinkUUIDProps }
      : {};
    const instanceLinksResponse = await dispatch(
      qActions.cache.fetchInstanceLinks({
        requestProps
      })
    );
    const instanceLinks = get(instanceLinksResponse, 'instanceLinks') || {};
    const instanceLink =
      Object.keys(instanceLinks).map(key => instanceLinks[key])[0] || {};
    const { instanceLinkUUID, profileUUID } = instanceLink;

    if (instanceLinkUUID) {
      await Promise.all([
        dispatch(qActions.profile.fetchProfiles()),
        dispatch(
          qActions.cache.fetchInstancesQuestions({
            requestProps: { instanceLinkUUID }
          })
        ),
        dispatch(
          qActions.cache.fetchInstancesQuestionsOptions({
            requestProps: { instanceLinkUUID }
          })
        ),
        dispatch(
          qActions.cache.fetchInstancesResponses({
            requestProps: { instanceLinkUUID }
          })
        )
      ]);

      const profiles = patientProfilesSelector(getState());
      const patientProfile = find(profiles, { instanceLinkUUID }) || {};

      await dispatch(
        patientProfilesActions.selectProfile({
          patientProfile: Object.assign({}, patientProfile, {
            patientHash,
            mrp: mrp || ''
          })
        })
      );

      dispatch(qActions.profile.fetchDoctorProfile());

      if (cdss) {
        const { lastUpdated } = patientProfile;
        const todaysDate = new Date();
        const todaysDateTime = todaysDate.getTime();
        const loginId =
          get(getState(), 'profile.profileVO.profile.loginId') || '';

        // Post 'patientInstanceLinkUUID' key in session with instanceLinkUUID doctor opens for
        // session expire to catch if session expired and chart note was not sent. This will ensure
        // chart note is sent
        dispatch(
          qActions.session.postPutSessionKeyValue({
            requestProps: {
              key: 'patientInstanceLinkUUID',
              value: instanceLinkUUID
            }
          })
        );
        dispatch(
          qActions.session.postPutSessionKeyValue({
            requestProps: {
              key: 'patientProfileUUID',
              value: profileUUID
            }
          })
        );
        dispatch(
          qActions.session.postPutSessionKeyValue({
            requestProps: {
              key: 'loginId',
              value: loginId
            }
          })
        );
        if (patientHash) {
          dispatch(
            qActions.session.postPutSessionKeyValue({
              requestProps: {
                key: 'patientHash',
                value: patientHash
              }
            })
          );
        }
        if (mrp) {
          dispatch(
            qActions.session.postPutSessionKeyValue({
              requestProps: {
                key: 'mrp',
                value: mrp
              }
            })
          );
        }

        const resetCDSSProgress = shouldResetCDSSProgress({
          lastUpdatedDateTime: lastUpdated,
          todaysDateTime
        });

        if (resetCDSSProgress) {
          await dispatch(
            onResetCDSSProgress({
              instanceLinkUUID
            })
          );
        }
      }
    } else {
      return dispatch(
        initializePatientProfileRejected({
          error: {
            message: 'Request for instance link has no response'
          },
          status: 404
        })
      );
    }

    return dispatch(initializePatientProfileFulfilled());
  } catch (error) {
    return dispatch(initializePatientProfileRejected({ error }));
  }
};

export const onReachScreen2 = () => {
  return {
    type: types.ON_REACH_SCREEN_2
  };
};

export const onReachScreen3 = () => {
  return {
    type: types.ON_REACH_SCREEN_3
  };
};

export const decisionSupportResumed = () => {
  return {
    type: types.DECISION_SUPPORT_RESUMED
  };
};
