import filter from 'lodash/filter';
import size from 'lodash/size';

const isDestinationStoreEmpty = (props = {}) => {
  const { destinationStore, responses = [] } = props;

  const filteredResponses = filter(responses, {
    responseSetId: destinationStore
  });
  const filteredResponsesSize = size(filteredResponses);

  return filteredResponsesSize === 0;
};

export default isDestinationStoreEmpty;
