import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import getScreen from '_cdss/library/businessLogic/getScreen';
import { actions as qocActions } from '_qoc-redux';
import sdk from '_cdss/sdk';

import { types } from './constants';
import { patientProfilesSelector } from '../selectors';

export const selectProfile = props => dispatch => {
  const { patientProfile } = props || {};

  dispatch({
    payload: {
      selectedProfile: patientProfile
    },
    type: types.SELECT_PATIENT
  });
};

export const selectScreen = props => (dispatch, getState) => {
  const { instanceLinkUUID } = props || {};

  const profiles = patientProfilesSelector(getState());
  const patientProfile = find(profiles, { instanceLinkUUID }) || {};

  const screen = getScreen({ patientProfile });

  return dispatch({
    payload: { screen },
    type: types.SELECT_SCREEN
  });
};

export const redirectToScreen = (props = {}) => (dispatch, getState) => {
  const { screen, instanceLinkUUID, patientHash } = props;

  const targetScreen = screen || getState().patientProfiles.screen;

  const redirectUrl = targetScreen.replace(
    /:instanceLinkUUID/,
    instanceLinkUUID
  );
  const { pathname } = getState().router.location;

  if (targetScreen !== '' && redirectUrl !== pathname) {
    dispatch(
      qocActions.system.onRedirectUrl(
        `${targetScreen.replace(
          /:patientHash/,
          patientHash
        )}?instanceLinkUUID=${instanceLinkUUID}`
      )
    );
  }
};

export const getAssignedPatients = () => async dispatch => {
  const assignedPatients = await sdk.getAssignedPatients();

  if (!assignedPatients) {
    return [];
  }

  const instanceLinks = keyBy(
    Array.isArray(assignedPatients) ? assignedPatients : [assignedPatients],
    'instanceLinkUUID'
  );

  const fulfillProps = {
    instanceLinks,
    message: 'Fetched assigned patiented successfully',
    status: 200
  };

  await dispatch(qocActions.cache.fetchInstanceLinksFulfilled(fulfillProps));

  return assignedPatients;
};
