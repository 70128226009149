import { TYPES } from './constants';
import { ASYNC_STATUSES } from '../constants';

export const initialState = {
  error: false,
  initialized: false,
  modalShowing: false,
  modalContent: null,
  initializeSystem: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  status: 'idle',
  toastShowing: false,
  toastMessage: ''
};

export default (state = initialState, action) => {
  if (action.type === TYPES.CHANGE_SYSTEM_MODAL) {
    return Object.assign({}, state, {
      modalContent: action.payload
    });
  }

  if (action.type === TYPES.DISPLAY_SYSTEM_MODAL) {
    return Object.assign({}, state, {
      modalShowing: action.payload
    });
  }

  if (action.type === TYPES.CHANGE_SYSTEM_TOAST) {
    return Object.assign({}, state, {
      toastMessage: action.payload
    });
  }

  if (action.type === TYPES.DISPLAY_SYSTEM_TOAST) {
    return Object.assign({}, state, {
      toastShowing: action.payload
    });
  }

  if (action.type === TYPES.INITIALIZE_SYSTEM_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      initialized: true,
      initializeSystem: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.INITIALIZE_SYSTEM_PENDING) {
    return Object.assign({}, state, {
      initializeSystem: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.INITIALIZE_SYSTEM_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      error: message,
      initialized: false,
      initializeSystem: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.ON_PROCESS_TASK_FULFILLED) {
    return Object.assign({}, state, {
      error: null,
      status: 'idle'
    });
  }

  if (action.type === TYPES.ON_PROCESS_TASK_PENDING) {
    return Object.assign({}, state, {
      error: null,
      status: 'loading'
    });
  }

  if (action.type === TYPES.ON_PROCESS_TASK_REJECTED) {
    return Object.assign({}, state, {
      error: action.error,
      status: 'idle'
    });
  }

  return state;
};
