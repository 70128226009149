export const TYPES = {
  CHANGE_SYSTEM_MODAL: 'CHANGE_SYSTEM_MODAL',
  DISPLAY_SYSTEM_MODAL: 'DISPLAY_SYSTEM_MODAL',

  CHANGE_SYSTEM_TOAST: 'CHANGE_SYSTEM_TOAST',
  DISPLAY_SYSTEM_TOAST: 'DISPLAY_SYSTEM_TOAST',

  INITIALIZE_SYSTEM_FULFILLED: 'INITIALIZE_SYSTEM_FULFILLED',
  INITIALIZE_SYSTEM_PENDING: 'INITIALIZE_SYSTEM_PENDING',
  INITIALIZE_SYSTEM_REJECTED: 'INITIALIZE_SYSTEM_REJECTED',

  HISTORY_LISTEN: 'HISTORY_LISTEN',

  FIRST_LOGIN: 'FIRST_LOGIN',

  ON_REDIRECT_URL: 'ON_REDIRECT_URL',
  ON_FORCE_REDIRECT_URL: 'ON_FORCE_REDIRECT_URL',

  ON_PROCESS_TASK_FULFILLED: 'ON_PROCESS_TASK_FULFILLED',
  ON_PROCESS_TASK_PENDING: 'ON_PROCESS_TASK_PENDING',
  ON_PROCESS_TASK_REJECTED: 'ON_PROCESS_TASK_REJECTED'
};

export default {
  TYPES
};
