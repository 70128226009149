/* eslint-disable */
import sdk from '@eams-dev/js-qoc-sdk';
import { TYPES } from './constants';
import { ERROR_NOT_IN_2XX } from '../constants';
import extractHttpResponse from '../utils/extractHttpResponse';
import fulfillAction from '../utils/fulfillAction';
import getFirstChar from '../utils/getFirstChar';
import rejectAction from '../utils/rejectAction';

/**
 * deleteSessionKey
 * Description: Delete Session Key.
 * @param {Object} props (default={})
 * @param {Object} props.requestProps (default={}) Request props for the sdk function call
 * @param {string} props.requestProps.key Session key
 * @param {Function} props.onFulfilled Callback function when is successfully fulfilled. If undefined, will not be called
 * @param {string} props.onFulfilledMessage Message to store in redux state when is successfully fulfilled. If non truthy, will use message from request
 * @param {Function} props.onRejected Message to store in redux state when any error occurred. If undefined, will not be called
 * @param {string} props.onRejectedMessage Message to store in redux state when any error occurred. If non truthy, will use message from request
 * @return {Promise} Object that contains result of dispatch
 */
export function deleteSessionKey(props = {}) {
  const errorsResponse = [];

  return async dispatch => {
    dispatch({
      type: TYPES.DELETE_SESSION_KEY_PENDING
    });

    const {
      requestProps = {},
      onFulfilled,
      onFulfilledMessage,
      onRejected,
      onRejectedMessage
    } = props;

    try {
      const response = await sdk.deleteSessionKey(requestProps);
      const {
        data = {},
        errors = [],
        status,
        statusText
      } = extractHttpResponse({
        response
      });

      if (errors.length > 0 && errors[0]) {
        errorsResponse.push(...errors);

        throw new Error(errors[0].reason);
      }

      if (getFirstChar({ value: status }) === '2') {
        return dispatch(
          fulfillAction({
            data: {
              key: requestProps.key,
              message: onFulfilledMessage || statusText,
              status
            },
            onFulfilled,
            type: TYPES.DELETE_SESSION_KEY_FULFILLED
          })
        );
      }

      throw new Error(ERROR_NOT_IN_2XX);
    } catch (error) {
      return dispatch(
        rejectAction({
          error: errorsResponse[0] || error,
          onRejected,
          onRejectedMessage,
          type: TYPES.DELETE_SESSION_KEY_REJECTED
        })
      );
    }
  };
}

/**
 * getSessionKey
 * Description: GET Session Value by Key.
 * @param {Object} props (default={})
 * @param {Object} props.requestProps (default={}) Request props for the sdk function call
 * @param {string} props.requestProps.key Session key
 * @param {Function} props.onFulfilled Callback function when is successfully fulfilled. If undefined, will not be called
 * @param {string} props.onFulfilledMessage Message to store in redux state when is successfully fulfilled. If non truthy, will use message from request
 * @param {Function} props.onRejected Message to store in redux state when any error occurred. If undefined, will not be called
 * @param {string} props.onRejectedMessage Message to store in redux state when any error occurred. If non truthy, will use message from request
 * @return {Promise} Object that contains result of dispatch
 */
export function getSessionKey(props = {}) {
  const errorsResponse = [];

  return async dispatch => {
    dispatch({
      type: TYPES.GET_SESSION_KEY_PENDING
    });

    const {
      requestProps = {},
      onFulfilled,
      onFulfilledMessage,
      onRejected,
      onRejectedMessage
    } = props;

    try {
      const response = await sdk.getSessionKey(requestProps);
      const {
        data = {},
        errors = [],
        status,
        statusText
      } = extractHttpResponse({
        response
      });

      if (errors.length > 0 && errors[0]) {
        errorsResponse.push(...errors);

        throw new Error(errors[0].reason);
      }

      if (getFirstChar({ value: status }) === '2') {
        return dispatch(
          fulfillAction({
            data: {
              key: requestProps.key,
              message: onFulfilledMessage || statusText,
              status,
              value: data
            },
            onFulfilled,
            type: TYPES.GET_SESSION_KEY_FULFILLED
          })
        );
      }

      throw new Error(ERROR_NOT_IN_2XX);
    } catch (error) {
      return dispatch(
        rejectAction({
          error: errorsResponse[0] || error,
          onRejected,
          onRejectedMessage,
          type: TYPES.GET_SESSION_KEY_REJECTED
        })
      );
    }
  };
}

/**
 * postPutSessionKeyValue
 * Description: POST / PUT Session Key Value.
 * @param {Object} props (default={})
 * @param {Object} props.requestProps (default={}) Request props for the sdk function call
 * @param {string} props.requestProps.key Session key
 * @param {Function} props.onFulfilled Callback function when is successfully fulfilled. If undefined, will not be called
 * @param {string} props.onFulfilledMessage Message to store in redux state when is successfully fulfilled. If non truthy, will use message from request
 * @param {Function} props.onRejected Message to store in redux state when any error occurred. If undefined, will not be called
 * @param {string} props.onRejectedMessage Message to store in redux state when any error occurred. If non truthy, will use message from request
 * @return {Promise} Object that contains result of dispatch
 */
export function postPutSessionKeyValue(props = {}) {
  const errorsResponse = [];

  return async dispatch => {
    dispatch({
      type: TYPES.POST_PUT_SESSION_KEY_VALUE_PENDING
    });

    const {
      requestProps = {},
      onFulfilled,
      onFulfilledMessage,
      onRejected,
      onRejectedMessage
    } = props;

    try {
      const response = await sdk.postSessionKeys(requestProps);
      const {
        data = {},
        errors = [],
        status,
        statusText
      } = extractHttpResponse({
        response
      });

      if (errors.length > 0 && errors[0]) {
        errorsResponse.push(...errors);

        throw new Error(errors[0].reason);
      }

      if (getFirstChar({ value: status }) === '2') {
        return dispatch(
          fulfillAction({
            data: {
              key: requestProps.key,
              message: onFulfilledMessage || statusText,
              status,
              value: data
            },
            onFulfilled,
            type: TYPES.POST_PUT_SESSION_KEY_VALUE_FULFILLED
          })
        );
      }

      throw new Error(ERROR_NOT_IN_2XX);
    } catch (error) {
      return dispatch(
        rejectAction({
          error: errorsResponse[0] || error,
          onRejected,
          onRejectedMessage,
          type: TYPES.POST_PUT_SESSION_KEY_VALUE_REJECTED
        })
      );
    }
  };
}
