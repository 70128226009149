/* eslint-disable */
import sdk from '_cdss/sdk';
import { chain, get } from 'lodash';

import rh from '_cdss/utils/reactHelpers';

export const types = rh.types([
  'POST_CHART_NOTES_REQUEST',
  'POST_CHART_NOTES_REQUEST2',
  'POST_CHART_NOTES_TO_OSCAR_FULFILLED',
  'POST_CHART_NOTES_TO_OSCAR_PENDING',
  'POST_CHART_NOTES_TO_OSCAR_REJECTED',
  'CLEAR_CHART_NOTE_RESULTS',
  'CHANGE_FORM_SETTINGS',
  'GET_ASSIGNED_PATIENTS'
]);

// - - - - - - - - - - - - - - - - - -

// helpers

const sanitizeExpectedResults = val => {
  return val ? val.replace(/\s/g, '') : '';
};

const setDataStatus = data => {
  let status = 'Yes! Test was successful!';
  let statusClass = 'success';

  if (data.resultCode !== data.expectedResult) {
    status = 'Test failed! Wah wah';
    statusClass = 'failed';
  }

  data.status = status;
  data.statusClass = statusClass;

  return data;
};

// - - - - - - - - - - - - - - - - - -

// begin actions

export const postChartNotesRequest = (params = {}) => (dispatch, getState) => {
  const instanceLinkUUID = get(
    getState(),
    'patientProfiles.selectedProfile.instanceLinkUUID'
  );
  const { chartNotesRequestVO } = params;

  let data = {
    id: chartNotesRequestVO._id,
    expectedResult: sanitizeExpectedResults(chartNotesRequestVO._expectedResult)
  };

  const updateParams = chain(params)
    .thru(_params => {
      const update = Object.assign({}, _params, {
        responseEntityOnly: true
      });
      if (instanceLinkUUID === null) {
        return update;
      }

      return Object.assign({}, update, {
        ...update,
        chartNotesRequestVO: {
          ...update.chartNotesRequestVO,
          questionnaireInstanceLinkUUID: instanceLinkUUID
        }
      });
    })
    .thru(_params => {
      const updateDaysPerSession = medication => {
        const { daysPerSession } = medication;

        if (daysPerSession && daysPerSession.length === 0) {
          return Object.assign({}, medication, {
            daysPerSession: 0
          });
        }

        return medication;
      };

      return Object.assign({}, _params, {
        chartNotesRequestVO: {
          ..._params.chartNotesRequestVO,
          recommendedMedications: chain(_params)
            .get('chartNotesRequestVO.recommendedMedications')
            .map(updateDaysPerSession)
            .thru(recommendedMedications => {
              console.log('----recommendedMedications', recommendedMedications);

              return recommendedMedications;
            })
            .value(),
          onSubmitedMedications: chain(_params)
            .get('chartNotesRequestVO.onSubmitedMedications')
            .map(updateDaysPerSession)
            .thru(onSubmitedMedications => {
              console.log('----onSubmitedMedications', onSubmitedMedications);

              return onSubmitedMedications;
            })
            .value()
        }
      });
    })
    .value();

  return sdk.postChartNotesRequest(updateParams).then(res => {
    if (res) {
      data.message = res.message;
      data.resultCode = res.resultCode;

      if (data.message) {
        const messageFormatted = data.message
          .replace('    ', '\n\n')
          .replace('↵', '\n');
        console.log(
          `(Chart Notes Testing) Prepended message to Chart Note message:\n${messageFormatted}`
        );
      } else {
        console.log('(Chart Notes Testing) There is no data.message');
      }
    } else {
      console.log(
        '(Chart Notes Testing) Failed to prepend message to Chart Note message'
      );
      data.resultCode = 'bad_request';
    }

    data = setDataStatus(data);

    return dispatch({ data, type: types.POST_CHART_NOTES_REQUEST });
  });
};

export const postChartNotesToOscar = (props = {}) => async dispatch => {
  dispatch({ type: types.POST_CHART_NOTES_TO_OSCAR_PENDING });

  try {
    const { chartNotesToOscarRequestVO } = props || {};
    const response = await sdk.postChartNotesToOscar({
      chartNotesToOscarRequestVO
    });

    if (response === null) {
      throw new Error('invalid response entity');
    }
    const { oscarMsg } = response || {};

    const oscarMsgFormatted = oscarMsg
      .replace('    ', '\n\n')
      .replace('↵', '\n');

    console.log(
      `(Chart Notes Testing) Chart Note sent to Oscar:\n${oscarMsgFormatted}`
    );

    return dispatch({
      data: { responseEntity: response, oscarMsgFormatted },
      type: types.POST_CHART_NOTES_TO_OSCAR_FULFILLED
    });
  } catch (error) {
    console.log(
      `(Chart Notes Testing) Chart Note failed to send to Oscar, error: ${error}`
    );

    return dispatch({
      data: { error },
      type: types.POST_CHART_NOTES_TO_OSCAR_REJECTED
    });
  }
};

export const postChartNotesRequest2 = params => dispatch => {
  const chartNotesRequestVO = params.chartNotesRequestVO;

  let data = {
    id: chartNotesRequestVO._id,
    expectedResult: sanitizeExpectedResults(chartNotesRequestVO._expectedResult)
  };

  params.responseEntityOnly = true;

  sdk.postChartNotesRequest2(params).then(res => {
    if (res) {
      data.message = res.message;
    } else {
      data.resultCode = 'bad_request';
    }

    data = setDataStatus(data);

    dispatch({ data, type: types.POST_CHART_NOTES_REQUEST2 });
  });
};

export const getAssignedPatients = params => dispatch => {
  sdk.getAssignedPatients().then(res => {
    dispatch({ data: res, type: types.GET_ASSIGNED_PATIENTS });
  });
};

export const clearChartNoteResults = params => dispatch => {
  // params currently not used
  const data = {};

  dispatch({ data, type: types.CLEAR_CHART_NOTE_RESULTS });
};

export const changeFormSettings = data => dispatch => {
  dispatch({ data, type: types.CHANGE_FORM_SETTINGS });
};
