const shouldResetCDSSProgress = (props = {}) => {
  const { lastUpdatedDateTime, todaysDateTime } = props;
  const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
  const timeDiff = Math.abs(todaysDateTime - lastUpdatedDateTime);
  const diffWeeks = timeDiff / ONE_WEEK;

  console.log('shouldResetCDSSProgress props', props);
  console.log('shouldResetCDSSProgress results', {
    diffWeeks,
    ONE_WEEK,
    timeDiff
  });

  return diffWeeks > 4;
};

export default shouldResetCDSSProgress;
