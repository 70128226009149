import { chain, map, omit } from 'lodash';
import { TYPES } from './constants';
import { ASYNC_STATUSES } from '../constants';

const initialState = {
  photographProfilePropertys: {},
  deletePhotographsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchPhotographsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  postPhotographsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  updatePhotographsProfilesPropertys: {
    asyncStatus: '',
    message: '',
    status: 0
  }
};

const reducer = (state = initialState, action) => {
  if (action.type === TYPES.DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED) {
    const { profilePhotographPropertyUUIDs, message, status } = action.payload;

    return Object.assign({}, state, {
      instanceLinks: omit(
        state.photographProfilePropertys,
        profilePhotographPropertyUUIDs
      ),
      deletePhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      deletePhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      deletePhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED) {
    const { photographProfilePropertys, message, status } = action.payload;

    return Object.assign({}, state, {
      photographProfilePropertys: {
        ...state.photographProfilePropertys,
        ...photographProfilePropertys
      },
      fetchPhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      fetchPhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchPhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED) {
    const { photographProfilePropertys, message, status } = action.payload;
    const newUUIDS = map(
      photographProfilePropertys,
      'profilePhotographPropertyUUID'
    );

    return Object.assign({}, state, {
      photographProfilePropertys: chain(state.photographProfilePropertys)
        .filter(
          ({ profilePhotographPropertyUUID }) =>
            !newUUIDS.includes(profilePhotographPropertyUUID)
        )
        .concat(photographProfilePropertys)
        .keyBy('profilePhotographPropertyUUID')
        .value(),
      postPhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      postPhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.POST_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      postPhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED) {
    const { photographProfilePropertys, message, status } = action.payload;
    const newUUIDS = map(
      photographProfilePropertys,
      'profilePhotographPropertyUUID'
    );

    return Object.assign({}, state, {
      photographProfilePropertys: chain(state.photographProfilePropertys)
        .filter(
          ({ profilePhotographPropertyUUID }) =>
            !newUUIDS.includes(profilePhotographPropertyUUID)
        )
        .concat(photographProfilePropertys)
        .keyBy('profilePhotographPropertyUUID')
        .value(),
      updatePhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING) {
    return Object.assign({}, state, {
      updatePhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      updatePhotographsProfilesPropertys: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  return state;
};

export default reducer;
