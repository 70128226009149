export const TYPES = {
  DELETE_INSTANCE_LINKS_FULFILLED: 'DELETE_INSTANCE_LINKS_FULFILLED',
  DELETE_INSTANCE_LINKS_PENDING: 'DELETE_INSTANCE_LINKS_PENDING',
  DELETE_INSTANCE_LINKS_REJECTED: 'DELETE_INSTANCE_LINKS_REJECTED',

  DELETE_INSTANCES_RESPONSES_FULFILLED: 'DELETE_INSTANCES_RESPONSES_FULFILLED',
  DELETE_INSTANCES_RESPONSES_PENDING: 'DELETE_INSTANCES_RESPONSES_PENDING',
  DELETE_INSTANCES_RESPONSES_REJECTED: 'DELETE_INSTANCES_RESPONSES_REJECTED',

  FETCH_INSTANCE_LINKS_FULFILLED: 'FETCH_INSTANCE_LINKS_FULFILLED',
  FETCH_INSTANCE_LINKS_PENDING: 'FETCH_INSTANCE_LINKS_PENDING',
  FETCH_INSTANCE_LINKS_REJECTED: 'FETCH_INSTANCE_LINKS_REJECTED',

  FETCH_INSTANCES_FULFILLED: 'FETCH_INSTANCES_FULFILLED',
  FETCH_INSTANCES_PENDING: 'FETCH_INSTANCES_PENDING',
  FETCH_INSTANCES_REJECTED: 'FETCH_INSTANCES_REJECTED',

  FETCH_INSTANCES_QUESTIONS_FULFILLED: 'FETCH_INSTANCES_QUESTIONS_FULFILLED',
  FETCH_INSTANCES_QUESTIONS_PENDING: 'FETCH_INSTANCES_QUESTIONS_PENDING',
  FETCH_INSTANCES_QUESTIONS_REJECTED: 'FETCH_INSTANCES_QUESTIONS_REJECTED',

  FETCH_INSTANCES_QUESTIONS_OPTIONS_FULFILLED:
    'FETCH_INSTANCES_QUESTIONS_OPTIONS_FULFILLED',
  FETCH_INSTANCES_QUESTIONS_OPTIONS_PENDING:
    'FETCH_INSTANCES_QUESTIONS_OPTIONS_PENDING',
  FETCH_INSTANCES_QUESTIONS_OPTIONS_REJECTED:
    'FETCH_INSTANCES_QUESTIONS_OPTIONS_REJECTED',

  FETCH_INSTANCES_RESPONSES_FULFILLED: 'FETCH_INSTANCES_RESPONSES_FULFILLED',
  FETCH_INSTANCES_RESPONSES_PENDING: 'FETCH_INSTANCES_RESPONSES_PENDING',
  FETCH_INSTANCES_RESPONSES_REJECTED: 'FETCH_INSTANCES_RESPONSES_REJECTED',

  POST_INSTANCES_RESPONSES_FULFILLED: 'POST_INSTANCES_RESPONSES_FULFILLED',
  POST_INSTANCES_RESPONSES_PENDING: 'POST_INSTANCES_RESPONSES_PENDING',
  POST_INSTANCES_RESPONSES_REJECTED: 'POST_INSTANCES_RESPONSES_REJECTED',

  INITIALIZE_CACHE_FULFILLED: 'INITIALIZE_CACHE_FULFILLED',
  INITIALIZE_CACHE_PENDING: 'INITIALIZE_CACHE',
  INITIALIZE_CACHE_REJECTED: 'INITIALIZE_CACHE_REJECTED',

  POST_INSTANCE_LINKS_FULFILLED: 'POST_INSTANCE_LINKS_FULFILLED',
  POST_INSTANCE_LINKS_PENDING: 'POST_INSTANCE_LINKS_PENDING',
  POST_INSTANCE_LINKS_REJECTED: 'POST_INSTANCE_LINKS_REJECTED',

  UPDATE_INSTANCES_RESPONSES: 'UPDATE_INSTANCES_RESPONSES'
};

export default {
  TYPES
};
