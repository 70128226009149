import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { reducers } from '.';

export const history = createBrowserHistory();

function configureStore(preloadedState) {
  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history)
    }),
    preloadedState,
    composeWithDevTools(
      compose(applyMiddleware(thunk, routerMiddleware(history)))
    )
  );

  return store;
}

export default configureStore;
