import { chain, keyBy } from 'lodash';
import sdk from '@eams-dev/js-qoc-sdk';
import { TYPES } from './constants';
import extractHttpResponse from '../utils/extractHttpResponse';
import fulfillAction from '../utils/fulfillAction';
import rejectAction from '../utils/rejectAction';

/**
 * Delete Photographs Profiles Propertys
 */
export const deletePhotographsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { profilePhotographPropertyUUIDs = [] } = requestProps;

  try {
    const response = await sdk.deletePhotographsProfilesPropertys(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          profilePhotographPropertyUUIDs,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};

/**
 * Fetch Photographs Profiles Propertys
 */
export const fetchPhotographsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getPhotographsProfilesPropertys(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const photographProfilePropertys = keyBy(
        data.responseEntity || data,
        'profilePhotographPropertyUUID'
      );

      return dispatch(
        fulfillAction({
          photographProfilePropertys,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};

/**
 * Post Photographs Profiles Propertys
 */
export const postPhotographsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.POST_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { photographProfilePropertys } = requestProps;

  try {
    const response = await sdk.postPhotographsProfilesPropertys(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 201) {
      const photographProfilePropertysWithUUIDs = chain(
        photographProfilePropertys
      )
        .map((photographProfileProperty, index) =>
          Object.assign({}, photographProfileProperty, {
            profilePhotographPropertyUUID: data[index].id
          })
        )
        .keyBy('profilePhotographPropertyUUID')
        .value();

      return dispatch(
        fulfillAction({
          photographProfilePropertys: photographProfilePropertysWithUUIDs,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.POST_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.POST_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};

/**
 * Update Photographs Profiles Propertys
 */
export const updatePhotographsProfilesPropertys = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { photographProfilePropertys } = requestProps;

  try {
    const response = await sdk.postPhotographsProfilesPropertys(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 201) {
      return dispatch(
        fulfillAction({
          photographProfilePropertys,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED
      })
    );
  }
};
