import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

import masterMedications from '_cdss/library/medications';
import { MEDICATION_DESTINATION_STORES } from '_cdss/redux/constants';

import transformMedications from './transformMedications';

const modelMedications = (props = {}) => {
  const {
    surveyOptions = [],
    surveyQuestions = [],
    surveyResponses = []
  } = props;

  const medicationResponses = reduce(
    MEDICATION_DESTINATION_STORES,
    (acc, curr, key) =>
      Object.assign({}, acc, {
        [key]: filter(surveyResponses, {
          responseSetId: curr
        })
      }),
    {}
  );

  const medications = reduce(
    medicationResponses,
    (acc, curr, key) => {
      const desinationStoreMedications = transformMedications({
        masterMedications,
        surveyOptions,
        surveyResponses: curr,
        surveyQuestions
      });

      return Object.assign({}, acc, {
        [key]: desinationStoreMedications
      });
    },
    {}
  );

  return medications;
};

export default modelMedications;
