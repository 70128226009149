/**
 * Checks the size of medications props to deterime
 * if medications is empty
 */
const checkArraySizeGreaterThan = (props = {}) => {
  const { array = [], size = 0 } = props;

  return array.length > size;
};

export default checkArraySizeGreaterThan;
