import { chain, omit } from 'lodash';
import { TYPES } from './constants';
import { ASYNC_STATUSES } from '../constants';

const initialState = {
  initialized: false,
  instances: {},
  instanceLinks: {},
  options: {},
  questions: {},
  responses: {},
  deleteInstanceLinks: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  deleteInstancesResponses: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchInstanceLinks: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchInstances: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchInstancesQuestions: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchInstancesQuestionsOptions: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchInstancesResponses: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  initializeCache: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  postInstanceLinks: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  postInstancesResponses: {
    asyncStatus: '',
    message: '',
    status: 0
  }
};

const reducer = (state = initialState, action) => {
  if (action.type === TYPES.DELETE_INSTANCE_LINKS_FULFILLED) {
    const { instanceLinkUUIDs, message, status } = action.payload;

    return Object.assign({}, state, {
      instanceLinks: omit(state.instanceLinks, instanceLinkUUIDs),
      deleteInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.DELETE_INSTANCE_LINKS_PENDING) {
    return Object.assign({}, state, {
      deleteInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.DELETE_INSTANCE_LINKS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      deleteInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.DELETE_INSTANCES_RESPONSES_FULFILLED) {
    const { surveyResponseUUIDs, message, status } = action.payload;

    return Object.assign({}, state, {
      responses: omit(state.responses, surveyResponseUUIDs),
      deleteInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.DELETE_INSTANCES_RESPONSES_PENDING) {
    return Object.assign({}, state, {
      deleteInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.DELETE_INSTANCES_RESPONSES_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      deleteInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCE_LINKS_FULFILLED) {
    const { instanceLinks, message, status } = action.payload;

    return Object.assign({}, state, {
      instanceLinks: {
        ...state.instanceLinks,
        ...instanceLinks
      },
      fetchInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCE_LINKS_PENDING) {
    return Object.assign({}, state, {
      fetchInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCE_LINKS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_FULFILLED) {
    const { instances, message, status } = action.payload;

    return Object.assign({}, state, {
      instances: {
        ...state.instances,
        ...instances
      },
      fetchInstances: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_PENDING) {
    return Object.assign({}, state, {
      fetchInstances: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchInstances: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_QUESTIONS_FULFILLED) {
    const { questions, message, status } = action.payload;

    return Object.assign({}, state, {
      questions: {
        ...state.questions,
        ...questions
      },
      fetchInstancesQuestions: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_QUESTIONS_PENDING) {
    return Object.assign({}, state, {
      fetchInstancesQuestions: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_QUESTIONS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchInstancesQuestions: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_QUESTIONS_OPTIONS_FULFILLED) {
    const { options, message, status } = action.payload;

    return Object.assign({}, state, {
      options: {
        ...state.options,
        ...options
      },
      fetchInstancesQuestionsOptions: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_QUESTIONS_OPTIONS_PENDING) {
    return Object.assign({}, state, {
      fetchInstancesQuestionsOptions: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_QUESTIONS_OPTIONS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchInstancesQuestionsOptions: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_RESPONSES_FULFILLED) {
    const { responses, message, status } = action.payload;

    return Object.assign({}, state, {
      responses: {
        ...state.responses,
        ...responses
      },
      fetchInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_RESPONSES_PENDING) {
    return Object.assign({}, state, {
      fetchInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_INSTANCES_RESPONSES_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.INITIALIZE_CACHE_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      initialized: true,
      initializeCache: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.INITIALIZE_CACHE_PENDING) {
    return Object.assign({}, state, {
      initializeCache: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.INITIALIZE_CACHE_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      initialized: false,
      initializeCache: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_INSTANCE_LINKS_FULFILLED) {
    const { instanceLinks, message, status } = action.payload;

    return Object.assign({}, state, {
      instanceLinks: {
        ...state.instanceLinks,
        ...instanceLinks
      },
      postInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_INSTANCE_LINKS_PENDING) {
    return Object.assign({}, state, {
      postInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.POST_INSTANCE_LINKS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      postInstanceLinks: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_INSTANCES_RESPONSES_FULFILLED) {
    const { responses, message, status } = action.payload;

    const currentResponsesUUIDs = chain(state.responses)
      .filter(
        responseCurrent =>
          chain(responses)
            .filter(responseNew => {
              const conditionsMatched =
                responseCurrent.surveyLinkUUID === responseNew.surveyLinkUUID &&
                responseCurrent.responseSetDateTime ===
                  responseNew.responseSetDateTime &&
                responseCurrent.responseSetId === responseNew.responseSetId &&
                responseCurrent.instanceLinkUUID ===
                  responseNew.instanceLinkUUID;

              if (
                responseCurrent.responseTypeClass === 'Radio' ||
                !responseNew.optionUUID
              ) {
                return conditionsMatched;
              }

              return (
                responseCurrent.optionUUID === responseNew.optionUUID &&
                conditionsMatched
              );
            })
            .size()
            .value() > 0
      )
      .map('surveyResponseUUID')
      .value();

    const currentResponsesWithRemovedOld = omit(
      state.responses,
      currentResponsesUUIDs
    );

    const responsesNew = chain(responses)
      .map(response =>
        Object.assign({}, response, {
          questionId: response.questionId
        })
      )
      .keyBy('surveyResponseUUID')
      .value();

    return Object.assign({}, state, {
      responses: {
        ...currentResponsesWithRemovedOld,
        ...responsesNew
      },
      postInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.POST_INSTANCES_RESPONSES_PENDING) {
    return Object.assign({}, state, {
      postInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.POST_INSTANCES_RESPONSES_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      postInstancesResponses: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_INSTANCES_RESPONSES) {
    const { newSurveyResponses, oldSurveyResponseUUIDs } = action.payload;
    console.log('-- test action.payload', action.payload);

    // take the first one, they should all be the same
    const { responseCollectionUUID } = newSurveyResponses[0] || {};

    const responses = chain(state.responses)
      .reject(({ surveyResponseUUID }) =>
        oldSurveyResponseUUIDs.includes(surveyResponseUUID)
      )
      .groupBy('responseCollectionUUID')
      .thru(groupedResponses =>
        Object.assign({}, groupedResponses, {
          [responseCollectionUUID]: newSurveyResponses
        })
      )
      .map()
      .flattenDeep()
      .keyBy('surveyResponseUUID')
      .value();

    return Object.assign({}, state, {
      responses
    });
  }

  return state;
};

export default reducer;
