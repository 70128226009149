const getFirstChar = (props = {}) => {
  const { value } = props;

  if (!value) {
    // if value is falsy, return 5 to represent 5xx code
    return 5;
  }

  return value.toString().charAt(0);
};

export default getFirstChar;
