/* eslint-disable */

import axios from 'axios';
import type from 'type-detect';

import { each, map, reduce } from 'lodash';

// de-compose val into letter and number if it follows a specific format
export const decomposeParts = val => {
  if ('string' === type(val)) {
    val = val.trim();
    if (val) val = val.toLowerCase();

    const data = { val, number: null };

    const matches = val.match(/^([a-z])([0-9]+)$/);

    if (matches) {
      data.letter = matches[1];
      data.number = parseInt(matches[2]);
    }

    return data;
  }

  return null;
};

export const sanitizeChartNotesRequest = data => {
  if ('Object' === type(data)) {
    each(data, (val, key) => {
      if ('chemicalType' === key) {
        if ('Array' === type(val)) {
          const valStr = reduce(
            val,
            (acc, sub) => {
              if (acc) acc += ',';
              if (sub) {
                if ('string' === type(sub)) {
                  sub = sub.toLowerCase();
                }
                acc += sub;
              }
              return acc;
            },
            ''
          );

          data[key] = valStr;
        }
      } else if ('scenario' === key) {
        const parts = decomposeParts(val);

        if (parts) {
          data[key] = parts.val;

          if (parts.letter) data.scenarioLetter = parts.letter;
          if (null !== parts.number) data.scenarioNumber = parts.number;
        }
      } else if ('tag' === key) {
        const parts = decomposeParts(val);

        if (parts) {
          data[key] = parts.val;

          if (parts.letter) data.tagLetter = parts.letter;
          if (null !== parts.number) data.tagNumber = parts.number;
        }
      }
    });

    // - - - - - - - - - - - - - -

    each(data, (val, key) => {
      data[key] = sanitizeChartNotesRequest(val);
    });
  } else if ('Array' === type(data)) {
    data = map(data, sanitizeChartNotesRequest);
  }

  return data;
};

export async function httpRequest(props) {
  try {
    console.log('httpRequest axios props', props);
    const res = await axios(props);
    if (res && res.data && res.data.responseEntity) {
      console.log('axios res.data.responseEntity', res.data.responseEntity);
      return res.data.responseEntity;
    }
    console.log('axios res', res);
    return null;
  } catch (err) {
    console.log('axios err', err);
    return err;
  }
}
