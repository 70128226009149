export const TYPES = {
  FETCH_PROFILES_FULFILLED: 'FETCH_PROFILES_FULFILLED',
  FETCH_PROFILES_PENDING: 'FETCH_PROFILES_PENDING',
  FETCH_PROFILES_REJECTED: 'FETCH_PROFILES_REJECTED',

  FETCH_DOCTOR_PROFILE_FULFILLED: 'FETCH_DOCTOR_PROFILE_FULFILLED',
  FETCH_DOCTOR_PROFILE_PENDING: 'FETCH_DOCTOR_PROFILE_PENDING',
  FETCH_DOCTOR_PROFILE_REJECTED: 'FETCH_DOCTOR_PROFILE_REJECTED',

  FETCH_PROFILE_VO_FULFILLED: 'FETCH_PROFILE_VO_FULFILLED',
  FETCH_PROFILE_VO_PENDING: 'FETCH_PROFILE_VO_PENDING',
  FETCH_PROFILE_VO_REJECTED: 'FETCH_PROFILE_VO_REJECTED',

  REQUEST_RESET_PASSWORD_FULFILLED: 'REQUEST_RESET_PASSWORD_FULFILLED',
  REQUEST_RESET_PASSWORD_PENDING: 'REQUEST_RESET_PASSWORD_PENDING',
  REQUEST_RESET_PASSWORD_REJECTED: 'REQUEST_RESET_PASSWORD_REJECTED',

  RESET_PASSWORD_FULFILLED: 'RESET_PASSWORD_FULFILLED',
  RESET_PASSWORD_PENDING: 'RESET_PASSWORD_PENDING',
  RESET_PASSWORD_REJECTED: 'RESET_PASSWORD_REJECTED',

  UPDATE_PROFILE_FULFILLED: 'UPDATE_PROFILE_FULFILLED',
  UPDATE_PROFILE_PENDING: 'UPDATE_PROFILE_PENDING',
  UPDATE_PROFILE_REJECTED: 'UPDATE_PROFILE_REJECTED',

  UPDATE_USER_PASSWORD_FULFILLED: 'UPDATE_USER_PASSWORD_FULFILLED',
  UPDATE_USER_PASSWORD_PENDING: 'UPDATE_USER_PASSWORD_PENDING',
  UPDATE_USER_PASSWORD_REJECTED: 'UPDATE_USER_PASSWORD_REJECTED'
};

export default {
  TYPES
};
