import {
  URL_SECURE_DOCTOR_CDSS_PATIENT_ASTHMA_CONTROL,
  URL_SECURE_DOCTOR_CDSS_PATIENT_MEDICATION_CHANGE,
  URL_SECURE_DOCTOR_CDSS_PATIENT_AAP,
  URL_SECURE_DOCTOR_CDSS_PATIENT_AAP_REPORT,
  URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE
} from '_cdss/constants/urls';

const SCREENS = {
  ASTHMA_CONTROL: URL_SECURE_DOCTOR_CDSS_PATIENT_ASTHMA_CONTROL,
  MEDICATION_CHANGE: URL_SECURE_DOCTOR_CDSS_PATIENT_MEDICATION_CHANGE,
  ASTHMA_ACTION_PLAN: URL_SECURE_DOCTOR_CDSS_PATIENT_AAP,
  ASTHMA_ACTION_PLAN_REPORT: URL_SECURE_DOCTOR_CDSS_PATIENT_AAP_REPORT,
  COMPLETE: URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE
};

const getScreen = props => {
  const { patientProfile } = props || {};
  const { aapCompleted, medsStatuses = {}, screen } = patientProfile || {};

  // aap completed, no need to re-enter
  if (aapCompleted) {
    return SCREENS.COMPLETE;
  }

  // screen 1
  if (medsStatuses.doctorRecommendationsStatus !== 'Submitted') {
    return SCREENS.ASTHMA_CONTROL;
  }

  // screen 2
  if (medsStatuses.rulesRecommendationsStatus !== 'Submitted') {
    return SCREENS.MEDICATION_CHANGE;
  }

  // screen 4
  if (screen === '4' && !aapCompleted) {
    return SCREENS.ASTHMA_ACTION_PLAN_REPORT;
  }

  // screen 3 - check if there is a value first since this could be skipped
  // there will be a value if it's not skipped
  // defaulted to 'Draft' if skipped
  console.log(
    'medsStatuses.stepUpRecommendationsStatus: ',
    medsStatuses.stepUpRecommendationsStatus
  );

  if (
    medsStatuses.stepUpRecommendationsStatus &&
    medsStatuses.stepUpRecommendationsStatus !== 'Submitted'
  ) {
    return SCREENS.ASTHMA_ACTION_PLAN;
  }

  return SCREENS.ASTHMA_CONTROL;
};

export default getScreen;
