import axios from 'axios';
import sdk from '@eams-dev/js-qoc-sdk';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  URL_SESSION_EXPIRED,
  URL_LOGIN,
  URL_SECURE_USER_ASTHMA_ACTION_PLAN,
  URL_SECURE_USER_EDUCATION,
  URL_SECURE_USER_GLOSSARY,
  URL_SECURE_USER_HELPFUL_LINKS,
  URL_SECURE_USER_DECISION_AID
  // URL_SECURE_USER_DASHBOARD
} from '_constants/urls';
import { actions as allActions } from '_redux';
import QocTracker, { QocEventsTrackingEngine } from '@eams-dev/js-qoc-tracker';
import eAMSGreybg from './eAMs_greybg.gif';
import './styles.scss';

const componentBaseClass = 'qoc-system';

/** Helper Functions */
function renderLoadingContent(targetProps = {}) {
  const { status, systemLoading } = targetProps;

  if (systemLoading || status === 'loading') {
    return (
      <div className={`${componentBaseClass}__loading`}>
        <img src={eAMSGreybg} alt="loading gif" />
      </div>
    );
  }

  return null;
}

function renderMainContent(targetProps = {}) {
  const { children, systemLoading } = targetProps;

  if (systemLoading) {
    return null;
  }

  return <main className={`${componentBaseClass}__main`}>{children}</main>;
}

/** Main Component */
function System(props = {}) {
  const INACTIVE_TIME = 1000 * 60 * 60;
  let timer;

  const {
    children,
    fetchLoginStatus,
    fetchProfileVO,
    initializeSystem,
    status,
    systemLoading,
    onRedirectUrl,
    onForceRedirectUrl,
    location
  } = props;

  const setInactivity = () => {
    timer = setTimeout(() => {
      if (
        window.location.pathname !== URL_SESSION_EXPIRED &&
        window.location.pathname !== URL_LOGIN &&
        window.location.pathname !== URL_SECURE_USER_ASTHMA_ACTION_PLAN &&
        window.location.pathname !== URL_SECURE_USER_EDUCATION &&
        window.location.pathname !== URL_SECURE_USER_GLOSSARY &&
        window.location.pathname !== URL_SECURE_USER_HELPFUL_LINKS &&
        window.location.pathname !== URL_SECURE_USER_DECISION_AID
      ) {
        onRedirectUrl(URL_SESSION_EXPIRED);
      }
    }, INACTIVE_TIME);
  };
  const resetTimer = () => {
    clearTimeout(timer);

    setInactivity();
  };

  // initialize system
  useEffect(() => {
    const initializeSystemCallback = () => async () => {
      const fulfillProps = await fetchLoginStatus();
      console.log('fulfillProps', fulfillProps);
      if (fulfillProps.status === 200) {
        await Promise.all([fetchProfileVO()]);
        const check = await QocTracker.initialize({
          trackingEngines: [
            {
              config: {},
              engine: QocEventsTrackingEngine,
              name: 'qoc-events-tracking-engine'
            }
          ]
        }); // .then(() => console.log('QocTracker initialized'));
        console.log('QocTracker system: ', check);
      }

      return fulfillProps;
    };

    initializeSystem({ initializeSystemCallback });
  }, []);

  // check user login status
  useEffect(() => {
    const fetchUserLoginStatus = async () => {
      const loginStatus = await fetchLoginStatus();
      if (
        loginStatus.status === 401 &&
        (location.pathname.indexOf('secure/doctor/cdss/patients') > -1 ||
          location.pathname.indexOf('/patients/cdss/') > -1)
      ) {
        onForceRedirectUrl('/main/session/expired');
        localStorage.removeItem('pHash');
        localStorage.removeItem('activatedUser');
        localStorage.removeItem('patientHash');
        localStorage.removeItem('emailAddress');
      } else if (
        loginStatus.status === 401 &&
        location.pathname !== '/main/login' &&
        location.pathname !== '/main/doctor/login' &&
        location.pathname.indexOf('main/login/activation') === -1 &&
        location.pathname !== '/main/about-us' &&
        location.pathname !== '/main/user/register/not-eligible' &&
        location.pathname.indexOf('provider/register') === -1 &&
        location.pathname.indexOf('main/session/expired') === -1
      ) {
        onForceRedirectUrl('/main/login');
        localStorage.removeItem('pHash');
        localStorage.removeItem('activatedUser');
        localStorage.removeItem('patientHash');
        localStorage.removeItem('emailAddress');
      }
    };
    const fetchActivatedUser = localStorage.getItem('activatedUser');
    if (fetchActivatedUser !== 'undefined' && fetchActivatedUser !== 'false') {
      fetchUserLoginStatus();
    }
  }, [location.pathname]);

  // initalize sdk
  useEffect(() => {
    const axiosInstance = axios.create({});

    axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.message.includes('403')) {
          window.location.href = window.location.href.includes('/secure/doctor')
            ? `${URL_SESSION_EXPIRED}?role=ROLE_DOCTOR`
            : URL_SESSION_EXPIRED;
        }

        return Promise.reject(error);
      }
    );

    sdk.initializeLibrary({ httpLibrary: axiosInstance });
  }, []);

  useEffect(() => {
    // DOM Events
    // mousedown may cause issues for mobile
    const events = ['mousedown', 'touchstart'];

    const fetchActivatedUser = localStorage.getItem('activatedUser');
    if (fetchActivatedUser) {
      events.forEach(function(name) {
        window.addEventListener(name, resetTimer, true);
      });
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      events.forEach(function(name) {
        window.removeEventListener(name, resetTimer, true);
      });
    };
  }, []);

  return (
    <div className={componentBaseClass}>
      {renderLoadingContent({ status, systemLoading })}
      {renderMainContent({ children, systemLoading })}
    </div>
  );
}

System.propTypes = {
  children: PropTypes.node,
  fetchLoginStatus: PropTypes.func.isRequired,
  fetchProfileVO: PropTypes.func.isRequired,
  initializeSystem: PropTypes.func.isRequired,
  onForceRedirectUrl: PropTypes.func.isRequired,
  onRedirectUrl: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  systemLoading: PropTypes.bool,
  location: PropTypes.object.isRequired
};

System.defaultProps = {
  children: null,
  systemLoading: false
};

const mapStateToProps = state => {
  const systemInitialized = get(state, 'system.initialized');
  const systemPending =
    get(state, 'system.initializeSystem.asyncStatus') === 'PENDING';
  const systemLoading = !systemInitialized && systemPending;

  return {
    status: state.system.status,
    systemLoading
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLoginStatus: (props = {}) =>
    dispatch(allActions.authentication.fetchLoginStatus(props)),
  fetchProfileVO: (props = {}) =>
    dispatch(allActions.profile.fetchProfileVO(props)),
  initializeSystem: (props = {}) =>
    dispatch(allActions.system.initializeSystem(props)),
  onForceRedirectUrl: (props = {}) =>
    dispatch(allActions.system.onRedirectUrl(props)),
  onRedirectUrl: (props = {}) =>
    dispatch(allActions.system.onRedirectUrl(props))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(System)
);
