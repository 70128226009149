export const MEDICATION_DESTINATION_STORES = {
  original: 'original',
  doctorRecommendations: 'doctorRecommendations',
  rulesRecommendations: 'rulesRecommendations',
  rulesRecommendationsMedicationOptions:
    'rulesRecommendationsMedicationOptions',
  stepUpRecommendations: 'stepUpRecommendations'
};

export const SCREENS = {
  [MEDICATION_DESTINATION_STORES.doctorRecommendations]: '1',
  [MEDICATION_DESTINATION_STORES.rulesRecommendations]: '2',
  [MEDICATION_DESTINATION_STORES.stepUpRecommendations]: '3',
  aap: '4'
};

export const MEDICATION_DESTINATION_STORES_TO_DELETE = {
  [MEDICATION_DESTINATION_STORES.original]: [
    MEDICATION_DESTINATION_STORES.doctorRecommendations,
    MEDICATION_DESTINATION_STORES.rulesRecommendations,
    MEDICATION_DESTINATION_STORES.rulesRecommendationsMedicationOptions,
    MEDICATION_DESTINATION_STORES.stepUpRecommendations
  ],
  [MEDICATION_DESTINATION_STORES.doctorRecommendations]: [
    MEDICATION_DESTINATION_STORES.rulesRecommendations,
    MEDICATION_DESTINATION_STORES.rulesRecommendationsMedicationOptions,
    MEDICATION_DESTINATION_STORES.stepUpRecommendations
  ],
  [MEDICATION_DESTINATION_STORES.rulesRecommendations]: [
    MEDICATION_DESTINATION_STORES.stepUpRecommendations
  ],
  [MEDICATION_DESTINATION_STORES.stepUpRecommendations]: []
};

export const DEFAULT_DESTINATION_STORES = {
  [MEDICATION_DESTINATION_STORES.original]:
    MEDICATION_DESTINATION_STORES.original,
  [MEDICATION_DESTINATION_STORES.doctorRecommendations]:
    MEDICATION_DESTINATION_STORES.original,
  [MEDICATION_DESTINATION_STORES.rulesRecommendations]:
    MEDICATION_DESTINATION_STORES.doctorRecommendations,
  [MEDICATION_DESTINATION_STORES.stepUpRecommendations]:
    MEDICATION_DESTINATION_STORES.rulesRecommendations
};

export const MEDICATION_STATUS_OPTION_SET_UUID =
  'ECCE9636-AAD1-4906-AEFE-AE8A00136EA1';

export const MEDICATION_STATUS = {
  DRAFT: 1,
  SUBMITTED: 2
};
export const EMPTY_MEDICATION_DROPDOWNS_ANSWERED_QUESTION_ID =
  'empty_medication_dropdowns_answered';

export const EMPTY_MEDICATION_DROPDOWNS_ANSWERED_SURVEY_LINK_UUID =
  '020BBD28-5AE4-4D72-9B89-7239837F08B8';

export const YELLOW_ZONE_QUESTION_ID = 'yellow_zone_filter';

export const YELLOW_ZONE_SURVEY_LINK_UUID =
  '7817B5B5-FE90-4C97-B74E-6147B5B004A8';

export const YELLOW_ZONE_OPTION_SET_UUID =
  '6D1508F1-0584-4F65-997E-EF40193D7A5F';

export const YELLOW_ZONE_OPTION_VALUES = {
  INHALED_THERAPY: 1,
  PREDNISONE: 2,
  RELIEVER: 3
};

export const AAP_STATUS = {
  DEFERED: 'defered',
  COMPLETED: 'completed',
  CONTINUED: 'continued',
  APPROVED: 'approved',
  PRINTED: 'printed',
  NOT_SUITABLE: 'not_suitable'
};

export default {
  AAP_STATUS,
  MEDICATION_DESTINATION_STORES,
  MEDICATION_STATUS,
  MEDICATION_STATUS_OPTION_SET_UUID
};
