const isUnderGoodControl = (props = {}) => {
  const { controlResponses = {} } = props;

  const symptoms =
    controlResponses.symptoms !== 'about 3' &&
    controlResponses.symptoms !== '4 or more';

  const rescue =
    controlResponses.rescue !== 'about 3 times per week' &&
    controlResponses.rescue !== '4 or more times per week';

  const flare =
    typeof controlResponses.flare === 'undefined' ||
    controlResponses.flare === 'no';

  return (
    controlResponses.woken === 'no' &&
    symptoms &&
    rescue &&
    controlResponses.schoolwork === 'no' &&
    controlResponses.exercise === 'no' &&
    flare
  );
};

export default isUnderGoodControl;
