export const TYPES = {
  FETCH_LOGIN_STATUS_FULFILLED: 'FETCH_LOGIN_STATUS_FULFILLED',
  FETCH_LOGIN_STATUS_PENDING: 'FETCH_LOGIN_STATUS_PENDING',
  FETCH_LOGIN_STATUS_REJECTED: 'FETCH_LOGIN_STATUS_REJECTED',

  FETCH_LOGIN_STATUSES_FULFILLED: 'FETCH_LOGIN_STATUSES_FULFILLED',
  FETCH_LOGIN_STATUSES_PENDING: 'FETCH_LOGIN_STATUSES_PENDING',
  FETCH_LOGIN_STATUSES_REJECTED: 'FETCH_LOGIN_STATUSES_REJECTED',

  LOGIN_FULFILLED: 'LOGIN_FULFILLED',
  LOGIN_PENDING: 'LOGIN_PENDING',
  LOGIN_REJECTED: 'LOGIN_REJECTED',

  LOGOUT_FULFILLED: 'LOGOUT_FULFILLED',
  LOGOUT_PENDING: 'LOGOUT_PENDING',
  LOGOUT_REJECTED: 'LOGOUT_REJECTED'
};

export default {
  TYPES
};
