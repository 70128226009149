export const TYPES = {
  DELETE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED:
    'DELETE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED',
  DELETE_DOCUMENTS_PROFILES_PROPERTYS_PENDING:
    'DELETE_DOCUMENTS_PROFILES_PROPERTYS_PENDING',
  DELETE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED:
    'DELETE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED',

  FETCH_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED:
    'FETCH_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED',
  FETCH_DOCUMENTS_PROFILES_PROPERTYS_PENDING:
    'FETCH_DOCUMENTS_PROFILES_PROPERTYS_PENDING',
  FETCH_DOCUMENTS_PROFILES_PROPERTYS_REJECTED:
    'FETCH_DOCUMENTS_PROFILES_PROPERTYS_REJECTED',

  POST_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED:
    'POST_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED',
  POST_DOCUMENTS_PROFILES_PROPERTYS_PENDING:
    'POST_DOCUMENTS_PROFILES_PROPERTYS_PENDING',
  POST_DOCUMENTS_PROFILES_PROPERTYS_REJECTED:
    'POST_DOCUMENTS_PROFILES_PROPERTYS_REJECTED',

  UPDATE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED:
    'UPDATE_DOCUMENTS_PROFILES_PROPERTYS_FULFILLED',
  UPDATE_DOCUMENTS_PROFILES_PROPERTYS_PENDING:
    'UPDATE_DOCUMENTS_PROFILES_PROPERTYS_PENDING',
  UPDATE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED:
    'UPDATE_DOCUMENTS_PROFILES_PROPERTYS_REJECTED'
};

export default {
  TYPES
};
