import filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import {
  MEDICATION_DESTINATION_STORES,
  MEDICATION_STATUS
} from '_cdss/redux/constants';

const modelMedicationsStatus = (props = {}) => {
  const { surveyResponses = [] } = props;

  const medicationResponses = reduce(
    MEDICATION_DESTINATION_STORES,
    (acc, curr, key) =>
      Object.assign({}, acc, {
        [key]: filter(surveyResponses, {
          responseSetId: curr
        })
      }),
    {}
  );

  const medications = reduce(
    medicationResponses,
    (acc, curr, key) => {
      const medicationStatusResponse =
        find(curr, {
          questionId: 'meds_status',
          responseSetId: key
        }) || {};
      const medicationsStatus = medicationStatusResponse.responseNumeric;

      return Object.assign({}, acc, {
        [key]: medicationsStatus || MEDICATION_STATUS.DRAFT
      });
    },
    {}
  );

  return medications;
};

export default modelMedicationsStatus;
