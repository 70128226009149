const isMedicationSymbicort = (props = {}) => {
  const { medication = {} } = props;
  const { deviceAndName, name } = medication;

  const testName = deviceAndName || name || '';

  return testName.toLowerCase().includes('symbicort');
};

export default isMedicationSymbicort;
