export const TYPES = {
  REDIRECT_REGISTER_URL: 'REDIRECT_REGISTER_URL',

  REGISTER_PROVIDER_FULFILLED: 'REGISTER_PROVIDER_FULFILLED',
  REGISTER_PROVIDER_PENDING: 'REGISTER_PROVIDER_PENDING',
  REGISTER_PROVIDER_REJECTED: 'REGISTER_PROVIDER_REJECTED',

  SET_CLINIC_ID: 'SET_CLINIC_ID',
  SET_PHYSICIAN_NUMBER: 'SET_PHYSICIAN_NUMBER',
  SET_PHYSICIAN_NAME: 'SET_PHYSICIAN_NAME',
  SET_PATIENT_HASH: 'SET_PATIENT_HASH',
  SET_FORM_VALUES: 'SET_FORM_VALUES'
};

export default {
  TYPES
};
