import { types } from './constants';

const initialState = {
  screen: '',
  selectedProfile: null
};

const reducer = (state = initialState, action) => {
  if (action.type === types.SELECT_PATIENT) {
    const { selectedProfile } = action.payload;

    return Object.assign({}, state, { selectedProfile });
  }

  if (action.type === types.SELECT_SCREEN) {
    const { screen } = action.payload;

    return Object.assign({}, state, { screen });
  }

  return state;
};

export default reducer;
