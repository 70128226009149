const baseSecureUserPath = '/secure/user';

// secure user
export const URL_DECISION_AID = `${baseSecureUserPath}/decision-aid`;
export const URL_DECISION_AID_CONTENT = `${baseSecureUserPath}/decision-aid/content`;
export const URL_DECISION_AID_HISTORY = `${baseSecureUserPath}/decision-aid/history`;

export default {
  URL_DECISION_AID,
  URL_DECISION_AID_CONTENT,
  URL_DECISION_AID_HISTORY
};
