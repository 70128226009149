import authentication from './Authentication';
import cache from './Cache';
import documents from './Documents';
import photographs from './Photographs';
import profile from './Profile';
import session from './Session';
import system from './System';
import qUtils from './utils';

export const actions = {
  authentication: authentication.actions,
  documents: documents.actions,
  photographs: photographs.actions,
  cache: cache.actions,
  profile: profile.actions,
  session: session.actions,
  system: system.actions
};

export const connect = {
  authentication: authentication.connect,
  documents: documents.connect,
  photographs: photographs.connect,
  cache: cache.connect,
  profile: profile.connect,
  session: session.connect,
  system: system.connect
};

export const reducers = {
  authentication: authentication.reducer,
  cache: cache.reducer,
  documents: documents.reducer,
  photographs: photographs.reducer,
  profile: profile.reducer,
  session: session.reducer,
  system: system.reducer
};

export const utils = qUtils;

const redux = {
  actions,
  connect,
  reducers,
  utils
};

export default redux;
