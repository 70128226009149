import reject from 'lodash/reject';
// import {
// ASYNC_STATUSES,
// DEFAULT_ASYNC_FUNCTION_STATE
// } from '_qoc-redux/constants';
import { types } from './constants';

// const MEDICATION_STATES = {
// DRAFT: 'Draft',
// SUBMITTED: 'Submitted'
// };

function findSameMedication(props) {
  const { medications, medication } = props || {};

  return medications.find(
    ({ device, functionType, name, responseCollectionUUID }) =>
      medication.functionType === functionType &&
      (medication.responseCollectionUUID === responseCollectionUUID ||
        (medication.device === device && medication.name === name))
  );
}

export const initialState = {
  doctorRecommendations: [],
  doctorRecommendationsStatus: 1,
  original: [],
  originalStatus: 1,
  rulesRecommendations: [],
  rulesRecommendationsMedicationOptions: [],
  rulesRecommendationsStatus: 1,
  stepUpRecommendations: [],
  stepUpRecommendationsStatus: 1,

  currentDestinationStore: '',
  currentMedications: [],

  yellowZoneOption: null,
  emptyMedicationDropdownsAnswered: null,

  initializedMedications: false
};

const reducer = (state = initialState, action) => {
  if (action.type === types.SET_CURRENT_MEDICATIONS) {
    const { destinationStore } = action.data || {};

    return Object.assign({}, state, {
      currentDestinationStore: destinationStore,
      currentMedications: state[destinationStore]
    });
  }

  if (action.type === types.ADD_MEDICATION) {
    const { destinationStore, medication } = action.data || {};

    const key = destinationStore;

    const sameMedicationInState = findSameMedication({
      medications: state[key],
      medication
    });

    if (sameMedicationInState) {
      return state;
    }

    return Object.assign({}, state, {
      [key]: state[key].concat(medication)
    });
  }

  if (action.type === types.DELETE_MEDICATION) {
    const { destinationStore, medication } = action.data || {};

    const key = destinationStore;

    return Object.assign({}, state, {
      [key]: reject(
        state[key],
        ({ functionType, id }) =>
          medication.functionType === functionType && medication.id === id
      )
    });
  }

  if (action.type === types.INITIALIZE_MEDICATIONS) {
    const {
      allMedications,
      allMedicationsStatus,
      emptyMedicationDropdownsAnswered,
      yellowZoneOption
    } = action.data || {};
    const {
      doctorRecommendations,
      original,
      rulesRecommendations,
      rulesRecommendationsMedicationOptions,
      stepUpRecommendations
    } = allMedications || {};
    const {
      doctorRecommendations: doctorRecommendationsStatus,
      original: originalStatus,
      rulesRecommendations: rulesRecommendationsStatus,
      stepUpRecommendations: stepUpRecommendationsStatus
    } = allMedicationsStatus || {};

    return Object.assign({}, state, {
      initializedMedications: true,
      doctorRecommendations,
      doctorRecommendationsStatus,
      original,
      originalStatus,
      rulesRecommendations,
      rulesRecommendationsMedicationOptions,
      rulesRecommendationsStatus,
      stepUpRecommendations,
      stepUpRecommendationsStatus,
      yellowZoneOption,
      emptyMedicationDropdownsAnswered
    });
  }

  if (action.type === types.UPDATE_MEDICATION) {
    const { destinationStore, newMedication, oldMedication } =
      action.data || {};

    const key = destinationStore;

    const newMedications = state[key].map(medication => {
      const { functionType, responseCollectionUUID } = medication;

      const matchedOldMedication =
        functionType === oldMedication.functionType &&
        responseCollectionUUID === oldMedication.responseCollectionUUID;

      // finds the old med in state with same functionType or blank medicaiton
      // to replace with new one
      if (matchedOldMedication) {
        return newMedication;
      }

      return medication;
    });

    return Object.assign({}, state, {
      [key]: newMedications
    });
  }

  if (action.type === types.UPDATE_MEDICATIONS) {
    const { destinationStore, medications } = action.data || {};

    return Object.assign({}, state, {
      [destinationStore]: medications
    });
  }

  if (action.type === types.POST_MEDICATIONS_FULFILLED) {
    const {
      destinationStore,
      medications
      // response
    } = action.data || {};

    return Object.assign({}, state, {
      [destinationStore]: medications
    });
  }

  if (action.type === types.UPDATE_MEDICATION_STATUS_FULFILLED) {
    const { destinationStore, medicationStatus } = action.data || {};

    const getDs = ds => {
      return ds === 'rulesRecommendationsMedicationOptions'
        ? 'rulesRecommendations'
        : ds;
    };

    const dsKey = getDs(destinationStore);

    return Object.assign({}, state, {
      [`${dsKey}Status`]: medicationStatus
    });
  }

  if (action.type === types.DELETE_NEXT_DS_PROGRESS_FULFILLED) {
    const { destinationStoresToDelete, propertiesToUpdate } = action.data || {};

    const getDs = ds => {
      return ds === 'rulesRecommendationsMedicationOptions'
        ? 'rulesRecommendations'
        : ds;
    };

    const deletedDestinationStores = destinationStoresToDelete.reduce(
      (acc, ds) => {
        const dsKey = getDs(ds);

        return Object.assign({}, acc, {
          [ds]: [],
          [`${dsKey}Status`]: 1
        });
      },
      {}
    );

    return Object.assign({}, state, {
      ...deletedDestinationStores,
      ...propertiesToUpdate
    });
  }

  if (action.type === types.DELETE_MEDICATIONS_FULFILLED) {
    const { destinationStore, responseCollectionUUIDs } = action.data || {};

    const medications = state[destinationStore].filter(
      ({ responseCollectionUUID }) =>
        !responseCollectionUUIDs.includes(responseCollectionUUID)
    );

    return Object.assign({}, state, {
      [destinationStore]: medications
    });
  }

  if (action.type === types.SET_STEP_UP_YELLOW_ZONE_OPTION) {
    const { yellowZoneOption } = action.data || {};

    return Object.assign({}, state, { yellowZoneOption });
  }

  if (action.type === types.SET_UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED) {
    const { emptyMedicationDropdownsAnswered } = action.data || {};

    return Object.assign({}, state, { emptyMedicationDropdownsAnswered });
  }

  return state;
};

export default reducer;
