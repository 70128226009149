export const TYPES = {
  REDIRECT_REGISTER_URL: 'REDIRECT_REGISTER_URL',

  VALIDATE_LOGIN_FULFILLED: 'VALIDATE_LOGIN_FULFILLED',
  VALIDATE_LOGIN_PENDING: 'VALIDATE_LOGIN_PENDING',
  VALIDATE_LOGIN_REJECTED: 'VALIDATE_LOGIN_REJECTED',

  REGISTER_USER_FULFILLED: 'REGISTER_USER_FULFILLED',
  REGISTER_USER_PENDING: 'REGISTER_USER_PENDING',
  REGISTER_USER_REJECTED: 'REGISTER_USER_REJECTED',

  SEND_EMAIL_VERIFICATION_FULFILLED: 'SEND_EMAIL_VERIFICATION_FULFILLED',
  SEND_EMAIL_VERIFICATION_PENDING: 'SEND_EMAIL_VERIFICATION_PENDING',
  SEND_EMAIL_VERIFICATION_REJECTED: 'SEND_EMAIL_VERIFICATION_REJECTED',

  ACTIVATE_ACCOUNT_FULFILLED: 'ACTIVATE_ACCOUNT_FULFILLED',
  ACTIVATE_ACCOUNT_PENDING: 'ACTIVATE_ACCOUNT_PENDING',
  ACTIVATE_ACCOUNT_REJECTED: 'ACTIVATE_ACCOUNT_REJECTED',

  SET_MAYBE_LOGIN_FORM_VALUES: 'SET_MAYBE_LOGIN_FORM_VALUES',
  SET_ACTIVATED_USER_INFO: 'SET_ACTIVATED_USER_INFO',
  SET_EMAIL_TO_ACTIVATE: 'SET_EMAIL_TO_ACTIVATE',
  SET_TERMS_OF_USE_AGREE: 'SET_TERMS_OF_USE_AGREE',
  SET_E_CONSENT_AGREE: 'SET_E_CONSENT_AGREE',
  SET_REGISTER_FORM_VALUES: 'SET_REGISTER_FORM_VALUES',
  SET_TOKEN_UUID: 'SET_TOKEN_UUID'
};

export default {
  TYPES
};
