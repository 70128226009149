// import sdk from '@eams-dev/js-qoc-sdk';
// import { chain, get, size } from 'lodash';

// import isDestinationStoreEmpty from '_cdss/library/businessLogic/isDestinationStoreEmpty';
import extractMedicationToResponses from '_cdss/utils/extractMedicationToResponses';

import { chain } from 'lodash';
import get from 'lodash/get';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { actions as qActions } from '_qoc-redux';

import modelMedications from '_cdss/utils/modelMedications';
import modelMedicationsStatus from '_cdss/utils/modelMedicationsStatus';

// import * as patientProfileActions from '../patientProfile/actions';
import { types } from './constants';
import {
  MEDICATION_DESTINATION_STORES,
  EMPTY_MEDICATION_DROPDOWNS_ANSWERED_QUESTION_ID,
  EMPTY_MEDICATION_DROPDOWNS_ANSWERED_SURVEY_LINK_UUID,
  MEDICATION_DESTINATION_STORES_TO_DELETE,
  MEDICATION_STATUS,
  MEDICATION_STATUS_OPTION_SET_UUID,
  YELLOW_ZONE_QUESTION_ID,
  YELLOW_ZONE_SURVEY_LINK_UUID,
  YELLOW_ZONE_OPTION_SET_UUID
} from '../constants';

const getYellowZoneOption = (props = {}) => {
  const { surveyOptions = {}, surveyResponses = {} } = props;

  const yellowZoneOption = chain(surveyResponses)
    .find({ questionId: YELLOW_ZONE_QUESTION_ID })
    .thru((response = {}) => {
      const { optionUUID, responseNumeric } = response;

      if (optionUUID) {
        return chain(surveyOptions)
          .find({ optionUUID })
          .get('optionValue')
          .value();
      }

      return responseNumeric;
    })
    .thru(optionValue => optionValue || null)
    .value();

  return yellowZoneOption;
};

export const getEmptyMedicationDropdownsAnswered = (props = {}) => {
  const { surveyResponses = {} } = props;

  const emptyMedicationDropdownsAnswered =
    chain(surveyResponses)
      .find({ questionId: EMPTY_MEDICATION_DROPDOWNS_ANSWERED_QUESTION_ID })
      .get('responseState')
      .value() || null;

  return emptyMedicationDropdownsAnswered;
};

/* setCurrentMedications */
export function setCurrentMedications(props) {
  const { destinationStore } = props || {};

  return {
    data: {
      destinationStore
    },
    type: types.SET_CURRENT_MEDICATIONS
  };
}
/* /setCurrentMedications */

/* addMedication */
export function addMedication(props) {
  const { destinationStore = 'currentMedications', medication } = props || {};

  return {
    data: {
      destinationStore,
      medication
    },
    type: types.ADD_MEDICATION
  };
}
/* / */

/* deleteMedication */
export function deleteMedication(props) {
  const { destinationStore = 'currentMedications', medication } = props || {};

  return {
    data: {
      destinationStore,
      medication
    },
    type: types.DELETE_MEDICATION
  };
}
/* / */

/* updateMedication */
export function updateMedication(props) {
  const {
    destinationStore = 'currentMedications',
    oldMedication,
    newMedication
  } = props || {};

  return {
    data: {
      destinationStore,
      newMedication,
      oldMedication
    },
    type: types.UPDATE_MEDICATION
  };
}
/* / */

/* updateMedications */
export function updateMedications(props) {
  const { destinationStore = 'currentMedications', medications } = props || {};

  return {
    data: {
      destinationStore,
      medications
    },
    type: types.UPDATE_MEDICATIONS
  };
}
/* /updateMedications */

/* initializeMedications */
export function initializeMedications(props) {
  return (dispatch, getState) => {
    const { instanceLinkUUID } = props || {};

    const surveyOptions = get(getState(), 'cache.options') || {};
    const surveyQuestions = get(getState(), 'cache.questions') || {};
    const surveyResponses = get(getState(), 'cache.responses') || {};

    const medicationsResponses = filter(
      surveyResponses,
      surveyResponse =>
        surveyResponse.instanceLinkUUID === instanceLinkUUID &&
        surveyResponse.questionId !== 'meds_status'
    );
    const medicationsStatusResponses = filter(
      surveyResponses,
      surveyResponse =>
        surveyResponse.instanceLinkUUID === instanceLinkUUID &&
        surveyResponse.questionId === 'meds_status'
    );

    const allMedications = modelMedications({
      surveyOptions,
      surveyQuestions,
      surveyResponses: medicationsResponses
    });

    const allMedicationsStatus = modelMedicationsStatus({
      surveyResponses: medicationsStatusResponses
    });

    const yellowZoneOption = getYellowZoneOption({
      surveyOptions,
      surveyResponses
    });

    const emptyMedicationDropdownsAnswered = getEmptyMedicationDropdownsAnswered(
      {
        surveyResponses
      }
    );

    dispatch({
      data: {
        allMedications,
        allMedicationsStatus,
        emptyMedicationDropdownsAnswered,
        yellowZoneOption
      },
      type: types.INITIALIZE_MEDICATIONS
    });
  };
}
/* / */

/* deleteNextDestinationStoresProgressAsync */
export const deleteNextDestinationStoresProgressAsync = props => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.DELETE_NEXT_DS_PROGRESS_PENDING
  });

  const { currentDestinationStore } = props || {};

  try {
    const instanceLinkUUID =
      get(getState(), 'patientProfiles.selectedProfile.instanceLinkUUID') || '';
    let { emptyMedicationDropdownsAnswered, yellowZoneOption } =
      get(getState(), 'medications') || {};

    const destinationStoresToDelete =
      MEDICATION_DESTINATION_STORES_TO_DELETE[currentDestinationStore];

    let surveyResponseUUIDs = [];

    if (destinationStoresToDelete.length > 0) {
      surveyResponseUUIDs = chain(getState())
        .get('cache.responses')
        .filter(
          surveyResponse =>
            destinationStoresToDelete.includes(surveyResponse.responseSetId) &&
            surveyResponse.instanceLinkUUID === instanceLinkUUID
        )
        .map('surveyResponseUUID')
        .value();

      if (
        destinationStoresToDelete.includes(
          MEDICATION_DESTINATION_STORES.stepUpRecommendations
        )
      ) {
        // only need to reset redux since this question has responseSetId of destinationStore
        yellowZoneOption = null;
      }

      if (
        destinationStoresToDelete.includes(
          MEDICATION_DESTINATION_STORES.rulesRecommendations
        )
      ) {
        // only need to reset redux since this question has responseSetId of destinationStore
        emptyMedicationDropdownsAnswered = null;
      }

      if (surveyResponseUUIDs.length > 0) {
        await dispatch(
          qActions.cache.deleteInstancesResponses({
            requestProps: {
              surveyResponseUUIDs
            }
          })
        );
      }
    }

    return dispatch({
      data: {
        currentDestinationStore,
        destinationStoresToDelete,
        surveyResponseUUIDs,
        propertiesToUpdate: {
          emptyMedicationDropdownsAnswered,
          yellowZoneOption
        }
      },
      type: types.DELETE_NEXT_DS_PROGRESS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.DELETE_NEXT_DS_PROGRESS_REJECTED
    });
  }
};
/* / */

/* updateMedicationStatusAsync */
export const updateMedicationStatusAsync = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.UPDATE_MEDICATION_STATUS_PENDING
  });

  const {
    destinationStore,
    medicationStatus = MEDICATION_STATUS.DRAFT
  } = props;

  try {
    const instanceLinkUUID =
      get(getState(), 'patientProfiles.selectedProfile.instanceLinkUUID') || '';
    const questionsOptions = get(getState(), 'cache.options');
    const optionUUID = chain(questionsOptions)
      .find({
        optionSetUUID: MEDICATION_STATUS_OPTION_SET_UUID,
        optionValue: medicationStatus
      })
      .get('optionUUID')
      .value();

    const responses = [
      {
        instanceLinkUUID,
        optionUUID,
        responseDataType: 'numeric',
        responseNumeric: medicationStatus,
        responseSetId: destinationStore,
        responseTypeClass: 'SelectOne',
        questionId: 'meds_status',
        surveyLinkUUID: '179550B4-97FC-4975-8D97-2C06EF787AFB'
      }
    ];

    const response = await dispatch(
      qActions.cache.postInstancesResponses({
        requestProps: {
          surveyResponses: responses
        }
      })
    );

    if (medicationStatus === MEDICATION_STATUS.SUBMITTED) {
      await dispatch(
        deleteNextDestinationStoresProgressAsync({
          currentDestinationStore: destinationStore
        })
      );
    }

    return dispatch({
      data: {
        destinationStore,
        medicationStatus,
        response
      },
      type: types.UPDATE_MEDICATION_STATUS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.UPDATE_MEDICATION_STATUS_REJECTED
    });
  }
};
/* / */

const getAllMedicationResponses = (props = {}) => {
  const {
    destinationStore = '',
    instanceLinkUUID = '',
    medications = [],
    questions = [],
    questionsOptions = []
  } = props;

  const medicationOptions = chain(medications)
    .filter(medication => medication.medications)
    .reduce((acc, curr) => {
      if (curr.medications && curr.medications.length > 0) {
        return acc.concat(curr.medications);
      }

      return acc;
    }, [])
    .value();

  const medicationResponses = extractMedicationToResponses({
    destinationStore,
    instanceLinkUUID,
    medications,
    questions,
    questionsOptions
  });

  const medicationOptionsResponses =
    medicationOptions.length > 0
      ? extractMedicationToResponses({
          destinationStore: `${destinationStore}MedicationOptions`,
          instanceLinkUUID,
          medications: medicationOptions,
          questions,
          questionsOptions
        })
      : [];

  const allMedicationResponses = []
    .concat(medicationResponses, medicationOptionsResponses)
    .filter(response => Object.keys(response).length > 0);

  return allMedicationResponses;
};

/* postMedicationsAsync */
export const postMedicationsAsync = props => async (dispatch, getState) => {
  dispatch({
    type: types.POST_MEDICATIONS_PENDING
  });

  const { destinationStore, medications: medicationsProps = [] } = props || {};
  console.log('postMedicationsAsync medications: ', medicationsProps);

  try {
    const medications =
      medicationsProps.length === 0
        ? get(getState(), `medications[${destinationStore}]`)
        : medicationsProps;
    const instanceLinkUUID =
      get(getState(), 'patientProfiles.selectedProfile.instanceLinkUUID') || '';
    const questions = get(getState(), 'cache.questions');
    const questionsOptions = get(getState(), 'cache.options');

    const allMedicationResponses = getAllMedicationResponses({
      destinationStore,
      instanceLinkUUID,
      medications,
      questions,
      questionsOptions
    });

    if (size(allMedicationResponses) > 125) {
      throw new Error('The maximum number of post response(s) was reached.');
    }

    const response =
      allMedicationResponses.length > 0
        ? await dispatch(
            qActions.cache.postInstancesResponses({
              requestProps: {
                surveyResponses: allMedicationResponses
              }
            })
          )
        : {};

    return dispatch({
      data: {
        destinationStore,
        medications,
        response
      },
      type: types.POST_MEDICATIONS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.POST_MEDICATIONS_REJECTED
    });
  }
};
/* / */

/* postMedicationsAndStatusAsync */
export const postMedicationsAndStatusAsync = props => async dispatch => {
  dispatch({
    type: types.POST_MEDICATIONS_AND_STATUS_PENDING
  });

  const {
    destinationStore,
    medicationStatus = MEDICATION_STATUS.DRAFT,
    medications,
    method = 'POST'
  } = props || {};

  try {
    const promises = await Promise.all([
      dispatch(
        postMedicationsAsync({
          destinationStore,
          medications,
          method
        })
      ),
      dispatch(
        updateMedicationStatusAsync({
          destinationStore,
          medicationStatus
        })
      )
    ]);

    return dispatch({
      data: {
        promises
      },
      type: types.POST_MEDICATIONS_AND_STATUS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.POST_MEDICATIONS_AND_STATUS_REJECTED
    });
  }
};
/* / */

/* deleteMedicationsAsync */
export const deleteMedicationsAsync = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.DELETE_MEDICATIONS_PENDING
  });

  const responses = get(getState(), 'cache.responses');
  const { destinationStore, deletedMedications = [] } = props;

  try {
    const medications =
      deletedMedications.length === 0
        ? get(getState(), `medications[${destinationStore}]`)
        : deletedMedications;
    const responseCollectionUUIDs = medications.map(
      ({ responseCollectionUUID }) => responseCollectionUUID
    );

    if (medications.length > 0) {
      const surveyResponseUUIDs = chain(responses)
        .filter(
          ({ responseCollectionUUID, responseSetId }) =>
            responseSetId === destinationStore &&
            responseCollectionUUIDs.includes(responseCollectionUUID)
        )
        .map(({ surveyResponseUUID }) => surveyResponseUUID)
        .value();

      if (surveyResponseUUIDs.length > 0) {
        await dispatch(
          qActions.cache.deleteInstancesResponses({
            requestProps: {
              surveyResponseUUIDs
            }
          })
        );
      }
    }

    return dispatch({
      data: {
        destinationStore,
        deletedMedications,
        responseCollectionUUIDs,
        medications
      },
      type: types.DELETE_MEDICATIONS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.DELETE_MEDICATIONS_REJECTED
    });
  }
};
/* / */

/* saveMedicationProgressAsync */
export const saveMedicationProgressAsync = (props = {}) => async dispatch => {
  dispatch({
    type: types.SAVE_MEDICATION_PROGRESS_PENDING
  });

  const {
    destinationStore,
    medications,
    medicationStatus = MEDICATION_STATUS.DRAFT,
    onFulfilled
  } = props;

  try {
    await dispatch(
      deleteMedicationsAsync({
        destinationStore
      })
    );
    await dispatch(
      postMedicationsAndStatusAsync({
        destinationStore,
        medications,
        medicationStatus
      })
    );

    if (typeof onFulfilled === 'function') {
      onFulfilled();
    }

    return dispatch({
      data: {},
      type: types.SAVE_MEDICATION_PROGRESS_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.SAVE_MEDICATION_PROGRESS_REJECTED
    });
  }
};
/* / */

/* setUpdateEmptyMedicationDropdownsAnswered */
export const setUpdateEmptyMedicationDropdownsAnswered = props => {
  const { value = true } = props || {};

  return {
    data: {
      emptyMedicationDropdownsAnswered: value
    },
    type: types.SET_UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED
  };
};
/* / */

/* updateEmptyMedicationDropdownsAnswered */
export const updateEmptyMedicationDropdownsAnsweredAsync = () => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_PENDING
  });

  try {
    const emptyMedicationDropdownsAnswered = get(
      getState(),
      'medications.emptyMedicationDropdownsAnswered'
    );

    if (!emptyMedicationDropdownsAnswered) {
      return dispatch({
        data: {
          postResponse: {}
        },
        type: types.UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_FULFILLED
      });
    }

    const responses = get(getState(), 'cache.responses');
    const instanceLinkUUID =
      get(getState(), 'patientProfiles.selectedProfile.instanceLinkUUID') || '';

    if (!instanceLinkUUID) {
      throw new Error('Patient not selected, did not find instanceLinkUUID');
    }

    const response = chain(responses)
      .find({ questionId: EMPTY_MEDICATION_DROPDOWNS_ANSWERED_QUESTION_ID })
      .thru(foundResponse => {
        if (foundResponse) {
          return Object.assign({}, foundResponse, {
            responseSetId: MEDICATION_DESTINATION_STORES.rulesRecommendations,
            responseState: emptyMedicationDropdownsAnswered
          });
        }

        return {
          instanceLinkUUID,
          responseSetId: MEDICATION_DESTINATION_STORES.rulesRecommendations,
          questionId: EMPTY_MEDICATION_DROPDOWNS_ANSWERED_QUESTION_ID,
          responseState: emptyMedicationDropdownsAnswered,
          surveyLinkUUID: EMPTY_MEDICATION_DROPDOWNS_ANSWERED_SURVEY_LINK_UUID
        };
      })
      .value();

    const postResponse = await dispatch(
      qActions.cache.postInstancesResponses({
        requestProps: {
          surveyResponses: [response]
        }
      })
    );

    return dispatch({
      data: {
        postResponse
      },
      type: types.UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_FULFILLED
    });
  } catch (error) {
    return dispatch({
      data: {
        error
      },
      type: types.UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_REJECTED
    });
  }
};
/* / */

/* setStepUpYellowZoneOption */
export const setStepUpYellowZoneOption = props => {
  const { optionValue } = props || {};

  return {
    data: {
      yellowZoneOption: optionValue
    },
    type: types.SET_STEP_UP_YELLOW_ZONE_OPTION
  };
};
/* / */

/* postStepUpYellowZoneOptionAsync */
export const postStepUpYellowZoneOptionAsync = props => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.POST_STEP_UP_YELLOW_ZONE_OPTION_PENDING
  });

  const { optionValue } = props || {};

  try {
    // deleteMedicationsAsync({ destinationStore: 'stepUpRecommendations' });
    const yellowZoneOption =
      optionValue || get(getState(), 'medications.yellowZoneOption') || null;

    console.log('postStepUpYellowZoneOptionAsync props: ', props);

    if (yellowZoneOption !== 0 && !yellowZoneOption) {
      return dispatch({
        data: {
          postResponse: {}
        },
        type: types.POST_STEP_UP_YELLOW_ZONE_OPTION_FULFILLED
      });
    }

    const responses = get(getState(), 'cache.responses');
    const questionsOptions = get(getState(), 'cache.options');
    const instanceLinkUUID =
      get(getState(), 'patientProfiles.selectedProfile.instanceLinkUUID') || '';
    const option =
      chain(questionsOptions)
        .find({
          optionSetUUID: YELLOW_ZONE_OPTION_SET_UUID,
          optionValue: yellowZoneOption
        })
        .value() || {};
    const { optionUUID } = option;

    if (!optionUUID) {
      throw new Error('No valid optionUUID provided');
    }

    if (!instanceLinkUUID) {
      throw new Error('Patient not selected, did not find instanceLinkUUID');
    }

    const response = chain(responses)
      .find({ questionId: YELLOW_ZONE_QUESTION_ID })
      .thru(foundResponse => {
        const responseTypeClass = 'Radio';
        console.log(
          'postStepUpYellowZoneOptionAsync foundResponse outside: ',
          foundResponse
        );

        if (foundResponse) {
          console.log(
            'postStepUpYellowZoneOptionAsync foundResponse inside: ',
            foundResponse
          );
          return Object.assign({}, foundResponse, {
            optionUUID,
            responseOptionUUID: optionUUID,
            responseNumeric: optionValue,
            responseSetId: MEDICATION_DESTINATION_STORES.stepUpRecommendations,
            responseTypeClass
          });
        }
        console.log(
          'postStepUpYellowZoneOptionAsync !foundResponse YELLOW_ZONE_QUESTION_ID: ',
          YELLOW_ZONE_QUESTION_ID
        );

        return {
          instanceLinkUUID,
          optionUUID,
          responseOptionUUID: optionUUID,
          questionId: YELLOW_ZONE_QUESTION_ID,
          responseNumeric: optionValue,
          responseSetId: MEDICATION_DESTINATION_STORES.stepUpRecommendations,
          responseTypeClass,
          surveyLinkUUID: YELLOW_ZONE_SURVEY_LINK_UUID
        };
      })
      .value();

    const postResponse = await dispatch(
      qActions.cache.postInstancesResponses({
        requestProps: {
          surveyResponses: [response]
        }
      })
    );

    console.log('postStepUpYellowZoneOptionAsync postResponse: ', postResponse);

    return dispatch({
      data: {
        postResponse
      },
      type: types.POST_STEP_UP_YELLOW_ZONE_OPTION_FULFILLED
    });
  } catch (error) {
    console.log('postStepUpYellowZoneOptionAsync postResponse error: ', error);
    return dispatch({
      data: {
        error
      },
      type: types.POST_STEP_UP_YELLOW_ZONE_OPTION_REJECTED
    });
  }
};
/* / */
