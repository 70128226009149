import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = ({ documents }) => ({ documents });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteDocumentsProfilesPropertys:
        actions.deleteDocumentsProfilesPropertys,
      fetchDocumentsProfilesPropertys: actions.fetchDocumentsProfilesPropertys,
      postDocumentsProfilesPropertys: actions.postDocumentsProfilesPropertys,
      updateDocumentsProfilesPropertys: actions.updateDocumentsProfilesPropertys
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
