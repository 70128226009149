export const TYPES = {
  DELETE_SESSION_KEY_FULFILLED: 'DELETE_SESSION_KEY_FULFILLED',
  DELETE_SESSION_KEY_PENDING: 'DELETE_SESSION_KEY_PENDING',
  DELETE_SESSION_KEY_REJECTED: 'DELETE_SESSION_KEY_REJECTED',

  GET_SESSION_KEY_FULFILLED: 'GET_SESSION_KEY_FULFILLED',
  GET_SESSION_KEY_PENDING: 'GET_SESSION_KEY_PENDING',
  GET_SESSION_KEY_REJECTED: 'GET_SESSION_KEY_REJECTED',

  POST_PUT_SESSION_KEY_VALUE_FULFILLED: 'POST_PUT_SESSION_KEY_VALUE_FULFILLED',
  POST_PUT_SESSION_KEY_VALUE_PENDING: 'POST_PUT_SESSION_KEY_VALUE_PENDING',
  POST_PUT_SESSION_KEY_VALUE_REJECTED: 'POST_PUT_SESSION_KEY_VALUE_REJECTED'
};

export default {
  TYPES
};
