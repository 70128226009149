import { TYPES } from './constants';
import { ASYNC_STATUSES } from '../constants';

export const initialState = {
  authenticated: false,
  loginStatuses: {},
  fetchLoginStatus: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchLoginStatuses: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  login: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  logout: {
    asyncStatus: '',
    message: '',
    status: 0
  }
};

export default (state = initialState, action) => {
  if (action.type === TYPES.FETCH_LOGIN_STATUS_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      authenticated: true,
      fetchLoginStatus: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_LOGIN_STATUS_PENDING) {
    return Object.assign({}, state, {
      fetchLoginStatus: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_LOGIN_STATUS_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      authenticated: false,
      fetchLoginStatus: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_LOGIN_STATUSES_FULFILLED) {
    const { loginStatuses, message, status } = action.payload;

    return Object.assign({}, state, {
      loginStatuses,
      fetchLoginStatuses: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_LOGIN_STATUSES_PENDING) {
    return Object.assign({}, state, {
      fetchLoginStatuses: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_LOGIN_STATUSES_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchLoginStatuses: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.LOGIN_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      authenticated: true,
      login: { asyncStatus: ASYNC_STATUSES.FULFILLED, message, status }
    });
  }

  if (action.type === TYPES.LOGIN_PENDING) {
    return Object.assign({}, state, {
      login: { asyncStatus: ASYNC_STATUSES.PENDING, message: '', status: 0 }
    });
  }

  if (action.type === TYPES.LOGIN_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      authenticated: false,
      login: { asyncStatus: ASYNC_STATUSES.REJECTED, message, status }
    });
  }

  if (action.type === TYPES.LOGOUT_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      authenticated: false,
      logout: { asyncStatus: ASYNC_STATUSES.FULFILLED, message, status }
    });
  }

  if (action.type === TYPES.LOGOUT_PENDING) {
    return Object.assign({}, state, {
      logout: { asyncStatus: ASYNC_STATUSES.PENDING, message: '', status: 0 }
    });
  }

  if (action.type === TYPES.LOGOUT_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      logout: { asyncStatus: ASYNC_STATUSES.REJECTED, message, status }
    });
  }

  return state;
};
