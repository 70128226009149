import { TYPES } from './constants';
import { ASYNC_STATUSES } from '../constants';

export const initialState = {
  profiles: {},
  profileVO: {},
  selectedDoctor: {},
  fetchProfiles: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  fetchDoctorProfile: {
    asyncStatus: ASYNC_STATUSES.PENDING,
    message: '',
    status: 0
  },
  fetchProfileVO: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  requestResetPassword: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  resetPassword: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  updateProfile: {
    asyncStatus: '',
    message: '',
    status: 0
  },
  updateUserPassword: {
    asyncStatus: '',
    message: '',
    status: 0
  }
};

export default (state = initialState, action) => {
  if (action.type === TYPES.FETCH_PROFILES_FULFILLED) {
    const { message, profiles, status } = action.payload;

    return Object.assign({}, state, {
      profiles,
      fetchProfiles: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_DOCTOR_PROFILE_FULFILLED) {
    const { provider, message } = action.payload;

    return Object.assign({}, state, {
      selectedDoctor: provider,
      fetchDoctorProfile: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message
      }
    });
  }

  if (action.type === TYPES.FETCH_DOCTOR_PROFILE_PENDING) {
    return Object.assign({}, state, {
      fetchDoctorProfile: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_DOCTOR_PROFILE_REJECTED) {
    return Object.assign({}, state, {
      fetchDoctorProfile: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_PROFILES_PENDING) {
    return Object.assign({}, state, {
      fetchProfiles: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_PROFILES_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchProfiles: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_PROFILE_VO_FULFILLED) {
    const { message, profileVO, status } = action.payload;

    return Object.assign({}, state, {
      profileVO,
      fetchProfileVO: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.FETCH_PROFILE_VO_PENDING) {
    return Object.assign({}, state, {
      fetchProfileVO: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.FETCH_PROFILE_VO_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      fetchProfileVO: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.REQUEST_RESET_PASSWORD_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      requestResetPassword: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.REQUEST_RESET_PASSWORD_PENDING) {
    return Object.assign({}, state, {
      requestResetPassword: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.REQUEST_RESET_PASSWORD_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      requestResetPassword: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.RESET_PASSWORD_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      resetPassword: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.REQUEST_RESET_PASSWORD_PENDING) {
    return Object.assign({}, state, {
      resetPassword: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.REQUEST_RESET_PASSWORD_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      resetPassword: { asyncStatus: ASYNC_STATUSES.REJECTED, message, status }
    });
  }

  if (action.type === TYPES.UPDATE_PROFILE_FULFILLED) {
    const { message, status, targetProfile } = action.payload;

    const isTargetProfileCurrentProfile =
      state.profileVO.profile.profileUUID === targetProfile.profileUUID;

    const profileVO = isTargetProfileCurrentProfile
      ? Object.assign({}, state.profileVO, {
          profile: {
            ...state.profileVO.profile,
            ...targetProfile
          }
        })
      : state.profileVO;

    const profiles = Object.assign({}, state.profiles, {
      [targetProfile.profileUUID]: {
        ...state.profiles[targetProfile.profileUUID],
        ...targetProfile
      }
    });

    return Object.assign({}, state, {
      profiles,
      profileVO,
      updateProfile: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_PROFILE_PENDING) {
    return Object.assign({}, state, {
      updateProfile: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.UPDATE_PROFILE_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      updateProfile: { asyncStatus: ASYNC_STATUSES.REJECTED, message, status }
    });
  }

  if (action.type === TYPES.UPDATE_USER_PASSWORD_FULFILLED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      updateUserPassword: {
        asyncStatus: ASYNC_STATUSES.FULFILLED,
        message,
        status
      }
    });
  }

  if (action.type === TYPES.UPDATE_USER_PASSWORD_PENDING) {
    return Object.assign({}, state, {
      updateUserPassword: {
        asyncStatus: ASYNC_STATUSES.PENDING,
        message: '',
        status: 0
      }
    });
  }

  if (action.type === TYPES.UPDATE_USER_PASSWORD_REJECTED) {
    const { message, status } = action.payload;

    return Object.assign({}, state, {
      updateUserPassword: {
        asyncStatus: ASYNC_STATUSES.REJECTED,
        message,
        status
      }
    });
  }

  return state;
};
