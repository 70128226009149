import * as gConstants from './constants';
import * as patientProfile from './patientProfile';
import * as patientProfiles from './patientProfiles';
import * as chartNoteTests from './chartNoteTests';
import * as medications from './medications';

export const actions = {
  patientProfile: patientProfile.actions,
  patientProfiles: patientProfiles.actions,
  chartNoteTests: chartNoteTests.actions,
  medications: medications.actions
};

export const connect = {};

export const reducers = {
  patientProfile: patientProfile.reducer,
  patientProfiles: patientProfiles.reducer,
  chartNoteTests: chartNoteTests.reducer,
  medications: medications.reducer
};

export const constants = gConstants;
