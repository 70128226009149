import { sanitizeChartNotesRequest, httpRequest } from './utils';

const apiPostAAPToOscarUrl = '/api/client/aapToOscarRequest';
// const apiPostMRPMessageToOscarUrl = '/api/client/mrpMessageToOscarRequest';
const apiQueueMRPMessageToOscarUrl = '/api/client/queueMrpMessage';
const apiUnqueueMRPMessageToOscarUrl = '/api/client/unqueueMrpMessage';
const apiPostChartNotesRequestUrl = '/api/client/chartNotesRequest';
const apiPostChartNotesRequest2Url = '/api/client/chartNotesRequest2';
const apiPostChartNotesToOscarUrl = '/api/client/chartNotesToOscarRequest';
const apiGetAssignedPatientsUrl = '/api/client/getAssignedPatients';

const method = 'post';

const sdk = {
  // postMRPMessageToOscar: props => {
  //   const { postMRPMessageToOscarVO } = props || {};

  //   return httpRequest({
  //     data: postMRPMessageToOscarVO,
  //     method,
  //     url: apiPostMRPMessageToOscarUrl
  //   });
  // },
  unqueueMRPMessageToOscar: props => {
    const { unqueueMRPMessageToOscarVO } = props || {};

    return httpRequest({
      data: unqueueMRPMessageToOscarVO,
      method,
      url: apiUnqueueMRPMessageToOscarUrl
    });
  },
  queueMRPMessageToOscar: props => {
    const { queueMRPMessageToOscarVO } = props || {};

    return httpRequest({
      data: queueMRPMessageToOscarVO,
      method,
      url: apiQueueMRPMessageToOscarUrl
    });
  },
  postAAPToOscar: props => {
    const { postAAPToOscarVO } = props || {};

    return httpRequest({
      data: postAAPToOscarVO,
      method,
      url: apiPostAAPToOscarUrl
    });
  },
  postChartNotesRequest: props => {
    const data = sanitizeChartNotesRequest(
      Object.assign({}, props.chartNotesRequestVO)
    );
    return httpRequest({ data, method, url: apiPostChartNotesRequestUrl });
  },
  postChartNotesRequest2: props => {
    const data = sanitizeChartNotesRequest(
      Object.assign({}, props.chartNotesRequestVO)
    );
    return httpRequest({ data, method, url: apiPostChartNotesRequest2Url });
  },
  postChartNotesToOscar: props => {
    const data = Object.assign({}, props.chartNotesToOscarRequestVO);
    console.log('-- sdk postChartNotesToOscar data', data);
    return httpRequest({ data, method, url: apiPostChartNotesToOscarUrl });
  },
  getAssignedPatients: () => {
    return httpRequest({ method: 'get', url: apiGetAssignedPatientsUrl });
  }
};

export default sdk;
