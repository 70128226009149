/*eslint-disable*/
import { chain, filter, find, isEmpty } from 'lodash';
import getDoseTarget from './getDoseTarget';

const validateMedication = (props = {}) => {
  const { medication = {} } = props;
  const { device, name, doseICS, doseLABA, doseOther } = medication;

  if (!device || !name || name.toString().toLowerCase() === 'unsure') {
    return false;
  }

  if (!doseICS && !doseLABA && !doseOther) {
    return false;
  }

  return true;
};

const extractMedicationToResponses = (props = {}) => {
  const {
    destinationStore,
    instanceLinkUUID,
    medications,
    questions,
    questionsOptions
  } = props;

  const depricatedRe = /(dep_)/g;
  const medicationsRe = /(med_|meds_)/g;
  const imgRe = /\.(jpg|png|jpeg)/g;

  const medicationsSurveyLinkUUIDHash = chain(questions)
    .reject(({ questionID }) => questionID.match(depricatedRe))
    .filter(({ questionID }) => questionID.match(medicationsRe))
    .reduce(
      (acc, curr) =>
        Object.assign({}, acc, { [curr.questionID]: curr.surveyLinkUUID }),
      {}
    )
    .value();

  const medicationsOptionUUIDHash =
    chain(questionsOptions)
      .filter(option => option.optionIcon.match(medicationsRe))
      .reduce(
        (acc, curr) =>
          Object.assign({}, acc, {
            [curr.optionIcon.replace(imgRe, '')]: curr.optionUUID
          }),
        {}
      )
      .value() || {};

  const puffsOptionSetUUID = 'DEAE259E-FB7B-49DD-BD3D-3CF34EAC67A7';
  const puffsOptionUUIDHash = chain(questionsOptions)
    .filter({ optionSetUUID: puffsOptionSetUUID })
    .reduce(
      (acc, curr) =>
        Object.assign({}, acc, {
          [curr.optionValue]: curr.optionUUID
        }),
      {}
    )
    .value();

  const pillsOptionSetUUID = 'D2BDDDC8-D9B0-41D0-AE59-093935179275';
  const pillsOptionUUIDHash = chain(questionsOptions)
    .filter({ optionSetUUID: pillsOptionSetUUID })
    .reduce(
      (acc, curr) =>
        Object.assign({}, acc, {
          [curr.optionValue]: curr.optionUUID
        }),
      {}
    )
    .value();

  const freqOptionSetUUID = 'A3BB7A4B-0BF7-445B-B10B-F4DA2D8F73A9';
  const freqOptionUUIDHash = chain(questionsOptions)
    .filter({ optionSetUUID: freqOptionSetUUID })
    .reduce(
      (acc, curr) =>
        Object.assign({}, acc, {
          [curr.optionValue]: curr.optionUUID
        }),
      {}
    )
    .value();

  const regularityOptionSetUUID = '0FD45CB6-D3C1-47CC-A591-785253B82701';
  const regularityOptionUUIDHash = chain(questionsOptions)
    .filter({ optionSetUUID: regularityOptionSetUUID })
    .reduce(
      (acc, curr) =>
        Object.assign({}, acc, {
          [curr.optionValue]: curr.optionUUID
        }),
      {}
    )
    .value();

  const controllerAndRelieverMedications = filter(medications, {
    functionType: 'controller',
    asReliever: true
  });

  const medicationResponses = chain(medications)
    .reject(({ functionType, name }) => {
      // if found controller for reliever and has asReliever truthy value, it will reject the medication
      // otherwise, keep the medication
      if (functionType === 'reliever') {
        const foundController =
          find(controllerAndRelieverMedications, { name }) || {};

        return foundController.asReliever;
      }

      return false;
    })
    .filter(medication => validateMedication({ medication }))
    .reduce((acc, medication) => {
      const {
        asReliever,
        chemicalType,
        daysPerSession,
        device,
        dose,
        doseICS,
        doseLABA,
        doseOther,
        functionType,
        name,
        puffsPerTime,
        responseCollectionUUID,
        tag,
        tagYellow,
        timesPerDay
      } = medication;

      const deviceOptionName = `med_${device}`;
      const deviceOptionUUID = medicationsOptionUUIDHash[deviceOptionName];
      const deviceQuestionId = 'meds_taking';
      const deviceSurveyLinkUUID =
        medicationsSurveyLinkUUIDHash[deviceQuestionId];
      const deviceResponse =
        deviceOptionUUID && deviceSurveyLinkUUID
          ? {
              responseOptionUUID: deviceOptionUUID,
              questionId: deviceQuestionId,
              surveyLinkUUID: deviceSurveyLinkUUID
            }
          : {};

      const updatedNameObject = {
        'teva-salbutamol': 'tevasalbutamol',
        'apo-salbutamol': 'aposalbutamol'
      };
      let updatedName = updatedNameObject[name.toLowerCase()] || name;

      const nameOptionName = `med_${device}_${updatedName}`.toLowerCase();
      const nameIsPackage = !!medicationsOptionUUIDHash[nameOptionName];

      const doseTarget = chain(dose)
        .thru(_dose => {
          if (_dose === 'unsure' || _dose === 0) {
            return _dose;
          }

          return getDoseTarget({
            chemicalType,
            doseICS,
            doseLABA,
            doseOther
          });
        })
        .value();

      const doseName = `med_${device}_${updatedName}_${doseTarget}`.toLowerCase();
      const doseQuestionName = `meds_${device}_${updatedName}_${doseTarget}`.toLowerCase();

      const nameOptionUUID = nameIsPackage
        ? medicationsOptionUUIDHash[nameOptionName]
        : medicationsOptionUUIDHash[doseName];
      // this question does not display
      const nameQuestionId = `meds_${device}`;
      const nameSurveyLinkUUID = medicationsSurveyLinkUUIDHash[nameQuestionId];
      const nameResponse =
        nameOptionUUID && nameSurveyLinkUUID
          ? {
              responseOptionUUID: nameOptionUUID,
              questionId: nameQuestionId,
              surveyLinkUUID: nameSurveyLinkUUID
            }
          : {};

      const packageOptionUUID = medicationsOptionUUIDHash[doseName];
      const packageQuestionId = `meds_${device}_${updatedName}`;
      const packageSurveyLinkUUID =
        medicationsSurveyLinkUUIDHash[packageQuestionId];
      const packageResponse =
        packageOptionUUID && packageSurveyLinkUUID
          ? {
              responseOptionUUID: packageOptionUUID,
              questionId: packageQuestionId,
              surveyLinkUUID: packageSurveyLinkUUID
            }
          : {};

      const puffsOptionUUID = puffsOptionUUIDHash[puffsPerTime];
      const puffsQuestionId = `${doseQuestionName}_puffs`;
      const puffsSurveyLinkUUID =
        medicationsSurveyLinkUUIDHash[puffsQuestionId];
      const puffsResponse =
        puffsOptionUUID && puffsSurveyLinkUUID
          ? {
              responseOptionUUID: puffsOptionUUID,
              questionId: puffsQuestionId,
              surveyLinkUUID: puffsSurveyLinkUUID
            }
          : {};

      const pillsOptionUUID = pillsOptionUUIDHash[puffsPerTime];
      const pillsQuestionId = `${doseQuestionName}_pills`;
      const pillsSurveyLinkUUID =
        medicationsSurveyLinkUUIDHash[pillsQuestionId];
      const pillsResponse =
        pillsOptionUUID && pillsSurveyLinkUUID
          ? {
              responseOptionUUID: pillsOptionUUID,
              questionId: pillsQuestionId,
              surveyLinkUUID: pillsSurveyLinkUUID
            }
          : {};

      let freqOptionUUID =
        freqOptionUUIDHash[
          name === 'prednisone' ? daysPerSession : timesPerDay
        ];
      const freqQuestionId = `${doseQuestionName}_freq`;
      const freqSurveyLinkUUID = medicationsSurveyLinkUUIDHash[freqQuestionId];

      if (timesPerDay === 'occasionally–not regularly') {
        freqOptionUUID = freqOptionUUIDHash[9998];
      }

      const freqResponse =
        freqOptionUUID && freqSurveyLinkUUID
          ? {
              responseOptionUUID: freqOptionUUID,
              questionId: freqQuestionId,
              surveyLinkUUID: freqSurveyLinkUUID
            }
          : {};

      // const funcTypeOptionUUID =
      //   asReliever && functionType === 'controller'
      //     ? regularityOptionUUIDHash[2]
      //     : regularityOptionUUIDHash[1];

      let funcTypeOptionUUID = regularityOptionUUIDHash[1];

      if (asReliever && functionType === 'controller') {
        funcTypeOptionUUID = regularityOptionUUIDHash[2];
      } else if (name === 'symbicort' && functionType === 'reliever') {
        funcTypeOptionUUID = regularityOptionUUIDHash[3];
      }

      const funcTypeQuestionId = `${doseQuestionName}_function`;
      const funcTypeSurveyLinkUUID =
        medicationsSurveyLinkUUIDHash[funcTypeQuestionId];
      const funcTypeResponse =
        funcTypeOptionUUID && funcTypeSurveyLinkUUID
          ? {
              responseOptionUUID: funcTypeOptionUUID,
              questionId: funcTypeQuestionId,
              surveyLinkUUID: funcTypeSurveyLinkUUID
            }
          : {};

      const tagResponse =
        tag && tag.length > 0
          ? {
              responseString: tag,
              questionId: 'meds_tag',
              surveyLinkUUID: '3535676F-B402-43B5-B043-482A160118C5'
            }
          : {};

      const tagYellowResponse =
        tagYellow && tagYellow.length > 0
          ? {
              responseString: tagYellow,
              questionId: 'meds_yellow_tag',
              surveyLinkUUID: '112CE1BE-9478-4666-907A-64B6B178BC50'
            }
          : {};

      const responses = chain(
        [].concat(
          deviceResponse,
          nameResponse,
          packageResponse,
          puffsResponse,
          pillsResponse,
          freqResponse,
          funcTypeResponse,
          tagResponse,
          tagYellowResponse
        )
      )
        .filter(response => !isEmpty(response))
        .map(response => {
          if (response.responseOptionUUID) {
            return Object.assign({}, response, {
              optionUUID: response.responseOptionUUID,
              responseCollectionUUID
            });
          }

          return Object.assign({}, response, { responseCollectionUUID });
        })
        .value();

      return acc.concat(responses);
    }, [])
    .filter(response => !isEmpty(response))
    .map(response => {
      if (response.questionId === 'meds_tag') {
        return Object.assign({}, response, {
          instanceLinkUUID,
          responseDataType: 'string',
          responseSetId: destinationStore,
          responseTypeClass: 'Value'
        });
      }

      return Object.assign({}, response, {
        instanceLinkUUID,
        responseDataType: 'numeric',
        responseSetId: destinationStore,
        responseTypeClass: 'SelectOne'
      });
    })
    .filter(Boolean)
    .value();

  return medicationResponses;
};

export default extractMedicationToResponses;
