import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import './styles.scss';
import configureStore, { history } from '_redux/configureStore';
import System from '../System';

const store = configureStore();

const componentBaseClass = 'qoc-application';

function Application(props = {}) {
  console.log('-- Application props: ', props);
  const { children } = props;

  return (
    <div className={componentBaseClass}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <System>{children}</System>
        </ConnectedRouter>
      </Provider>
    </div>
  );
}

Application.propTypes = {
  children: PropTypes.node.isRequired
};

Application.defaultProps = {};

export default Application;
