export const types = {
  ADD_MEDICATION: 'ADD_MEDICATION',
  DELETE_MEDICATION: 'DELETE_MEDICATION',
  UPDATE_MEDICATION: 'UPDATE_MEDICATION',
  UPDATE_MEDICATIONS: 'UPDATE_MEDICATIONS',
  INITIALIZE_MEDICATIONS: 'INITIALIZE_MEDICATIONS',
  SET_CURRENT_MEDICATIONS: 'SET_CURRENT_MEDICATIONS',

  SAVE_MEDICATION_PROGRESS_FULFILLED: 'SAVE_MEDICATION_PROGRESS_FULFILLED',
  SAVE_MEDICATION_PROGRESS_PENDING: 'SAVE_MEDICATION_PROGRESS_PENDING',
  SAVE_MEDICATION_PROGRESS_REJECTED: 'SAVE_MEDICATION_PROGRESS_REJECTED',

  DELETE_NEXT_DS_PROGRESS_FULFILLED: 'DELETE_NEXT_DS_PROGRESS_FULFILLED',
  DELETE_NEXT_DS_PROGRESS_PENDING: 'DELETE_NEXT_DS_PROGRESS_PENDING',
  DELETE_NEXT_DS_PROGRESS_REJECTED: 'DELETE_NEXT_DS_PROGRESS_REJECTED',

  DELETE_MEDICATIONS_FULFILLED: 'DELETE_MEDICATIONS_FULFILLED',
  DELETE_MEDICATIONS_PENDING: 'DELETE_MEDICATIONS_PENDING',
  DELETE_MEDICATIONS_REJECTED: 'DELETE_MEDICATIONS_REJECTED',

  POST_MEDICATIONS_FULFILLED: 'POST_MEDICATIONS_FULFILLED',
  POST_MEDICATIONS_PENDING: 'POST_MEDICATIONS_PENDING',
  POST_MEDICATIONS_REJECTED: 'POST_MEDICATIONS_REJECTED',

  SET_UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED:
    'SET_UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED',

  UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_FULFILLED:
    'UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_FULFILLED',
  UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_PENDING:
    'UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_PENDING',
  UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_REJECTED:
    'UPDATE_EMPTY_MEDICATION_DROPDOWNS_ANSWERED_REJECTED',

  POST_MEDICATIONS_AND_STATUS_FULFILLED:
    'POST_MEDICATIONS_AND_STATUS_FULFILLED',
  POST_MEDICATIONS_AND_STATUS_PENDING: 'POST_MEDICATIONS_AND_STATUS_PENDING',
  POST_MEDICATIONS_AND_STATUS_REJECTED: 'POST_MEDICATIONS_AND_STATUS_REJECTED',

  SET_STEP_UP_YELLOW_ZONE_OPTION: 'SET_STEP_UP_YELLOW_ZONE_OPTION',

  POST_STEP_UP_YELLOW_ZONE_OPTION_FULFILLED:
    'POST_STEP_UP_YELLOW_ZONE_OPTION_FULFILLED',
  POST_STEP_UP_YELLOW_ZONE_OPTION_PENDING:
    'POST_STEP_UP_YELLOW_ZONE_OPTION_PENDING',
  POST_STEP_UP_YELLOW_ZONE_OPTION_REJECTED:
    'POST_STEP_UP_YELLOW_ZONE_OPTION_REJECTED',

  UPDATE_MEDICATION_STATUS_FULFILLED: 'UPDATE_MEDICATION_STATUS_FULFILLED',
  UPDATE_MEDICATION_STATUS_PENDING: 'UPDATE_MEDICATION_STATUS_PENDING',
  UPDATE_MEDICATION_STATUS_REJECTED: 'UPDATE_MEDICATION_STATUS_REJECTED'
};

export default {
  types
};
