import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = state => ({
  system: state.system
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideSystemModal: actions.hideSystemModal,
      hideSystemToast: actions.hideSystemToast,
      initializeSystem: actions.initializeSystem,
      showSystemModal: actions.showSystemModal,
      showSystemToast: actions.showSystemToast
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
