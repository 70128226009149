export const TYPES = {
  DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED:
    'DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED',
  DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING:
    'DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING',
  DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED:
    'DELETE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED',

  FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED:
    'FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED',
  FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING:
    'FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING',
  FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED:
    'FETCH_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED',

  POST_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED:
    'POST_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED',
  POST_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING:
    'POST_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING',
  POST_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED:
    'POST_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED',

  UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED:
    'UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_FULFILLED',
  UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING:
    'UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_PENDING',
  UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED:
    'UPDATE_PHOTOGRAPHS_PROFILES_PROPERTYS_REJECTED'
};

export default {
  TYPES
};
