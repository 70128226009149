import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = ({ photographs }) => ({ photographs });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deletePhotographsProfilesPropertys:
        actions.deletePhotographsProfilesPropertys,
      fetchPhotographsProfilesPropertys:
        actions.fetchPhotographsProfilesPropertys,
      postPhotographsProfilesPropertys:
        actions.postPhotographsProfilesPropertys,
      updatePhotographsProfilesPropertys:
        actions.updatePhotographsProfilesPropertys
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
